import React from "react";
import {MdCallMerge, MdDeleteForever,MdCreateNewFolder,MdPageview} from "react-icons/md";
import {FaTrashRestoreAlt} from "react-icons/fa";

let href = "deletehistory";
let restorehref = "restorehistory";

const OrganizationUndecidedColumns = [
    {
        Header: 'Action',
        accessor: '',
        Cell: props => (

            <div>
                {props.value.status === "Deleted Undecided" ?
                    <a href="#"
                       data-toggle="modal"
                       data-target={`#${restorehref}`}
                       className="text-success"
                    > <FaTrashRestoreAlt/>Restore</a> :
                    <a href="#"
                       data-toggle="modal"
                       data-target={`#${href}`}
                    > <MdDeleteForever/>Delete</a>}

            </div>
        )
    },
    {
        Header: 'Institution Name',
        accessor: '',
        Cell: props => (

            <div className={props.value.status === "Deleted Undecided" ? "deleted" : ""}>{props.value.additionalProfileInfo.name}</div>

        )
    },
    {
        Header: 'Access Classification',
        accessor: 'access_classification',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Public" ? '#3498db' : row.value === "Confidential" ? '#f39c12' :
                    row.value === "Strictly-Confidential" ? '#8e44ad' : '#c0392b', transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    },
    {
        Header: 'Information Type',
        accessor: 'info_type',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === true ? '#2ecc71'
                    : row.value === false ? '#ffbf00'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value === true ? 'Positive'
                    : row.value === false ? `Negative`
                    : 'Invalid'
            }
          </span>
        )
    },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Undecided" ? '#e67e22'
                    : row.value === "Deleted Undecided" ? '#e74c3c' : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    }
];
const IndividualUndecidedColumns = [
    {
        Header: 'Action',
        accessor: '',
        Cell: props => (

            <div>
                {props.value.status === "Deleted Undecided" ?
                    <a href="#"
                       data-toggle="modal"
                       data-target={`#${restorehref}`}
                       className="text-success"
                    > <FaTrashRestoreAlt/>Restore</a> :
                    <a href="#"
                       data-toggle="modal"
                       data-target={`#${href}`}
                    > <MdDeleteForever/>Delete</a>}

            </div>
        )
    },
    {
        Header: 'Individual Name',
        accessor: '',
        Cell: props => (

            <div
                className={props.value.status === "Deleted Undecided" ? "deleted" : ""}
            >{props.value.additionalProfileInfo.name}</div>

        )
    },
    {
        Header: 'Organization',
        accessor: '',
        Cell: props => (

            <div className={props.value.status === "Deleted Undecided" ? "deleted" : ""}>{props.value.organization}</div>

        )
    },
    {
        Header: 'Position',
        accessor: '',
        Cell: props => (

            <div className={props.value.status === "Deleted Undecided" ? "deleted" : ""}>{props.value.position}</div>

        )
    },
    {
        Header: 'Access Classification',
        accessor: 'access_classification',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Public" ? '#3498db' : row.value === "Confidential" ? '#f39c12' :
                    row.value === "Strictly-Confidential" ? '#8e44ad' : '#c0392b', transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    },
    {
        Header: 'Information Type',
        accessor: 'info_type',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === true ? '#2ecc71'
                    : row.value === false ? '#ffbf00'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value === true ? 'Positive'
                    : row.value === false ? `Negative`
                    : 'Invalid'
            }
          </span>
        )
    },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Undecided" ? '#e67e22'
                    : row.value === "Deleted Undecided" ? '#e74c3c' : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    }
];
const OrganizationNotFoundColumns = [
    {
        Header: 'Action',
        columns:[
            {
                Header : 'Delete',
                accessor: '',
                Cell: props => (

                    <div>
                        {props.value.status === "Deleted Profile Not Found" ?
                            <a href="#"
                               data-toggle="modal"
                               data-target={`#${restorehref}`}
                               className="text-success"
                            > <FaTrashRestoreAlt/>Restore</a> :
                            <a href="#"
                               data-toggle="modal"
                               data-target={`#${href}`}
                            > <MdDeleteForever/>Delete</a>}

                    </div>
                )
            },
            {
                Header : 'Create New',
                accessor: '',
                Cell: props => (

                    <a href="#"
                       data-toggle="modal"
                       data-target={`#createnewinstitution`}
                       className=" text-success"
                    > <MdCreateNewFolder/>Create New & Merge</a>
                )
            },
            {
                Header : 'Merge',
                accessor : '',
                Cell: props => (

                    <a href="#"
                       data-toggle="modal"
                       data-target={`#mergeWithExistinginstitution`}
                       className=" text-info"
                    > <MdCallMerge/>Merge With Existing</a>
                )
            }
        ]
    },

    {
        Header: 'Institution Name',
        accessor: '',
        Cell: props => (

            <div
                className={props.value.status === "Deleted Profile Not Found" ? "deleted" : ""}
            >{props.value.additionalProfileInfo.name}</div>

        )
    },
    {
        Header: 'Access Classification',
        accessor: 'access_classification',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Public" ? '#3498db' : row.value === "Confidential" ? '#f39c12' :
                    row.value === "Strictly-Confidential" ? '#8e44ad' : '#c0392b', transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    },
    {
        Header: 'Information Type',
        accessor: 'info_type',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === true ? '#2ecc71'
                    : row.value === false ? '#ffbf00'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value === true ? 'Positive'
                    : row.value === false ? `Negative`
                    : 'Invalid'
            }
          </span>
        )
    }
    ,
    {
        Header: 'Status',
        accessor: 'status',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Profile Not Found" ? '#ffbf00'
                    : row.value === "Deleted Profile Not Found" ? '#e74c3c'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    }
];
const IndividualNotFoundColumns = [
    {
        Header: 'Action',
        columns:[
            {
                Header : 'Delete',
                accessor: '',
                Cell: props => (

                    <div>
                        {props.value.status === "Deleted Profile Not Found" ?
                            <a href="#"
                               data-toggle="modal"
                               data-target={`#${restorehref}`}
                               className="text-success"
                            > <FaTrashRestoreAlt/>Restore</a> :
                            <a href="#"
                               data-toggle="modal"
                               data-target={`#${href}`}
                            > <MdDeleteForever/>Delete</a>}

                    </div>
                )
            },
            {
                Header : 'Create New',
                accessor: '',
                Cell: props => (

                    <a href="#"
                       data-toggle="modal"
                       data-target={`#createnewindiviudal`}
                       className=" text-success"
                    > <MdCreateNewFolder/>Create & Merge</a>
                )
            },
            {
                Header : 'Merge',
                accessor : '',
                Cell: props => (

                    <a href="#"
                       data-toggle="modal"
                       data-target={`#mergeWithExistingindiviudal`}
                       className=" text-info"
                    > <MdCallMerge/>Merge With Existing</a>
                )
            }
        ]
    },
    {
        Header: 'Individual Name',
        accessor: '',
        Cell: props => (

            <div
                className={props.value.status === "Deleted Profile Not Found" ? "deleted" : ""}
            >{props.value.additionalProfileInfo.name}</div>

        )
    },
    {
        Header: 'Access Classification',
        accessor: 'access_classification',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Public" ? '#3498db' : row.value === "Confidential" ? '#f39c12' :
                    row.value === "Strictly-Confidential" ? '#8e44ad' : '#c0392b', transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    },
    {
        Header: 'Information Type',
        accessor: 'info_type',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === true ? '#2ecc71'
                    : row.value === false ? '#ffbf00'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value === true ? 'Positive'
                    : row.value === false ? `Negative`
                    : 'Invalid'
            }
          </span>
        )
    }
    ,
    {
        Header: 'Status',
        accessor: 'status',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Profile Not Found" ? '#ffbf00'
                    : row.value === "Deleted Profile Not Found" ? '#e74c3c'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    }
];

const OrganizationNotFoundColumnsNoDelete = [
    {
        Header: 'Action',
        columns:[
            {
                Header : 'Create New',
                accessor: '',
                Cell: props => (

                    <a href="#"
                       data-toggle="modal"
                       data-target={`#createnewinstitution`}
                       className=" text-success"
                    > <MdCreateNewFolder/>Create New & Merge</a>
                )
            },
            {
                Header : 'Merge',
                accessor : '',
                Cell: props => (

                    <a href="#"
                       data-toggle="modal"
                       data-target={`#mergeWithExistinginstitution`}
                       className=" text-info"
                    > <MdCallMerge/>Merge With Existing</a>
                )
            }
        ]
    },

    {
        Header: 'Institution Name',
        accessor: '',
        Cell: props => (

            <div
                className={props.value.status === "Deleted Profile Not Found" ? "deleted" : ""}
            >{props.value.additionalProfileInfo.name}</div>

        )
    },
    {
        Header: 'Access Classification',
        accessor: 'access_classification',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Public" ? '#3498db' : row.value === "Confidential" ? '#f39c12' :
                    row.value === "Strictly-Confidential" ? '#8e44ad' : '#c0392b', transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    },
    {
        Header: 'Information Type',
        accessor: 'info_type',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === true ? '#2ecc71'
                    : row.value === false ? '#ffbf00'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value === true ? 'Positive'
                    : row.value === false ? `Negative`
                    : 'Invalid'
            }
          </span>
        )
    }
    ,
    {
        Header: 'Status',
        accessor: 'status',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Profile Not Found" ? '#ffbf00'
                    : row.value === "Deleted Profile Not Found" ? '#e74c3c'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    }
];
const IndividualNotFoundColumnsNoDelete = [
    {
        Header: 'Action',
        columns:[
            {
                Header : 'Create New',
                accessor: '',
                Cell: props => (

                    <a href="#"
                       data-toggle="modal"
                       data-target={`#createnewindiviudal`}
                       className=" text-success"
                    > <MdCreateNewFolder/>Create & Merge</a>
                )
            },
            {
                Header : 'Merge',
                accessor : '',
                Cell: props => (

                    <a href="#"
                       data-toggle="modal"
                       data-target={`#mergeWithExistingindiviudal`}
                       className=" text-info"
                    > <MdCallMerge/>Merge With Existing</a>
                )
            }
        ]
    },
    {
        Header: 'Individual Name',
        accessor: '',
        Cell: props => (

            <div
                className={props.value.status === "Deleted Profile Not Found" ? "deleted" : ""}
            >{props.value.additionalProfileInfo.name}</div>

        )
    },
    {
        Header: 'Access Classification',
        accessor: 'access_classification',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Public" ? '#3498db' : row.value === "Confidential" ? '#f39c12' :
                    row.value === "Strictly-Confidential" ? '#8e44ad' : '#c0392b', transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    },
    {
        Header: 'Information Type',
        accessor: 'info_type',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === true ? '#2ecc71'
                    : row.value === false ? '#ffbf00'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value === true ? 'Positive'
                    : row.value === false ? `Negative`
                    : 'Invalid'
            }
          </span>
        )
    }
    ,
    {
        Header: 'Status',
        accessor: 'status',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Profile Not Found" ? '#ffbf00'
                    : row.value === "Deleted Profile Not Found" ? '#e74c3c'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    }
];


const OrganizationUndecidedColumnsPopUp = [

    {
        Header: 'Institution Name',
        accessor: '',
        Cell: props => (

            <div className={props.value.status === "Deleted Undecided" ? "deleted" : ""}>{props.value.additionalProfileInfo.name}</div>

        )
    },
    {
        Header: 'Access Classification',
        accessor: 'access_classification',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Public" ? '#3498db' : row.value === "Confidential" ? '#f39c12' :
                    row.value === "Strictly-Confidential" ? '#8e44ad' : '#c0392b', transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    },
    {
        Header: 'Information Type',
        accessor: 'info_type',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === true ? '#2ecc71'
                    : row.value === false ? '#ffbf00'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value === true ? 'Positive'
                    : row.value === false ? `Negative`
                    : 'Invalid'
            }
          </span>
        )
    },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Undecided" ? '#e67e22'
                    : row.value === "Deleted Undecided" ? '#e74c3c' : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    }
];
const IndividualUndecidedColumnsPopUp = [

    {
        Header: 'Individual Name',
        accessor: '',
        Cell: props => (

            <div
                className={props.value.status === "Deleted Undecided" ? "deleted" : ""}
            >{props.value.additionalProfileInfo.name}</div>

        )
    },
    {
        Header: 'Organization',
        accessor: '',
        Cell: props => (

            <div className={props.value.status === "Deleted Undecided" ? "deleted" : ""}>{props.value.organization}</div>

        )
    },
    {
        Header: 'Position',
        accessor: '',
        Cell: props => (

            <div className={props.value.status === "Deleted Undecided" ? "deleted" : ""}>{props.value.position}</div>

        )
    },
    {
        Header: 'Access Classification',
        accessor: 'access_classification',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Public" ? '#3498db' : row.value === "Confidential" ? '#f39c12' :
                    row.value === "Strictly-Confidential" ? '#8e44ad' : '#c0392b', transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    },
    {
        Header: 'Information Type',
        accessor: 'info_type',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === true ? '#2ecc71'
                    : row.value === false ? '#ffbf00'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value === true ? 'Positive'
                    : row.value === false ? `Negative`
                    : 'Invalid'
            }
          </span>
        )
    },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Undecided" ? '#e67e22'
                    : row.value === "Deleted Undecided" ? '#e74c3c' : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    }
];
const OrganizationNotFoundColumnsPopUp = [


    {
        Header: 'Institution Name',
        accessor: '',
        Cell: props => (

            <div
                className={props.value.status === "Deleted Profile Not Found" ? "deleted" : ""}
            >{props.value.additionalProfileInfo.name}</div>

        )
    },
    {
        Header: 'Access Classification',
        accessor: 'access_classification',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Public" ? '#3498db' : row.value === "Confidential" ? '#f39c12' :
                    row.value === "Strictly-Confidential" ? '#8e44ad' : '#c0392b', transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    },
    {
        Header: 'Information Type',
        accessor: 'info_type',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === true ? '#2ecc71'
                    : row.value === false ? '#ffbf00'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value === true ? 'Positive'
                    : row.value === false ? `Negative`
                    : 'Invalid'
            }
          </span>
        )
    }
    ,
    {
        Header: 'Status',
        accessor: 'status',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Profile Not Found" ? '#ffbf00'
                    : row.value === "Deleted Profile Not Found" ? '#e74c3c'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    }
];
const IndividualNotFoundColumnsPopUp = [
    {
        Header: 'Individual Name',
        accessor: '',
        Cell: props => (

            <div
                className={props.value.status === "Deleted Profile Not Found" ? "deleted" : ""}
            >{props.value.additionalProfileInfo.name}</div>

        )
    },
    {
        Header: 'Organization',
        accessor: '',
        Cell: props => (

            <div className={props.value.status === "Deleted Undecided" ? "deleted" : ""}>{props.value.organization}</div>

        )
    },
    {
        Header: 'Position',
        accessor: '',
        Cell: props => (

            <div className={props.value.status === "Deleted Undecided" ? "deleted" : ""}>{props.value.position}</div>

        )
    },
    {
        Header: 'Access Classification',
        accessor: 'access_classification',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Public" ? '#3498db' : row.value === "Confidential" ? '#f39c12' :
                    row.value === "Strictly-Confidential" ? '#8e44ad' : '#c0392b', transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    },
    {
        Header: 'Information Type',
        accessor: 'info_type',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === true ? '#2ecc71'
                    : row.value === false ? '#ffbf00'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value === true ? 'Positive'
                    : row.value === false ? `Negative`
                    : 'Invalid'
            }
          </span>
        )
    }
    ,
    {
        Header: 'Status',
        accessor: 'status',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Profile Not Found" ? '#ffbf00'
                    : row.value === "Deleted Profile Not Found" ? '#e74c3c'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    }
];

const OrganizationMatchedColumns = [
    {
        Header: 'Action',
        accessor: 'profile',
        Cell: props => (
            <a href={"/institution/" + props.value._id + "/" + new Date()}
               target="_blank"><MdPageview/> View Profile </a>
        )
    },
    {
        Header: 'Institution Name',
        accessor: '',
        Cell: props => (

            <div className={props.value.status === "Deleted Undecided" ? "deleted" : ""}>{props.value.additionalProfileInfo.name}</div>

        )
    },
    {
        Header: 'Access Classification',
        accessor: 'access_classification',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Public" ? '#3498db' : row.value === "Confidential" ? '#f39c12' :
                    row.value === "Strictly-Confidential" ? '#8e44ad' : '#c0392b', transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    },
    {
        Header: 'Information Type',
        accessor: 'info_type',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === true ? '#2ecc71'
                    : row.value === false ? '#ffbf00'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value === true ? 'Positive'
                    : row.value === false ? `Negative`
                    : 'Invalid'
            }
          </span>
        )
    },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "In Review" ? '#e67e22'
                    : row.value === "Rejected" ? '#e74c3c' : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    }
];
const IndividualMatchedColumns = [
    {
        Header: 'Action',
        accessor: 'profile',
        Cell: props => (
            <a href={"/individualEmployee/" + props.value._id + "/" + new Date()}
               target="_blank"><MdPageview/> View Profile </a>
        )
    },
    {
        Header: 'Individual Name',
        accessor: '',
        Cell: props => (

            <div
                className={props.value.status === "Deleted Undecided" ? "deleted" : ""}
            >{props.value.additionalProfileInfo.name}</div>

        )
    },
    {
        Header: 'Organization',
        accessor: '',
        Cell: props => (

            <div className={props.value.status === "Deleted Undecided" ? "deleted" : ""}>{props.value.organization}</div>

        )
    },
    {
        Header: 'Position',
        accessor: '',
        Cell: props => (

            <div className={props.value.status === "Deleted Undecided" ? "deleted" : ""}>{props.value.position}</div>

        )
    },
    {
        Header: 'Access Classification',
        accessor: 'access_classification',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Public" ? '#3498db' : row.value === "Confidential" ? '#f39c12' :
                    row.value === "Strictly-Confidential" ? '#8e44ad' : '#c0392b', transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    },
    {
        Header: 'Information Type',
        accessor: 'info_type',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === true ? '#2ecc71'
                    : row.value === false ? '#ffbf00'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value === true ? 'Positive'
                    : row.value === false ? `Negative`
                    : 'Invalid'
            }
          </span>
        )
    },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Undecided" ? '#e67e22'
                    : row.value === "Deleted Undecided" ? '#e74c3c' : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    }
];





export   {
    OrganizationUndecidedColumns,
    IndividualUndecidedColumns,
    OrganizationNotFoundColumns,
    IndividualNotFoundColumns,
    OrganizationMatchedColumns,
    IndividualMatchedColumns,
    OrganizationUndecidedColumnsPopUp,
    IndividualUndecidedColumnsPopUp,
    OrganizationNotFoundColumnsPopUp,
    IndividualNotFoundColumnsPopUp,
    IndividualNotFoundColumnsNoDelete,
    OrganizationNotFoundColumnsNoDelete
};