import React, {Component} from "react";
import Viewer from "react-viewer";
import ImageUploader from "react-images-upload";
import axios from 'axios';
import Alert from "react-s-alert";

class UpdatePhoto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            photo: [],
            passport: []
        }
    }

    onUpdatePhoto = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('photo', this.state.photo[0]);
        axios.post('/api-individualMember/changePhoto/' + this.props.id, formData).then(res => {
            window.location.reload();
        }).catch(err => {
            for (let i in err.response.data) {
                Alert.error(err.response.data[i], {
                    position: 'bottom-right',
                    effect: 'jelly'
                });
            }
        })
    };
    onUpdatePassport = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('passport', this.state.passport[0]);
        axios.post('/api-individualMember/changePassport/' + this.props.id, formData).then(res => {
            window.location.reload();
        }).catch(err => {
            for (let i in err.response.data) {
                Alert.error(err.response.data[i], {
                    position: 'bottom-right',
                    effect: 'jelly'
                });
            }
        })
    };
    onDropPassport = file => {
        this.setState({
            passport: this.state.photo.concat(file)
        });
    };

    onDropPhoto = file => {
        this.setState({
            photo: this.state.photo.concat(file)
        });
    };

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-sm-4 ml-auto mr-auto">

                        <img
                            alt="Photo"
                            src={this.props.photo}
                            // width={150}
                            onClick={() => {
                                this.setState({visible: !this.state.visible});
                            }}
                        />


                    </div>

                    <div className="col-sm-4 ml-auto mr-auto">

                        <img
                            alt="Passport"
                            src={this.props.passport}
                            // width={150}
                            onClick={() => {
                                this.setState({visible: !this.state.visible});
                            }}
                        />


                    </div>

                    <Viewer
                        visible={this.state.visible}
                        images={[
                            {src: this.props.photo, alt: 'Photo'},
                            {src: this.props.passport, alt: 'Passport'}]}
                        onClose={() => {
                            this.setState({visible: false});
                        }}
                    />
                </div>

                <div className="form-row">
                    <div className="form-group col-md-6">
                        <ImageUploader
                            singleImage={true}
                            withPreview={true}
                            withIcon={true}
                            buttonText='Choose Photo'
                            onChange={this.onDropPhoto}
                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                            maxFileSize={5242880}
                        />
                        <button
                            type="submit"
                            className="btn  btn-outline-success btn-round"
                            onClick={this.onUpdatePhoto}
                        >Update Photo
                        </button>
                    </div>
                    <div className="form-group col-md-6">
                        <ImageUploader
                            singleImage={true}
                            withPreview={true}
                            withIcon={true}
                            buttonText='Choose Passport/ID'
                            onChange={this.onDropPassport}
                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                            maxFileSize={5242880}
                        />
                        <button
                            onClick={this.onUpdatePassport}
                            type="submit"
                            className="btn btn-outline-success btn-round"
                        >Update Passport</button>
                    </div>

                </div>
            </React.Fragment>
        )

    }

}

export default UpdatePhoto;