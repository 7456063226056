import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {loginUser} from "../../actions/authActions";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardText,
    CardTitle,
    Col,
    Container,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from "reactstrap";
// core components
import MainNavBar from "components/Navbars/MainNavBar.js";
import Alert from "react-bootstrap/Alert";

function Effect() {
    const {body, documentElement} = document;
    body.classList.add("login-page");
    body.classList.add("sidebar-collapse");
    documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    body.scrollTop = 0;
    return function cleanup() {
        document.body.classList.remove("login-page");
        document.body.classList.remove("sidebar-collapse");
    };
}

const validEmailRegex =
    RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        // if we have an error string set valid to false
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
};
const backgroundImage =
    'https://images.unsplash.com/photo-1527192491265-7e15c55b1ed2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80';

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            errors: {
                emailnotfound: '',
                passwordincorrect: '',
                email :''
            },
            firstFocus: false,
            lastFocus: false,

        };
    }


    componentDidMount() {
        // If logged in and user navigates to Login page, should redirect them to dashboard
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/welcome");
        }
        Effect();


    }

    componentDidUpdate() {
        Effect();

    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            this.props.history.push("/welcome");
        }
        if (nextProps.errors) {
            this.setState({
                errors : nextProps.errors
            })
        }

    }

    onChange = e => {
        e.preventDefault();


        this.setState({[e.target.id]: e.target.value});

    };

    onSubmit = e => {
        e.preventDefault();

        const userData = {
            email: this.state.email,
            password: this.state.password
        };
        this.props.loginUser(userData,this.props.history);

    };

    render() {
        //console.log(this.state);
        const {errors} = this.state;
        return (


            <React.Fragment>

                <MainNavBar SignUp={true}/>

                <div className="page-header clear-filter" filter-color="blue">
                    <div
                        className="page-header-image"
                        style={{
                            backgroundImage: `url(${backgroundImage})`
                        }}
                    />
                    <div className="content">
                        <Container>
                            <Row>

                                <Col className="ml-auto mr-auto text-left" md="6">
                                    {/*{errors.map((k,value)=>*/}
                                    {/*    <Alert variant="danger">{value}</Alert>*/}
                                    {/*)}*/}
                                    {errors.emailnotfound? <Alert variant="danger">
                                        Email not found!
                                    </Alert> :errors.passwordincorrect?<Alert variant="danger">
                                        In correct password
                                    </Alert> : errors.email? <Alert variant="danger">Email is invalid!</Alert> : <span/>}
                                    <Card className="card-plain" sm={{size: 6, order: 2, offset: 1}}>

                                        <CardBody>
                                            <CardTitle tag="h1">Easy employee reference checking</CardTitle>
                                            <CardText>
                                                Some quick example text to build on the card title and make up the
                                                bulk of the card's content.
                                            </CardText>
                                            <Button
                                                color="primary"
                                                href="/sign-up"

                                            >
                                                Get Started
                                            </Button>
                                        </CardBody>
                                    </Card>

                                </Col>
                                <Col className="ml-auto mr-auto" md="4">
                                    <Card className="card-login card-plain">
                                        <Form className="form" noValidate onSubmit={this.onSubmit}>
                                            <CardTitle className="text-center" tag="h1">
                                                Login
                                            </CardTitle>
                                            <CardBody>
                                                <InputGroup
                                                    className={
                                                        "input-lg" +
                                                        (this.state.firstFocus ? " input-group-focus" : "")
                                                    }
                                                >
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="now-ui-icons users_circle-08"/>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input

                                                        onChange={this.onChange}
                                                        value={this.state.email}
                                                        error={errors.email}
                                                        id="email"
                                                        type="email"
                                                        placeholder="Email..."
                                                        onFocus={() => this.setState({firstFocus: true})}
                                                        onBlur={() => this.setState({firstFocus: false})}

                                                    />


                                                </InputGroup>
                                                <InputGroup
                                                    className={
                                                        "input-lg" +
                                                        (this.state.lastFocus ? " input-group-focus" : "")
                                                    }
                                                >
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="now-ui-icons text_caps-small"></i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input

                                                        onChange={this.onChange}
                                                        value={this.state.password}
                                                        error={errors.password}
                                                        id="password"
                                                        type="password"
                                                        placeholder="Password..."
                                                        onFocus={() => this.setState({lastFocus: true})}
                                                        onBlur={() => this.setState({lastFocus: false})}
                                                    />
                                                </InputGroup>
                                            </CardBody>
                                            <CardFooter className="text-center">
                                                <Button type="submit" className="btn btn-info btn-lg btn-block">
                                                    login
                                                </Button>

                                                <div className="pull-left">
                                                    <h6>
                                                        <a
                                                            className="link"
                                                            href="/forgot-password"

                                                        >
                                                            Forgot Password?
                                                        </a>
                                                    </h6>
                                                </div>

                                            </CardFooter>
                                        </Form>
                                    </Card>

                                </Col>
                            </Row>


                        </Container>
                    </div>

                </div>
                <Alert stack={{limit: 3}}/>
            </React.Fragment>

        );
    }

}

LoginPage.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    {loginUser}
)(LoginPage);
