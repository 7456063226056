import React, {Component} from "react";
import { Link } from "react-router-dom";

import "./404.scss";
import MainNavBar from "../Navbars/MainNavBar";

class NotActivated extends Component {
    render() {
        return (
            <React.Fragment>
                <MainNavBar/>
                <div className="not-found">
                    <Link to="/">
                        <b>401</b>
                    </Link>
                    <br />
                    <p>Your account is not activated.</p>
                    <p>
                        If you're redirected to this page, your account is not activated.<br/>
                        Please contact system administration to activate your account.
                    </p>
                </div>
            </React.Fragment>

        );
    }
};

export default NotActivated;
