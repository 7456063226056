import React, {Component} from "react";
import SearchField from "react-search-field";
import axios from "axios";
import Moment from "react-moment";
import {MdCallMerge, MdPageview,FaSearch} from "react-icons/all";
import ReactTable from "react-table";

class MergeWithExistingIndividualProfile extends Component {
    constructor() {
        super();
        this.state = {
            searchDone: false,
            searchKey: '',
            individualData: []
        }
    }

    onChange = (value, event) => {
        this.setState({
            searchKey: value
        })
    };

    onSearch = (value, event) => {
        axios.get('/api-individualProfile/search/' + value+'/' +this.props.id ).then( response =>{
            //console.log(response);
            this.setState({
                individualData : response.data.docs,
                searchDone: true
            })
        }).catch(err =>{
            console.log(err);
        });

    };

    render() {
        //console.log(this.props.history);
        const individualColumns = [
            {
                Header: 'Action',
                columns: [
                    {
                        Header: "View Profile",
                        accessor: '',
                        Cell: props => (
                            <div>
                                <a href={"/individualEmployee/" + props.value._id + "/" + new Date()}
                                   target="_blank"><MdPageview/> View Profile </a>
                            </div>
                        )
                    },
                    {
                        Header: "Merge",
                        accessor: '',
                        Cell: props => (
                            <div>
                                <button
                                    className="btn-info"
                                    onClick={() => {
                                        //console.log(currentIndividual);
                                        axios.post('/api-individualProfile/mergeProfileWithHistory/' + props.value._id + '/' + this.props.history._id)
                                            .then(res => {
                                                window.location.href = '/individualEmployee/' + props.value._id + '/' + new Date();
                                            }).catch(err => {
                                            console.log(err);
                                        })
                                    }}
                                >
                                    <MdCallMerge/> Merge
                                </button>

                            </div>
                        )
                    }
                ],

            },
            {
                Header: 'ID',
                accessor: 'identification_number'
            }
            ,
            {
                Header: 'Name',
                accessor: 'name',
                Cell: row => (
                    <div style={{color: '#1abc9c'}}> {row.value}</div>
                )
            },
            {
                Header: 'Date of Birth',
                accessor: 'date_of_birth',
                Cell: row => (
                    <Moment format="DD/MM/YYYY">{row.value}</Moment>
                )
            },
            {
                Header: 'Nationality',
                accessor: 'nationality'
            }
        ];
        return (
            <div>
                <div>
                    <h3 className="font-weight-light">Search Individual Profile To Merge</h3>
                    <br/>
                    <SearchField
                        placeholder="Type anything..."
                        onChange={this.onChange}
                        onEnter={this.onSearch}
                        onSearchClick={this.onSearch}
                        classNames="test-class"

                    />
                    <p
                        className="font-italic"
                        style={{padding: 30, fontSize: 14}}
                    >Type anything related to individual profile such as name, phone number, nationality...</p>
                </div>
                {this.state.searchDone?<div>
                    <h4 className="font-weight-light text-success"><FaSearch/> Search Result</h4>
                    <br/>
                    <ReactTable
                        columns={individualColumns}
                        data={this.state.individualData}
                        defaultPageSize={5}
                        filterable
                    />
                </div>:<span/>}

            </div>
        );
    }
}

export default MergeWithExistingIndividualProfile;