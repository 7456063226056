import React, {Component} from "react";
import MainNavBar from "../../../../components/Navbars/MainNavBar";
import ImageUploader from "react-images-upload";
import Alert from "react-s-alert";
import axios from 'axios';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import ShowAlert from "../../../../utils/ShowAlert";
import './style.css';
import ErrorPopUp from "../../../Utilities/ErrorPopUp";

const Members = "Member";
const Profiles = "Profile";
const Histories = "Histories";
const MembersActivities = "MembersActivities";
const ExportPDF = "ExportPDF";
const ReferenceCheckReports = "ReferenceCheckReports";
const FormattingReports = "FormattingReports";
const Search = "Search";
const Miscellaneous = "Miscellaneous";
const Merge = "Merge";
//
// const InsertOption = [Members,Profiles,Histories,Miscellaneous];
// const ViewOption = [Members,Profiles,MembersActivities,ExportPDF,ReferenceCheckReports,FormattingReports,Search];
// const EditOption = [Members,Profiles,Histories,Miscellaneous,FormattingReports];
// const DeleteOption = [Members,Profiles,Histories,Miscellaneous];
// const mergeOption = [Merge];



class InsertUsers extends Component{
    constructor(props) {
        super(props);
        this.state = {
            individualFirstName: "",
            individualLastName: "",
            individualFirstInKhmer: "",
            individualLastInKhmer: "",
            individualEmail: "",
            individualPassword: "",
            individualPassword2: "",
            individualIdentificationNumber: "",
            individualPassport: [],
            individualPhoto: [],
            individualOrganization: "",
            individualPosition: "",
            individualAddress: "",
            organizationName: "",
            organizationNameKhmer: "",
            organizationEmail: "",
            organizationPassword: "",
            organizationPassword2: "",
            organizationRegisteredNumber: "",
            organizationTIN: "",
            organizationRepresentative: "",
            organizationRePosition: "",
            organizationAddress: "",
            adminFirstName:'',
            adminLastName:'',
            adminEmail:'',
            adminPassword:'',
            adminPassword2:'',
            adminIden:'',
            adminPermission:'',
            adminAddress:'',
            adminPassport: [],
            adminPhoto: [],
            adminType:'',
            //Permission_list
            insertMembers : '',
            insertProfiles : '',
            insertHistories : '',
            insertMiscellaneous : '',
            viewMembers : '',
            viewProfiles : '',
            viewHistories : '',
            viewMiscellaneous : '',
            viewFormattingReports : '',
            viewImportFile:'',
            viewExportPDF : '',
            viewCheckReport : '',
            viewMembersActivities : '',
            viewSearch : '',
            editMembers : '',
            editProfiles : '',
            editHistories : '',
            editMiscellaneous : '',
            editFormattingReports : '',
            deleteMembers : '',
            deleteProfiles : '',
            deleteHistories : '',
            deleteMiscellaneous : '',
            Merge : '',
            errorPassword : false,
            errorMsg :   '',
            errorTitle :''
        };
    }
    handleChange = e => {
        this.setState({[e.target.id]: e.target.checked});
    };
    onChange = e => {
        e.preventDefault();
        this.setState({[e.target.id]: e.target.value});

    };
    onDropPassport = file => {
        this.setState({
            individualPassport: this.state.individualPassport.concat(file)
        });
    };
    onDropPassportAdmin = file => {
        this.setState({
            adminPassport: this.state.adminPassport.concat(file)
        });
    };

    onDropPhoto = file => {
        this.setState({
            individualPhoto: this.state.individualPhoto.concat(file)
        });
    };
    onDropPhotoAdmin = file => {
        this.setState({
            adminPhoto: this.state.adminPhoto.concat(file)
        });
    };

    handleErrorPassword = e =>{
        this.setState({
            errorPassword : !this.state.errorPassword
        })
    };

    onIndividualSubmit = e => {
        e.preventDefault();
        if(this.state.individualPassword !==this.state.individualPassword2){
            this.setState({
                errorTitle : "Your password arent' matched",
                errorMsg : "Please check your password and try again."
            });
            this.handleErrorPassword();
            return;
        }

        const formData = new FormData();
        formData.append('myFiles', this.state.individualPassport[0]);
        formData.append('myFiles', this.state.individualPhoto[0]);
        formData.append('name', this.state.individualFirstName + this.state.individualLastName);
        formData.append('name_in_khmer', this.state.individualFirstInKhmer + this.state.individualLastInKhmer);
        formData.append('identification_number', this.state.individualIdentificationNumber);
        formData.append('password', this.state.individualPassword);
        formData.append('password2', this.state.individualPassword2);
        formData.append('email', this.state.individualEmail);
        formData.append('organization', this.state.individualOrganization);
        formData.append('position', this.state.individualPosition);
        formData.append('current_address', this.state.individualAddress);
        axios.post('/api-IndividualMember/add',formData).then( result =>{
            if(result){
                this.props.history.push(`/individualUser/${result.data.id}`);
            }
        }).catch( err =>{
            this.setState({
                errorTitle : "Error",
                errorMsg : err.response.data.msg
            });
            this.handleErrorPassword();
        })


    };
    onOrganizationSubmit = e => {
        e.preventDefault();
        if(this.state.organizationPassword !==this.state.organizationPassword2){
            this.setState({
                errorTitle : "Your password arent' matched",
                errorMsg : "Please check your password and try again."
            });
            this.handleErrorPassword();
            return;
        }
        const newOrganization = {
            name_in_khmer : this.state.organizationNameKhmer,
            register_number : this.state.organizationRegisteredNumber,
            tax_identification_number : this.state.organizationTIN,
            representative : this.state.organizationRepresentative,
            position : this.state.organizationRePosition,
            current_address : this.state.organizationAddress,
            name : this.state.organizationName,
            email : this.state.organizationEmail,
            password : this.state.organizationPassword
        };
        axios.post('/api-organizationMember/add',newOrganization).then( result =>{
            if(result){
                this.props.history.push(`/organizationUser/${result.data.id}`);
            }
        }).catch( err =>{
            this.setState({
                errorTitle : "Error",
                errorMsg : err.response.data.msg
            });
            this.handleErrorPassword();
        })

    };
    onAdminSubmit = e =>{
        e.preventDefault();
        if(this.state.adminPassword !==this.state.adminPassword2){
            this.setState({
                errorTitle : "Your password arent' matched",
                errorMsg : "Please check your password and try again."
            });
            this.handleErrorPassword();
            return;
        }
        const InsertData = [
            this.state.insertMembers ? Members : '',
            this.state.insertProfiles ? Profiles : '',
            this.state.insertHistories ? Histories : '',
            this.state.insertMiscellaneous ? Miscellaneous : ''
        ];
        const ViewData = [
            this.state.viewMembers ? Members : '',
            this.state.viewProfiles ? Profiles : '',
            this.state.viewHistories ? Histories : '',
            this.state.viewMembersActivities ? MembersActivities : '',
            this.state.viewExportPDF ? ExportPDF : '',
            this.state.viewCheckReport ? ReferenceCheckReports : '',
            this.state.viewFormattingReports ? FormattingReports : '',
            this.state.viewSearch ? Search : '',
            this.state.viewMiscellaneous ? Miscellaneous : ''
        ];
        const EditData = [
            this.state.editMembers ? Members : '',
            this.state.editProfiles ? Profiles : '',
            this.state.editHistories ? Histories : '',
            this.state.editMiscellaneous ? Miscellaneous : '',
            this.state.editFormattingReports ? FormattingReports : ''
        ];
        const DeleteData = [
            this.state.deleteMembers? Members : '',
            this.state.deleteProfiles? Profiles : '',
            this.state.deleteHistories? Histories : '',
            this.state.deleteMiscellaneous? Miscellaneous : ''
        ];
        const MergeData = this.state.Merge ? "true" :"false";

        const formData = new FormData();
        formData.append('myFiles', this.state.adminPassport[0]);
        formData.append('myFiles', this.state.adminPhoto[0]);
        formData.append('name', this.state.adminFirstName + this.state.adminLastName);
        formData.append('identification_number', this.state.adminIden);
        formData.append('password', this.state.adminPassword);
        formData.append('password2', this.state.adminPassword2);
        formData.append('email', this.state.adminEmail);
        formData.append('permission_classification', this.state.adminPermission);
        formData.append('current_address', this.state.adminAddress);
        formData.append('InsertData',JSON.stringify(InsertData));
        formData.append('ViewData',JSON.stringify(ViewData));
        formData.append('EditData',JSON.stringify(EditData));
        formData.append('DeleteData',JSON.stringify(DeleteData));
        formData.append('MergeData',MergeData);
        axios.post('/api-adminMember/add/',formData).then( result =>{
            if(result){
                this.props.history.push(`/adminUser/${result.data.id}`);
            }
        }).catch( err =>{
            this.setState({
                errorTitle : "Please upload file",
                errorMsg : err.response.data.msg
            });
            this.handleErrorPassword();
        })
    };
    componentDidMount() {
        axios.get('/api-adminMember/getType/' + this.props.auth.user.id).then( res =>{
            this.setState({
                adminType : res.data.permission_classification
            })
        }).catch(err =>{
            this.setState({
                errorTitle : "Error",
                errorMsg : err.response.data
            });
            this.handleErrorPassword();
        })
    }



    render() {
        let InsertAdmin;
        let errorPassword =
            <ErrorPopUp
                show={this.state.errorPassword}
                onHide={this.handleErrorPassword}
                title = {this.state.errorTitle}
                errorMsg={this.state.errorMsg}
            />;

        if(this.state.adminType === "System Admin"){
            let PermissionSelector =
                <div className="row">
                    <div className="col">
                        <h6 className="category">Item</h6>
                        <h6 className="font-weight-normal category">Members</h6>
                        <h6 className="font-weight-normal category">Profiles</h6>
                        <h6 className="font-weight-normal category">Histories</h6>
                        <h6 className="font-weight-normal category">Miscellaneous</h6>
                        <h6 className="font-weight-normal category">Format Report</h6>
                        <h6 className="font-weight-normal category">Import File</h6>
                        <h6 className="font-weight-normal category">Export PDF</h6>
                        <h6 className="font-weight-normal category">Check Report</h6>
                        <h6 className="font-weight-normal category">Member Activities</h6>
                        <h6 className="font-weight-normal category">Search</h6>
                    </div>
                    <div className="col text-center">
                        <h6 className="category">Insert</h6>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="insertMembers"
                                onChange={this.handleChange}
                                checked = {this.state.insertMembers}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="insertProfiles"
                                onChange={this.handleChange}
                                checked = {this.state.insertProfiles}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="insertHistories"
                                onChange={this.handleChange}
                                checked = {this.state.insertHistories}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="insertMiscellaneous"
                                onChange={this.handleChange}
                                checked = {this.state.insertMiscellaneous}
                            />
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox disable-checkbox" disabled/>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox disable-checkbox" disabled/>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>

                    </div>
                    <div className="col text-center">
                        <h6 className="category ">View/Action</h6>
                        <div className="form-group">

                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="viewMembers"
                                onChange={this.handleChange}
                                checked = {this.state.viewMembers}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="viewProfiles"
                                onChange={this.handleChange}
                                checked = {this.state.viewProfiles}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="viewHistories"
                                onChange={this.handleChange}
                                checked = {this.state.viewHistories}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="viewMiscellaneous"
                                onChange={this.handleChange}
                                checked = {this.state.viewMiscellaneous}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="viewFormattingReports"
                                onChange={this.handleChange}
                                checked = {this.state.viewFormattingReports}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="viewImportFile"
                                onChange={this.handleChange}
                                checked = {this.state.viewImportFile}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="viewExportPDF"
                                onChange={this.handleChange}
                                checked = {this.state.viewExportPDF}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="viewCheckReport"
                                onChange={this.handleChange}
                                checked = {this.state.viewCheckReport}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="viewMembersActivities"
                                onChange={this.handleChange}
                                checked = {this.state.viewMembersActivities}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="viewSearch"
                                onChange={this.handleChange}
                                checked = {this.state.viewSearch}
                            />
                        </div>

                    </div>
                    <div className="col text-center">
                        <h6 className="category">Edit</h6>
                        <div className="form-group">

                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="editMembers"
                                onChange={this.handleChange}
                                checked = {this.state.editMembers}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="editProfiles"
                                onChange={this.handleChange}
                                checked = {this.state.editProfiles}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="editHistories"
                                onChange={this.handleChange}
                                checked = {this.state.editHistories}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="editMiscellaneous"
                                onChange={this.handleChange}
                                checked = {this.state.editMiscellaneous}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="editFormattingReports"
                                onChange={this.handleChange}
                                checked = {this.state.editFormattingReports}
                            />
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>

                    </div>
                    <div className="col text-center">
                        <h6 className="category text-danger">Delete</h6>
                        <div className="form-group">

                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="deleteMembers"
                                onChange={this.handleChange}
                                checked = {this.state.deleteMembers}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="deleteProfiles"
                                onChange={this.handleChange}
                                checked = {this.state.deleteProfiles}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="deleteHistories"
                                onChange={this.handleChange}
                                checked = {this.state.deleteHistories}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="deleteMiscellaneous"
                                onChange={this.handleChange}
                                checked = {this.state.deleteMiscellaneous}
                            />
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>

                    </div>
                    <div className="col text-center">
                        <h6 className="category text-warning">Merge</h6>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                id="Merge"
                                onChange={this.handleChange}
                                checked = {this.state.Merge}
                            />
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox"disabled/>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" className="big-checkbox" disabled/>
                        </div>

                    </div>
                </div>;
            InsertAdmin = <div className="tab-pane " id="admin" role="tabpanel">
                <form className="form "  onSubmit={this.onAdminSubmit}
                      encType="multipart/form-data" method="POST"
                >
                    <div className="text-left">
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputEmail4">First Name</label>
                                <input
                                    required={true}
                                    onChange={this.onChange}
                                    value={this.state.adminFirstName}
                                    type="text"
                                    className="form-control"
                                    id="adminFirstName"
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="individualLastName">Last Name</label>
                                <input
                                    required={true}
                                    onChange={this.onChange}
                                    value={this.state.adminLastName}
                                    type="text"
                                    className="form-control"
                                    id="adminLastName"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputEmail">Email</label>
                            <input
                                required={true}
                                onChange={this.onChange}
                                value={this.state.adminEmail}
                                type="email"
                                className="form-control"
                                id="adminEmail"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputAddress">Password</label>
                            <input
                                required={true}
                                onChange={this.onChange}
                                value={this.state.adminPassword}
                                type="password"
                                className="form-control"
                                id="adminPassword"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputAddress">Confirm Password</label>
                            <input
                                onChange={this.onChange}
                                value={this.state.adminPassword2}
                                type="password"
                                className="form-control"
                                id="adminPassword2"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputAddress">Identification Number</label>
                            <input
                                required={true}
                                onChange={this.onChange}
                                value={this.state.adminIden}
                                type="text"
                                className="form-control"
                                id="adminIden"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputAddress">Permission Classification</label>
                            <select
                                onChange={this.onChange}
                                required={true}
                                value={this.state.adminPermission}
                                id="adminPermission"
                                className="form-control"
                            >
                                <option defaultValue>Choose...</option>
                                <option value="Data Entry">Data Entry</option>
                                {/*<option value="Casual Member">Casual Member</option>*/}
                                <option value="Ordinary Member">Ordinary Member</option>
                                <option value="Special Member">Special Member</option>
                                <option value="System Admin">System Admin</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputAddress">Assign Permission</label>
                            {PermissionSelector}
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputAddress">Current Address</label>
                            <input
                                required={true}
                                onChange={this.onChange}
                                value={this.state.adminAddress}
                                type="text"
                                className="form-control"
                                id="adminAddress"
                            />
                        </div>
                        <div className="form-group">
                            <ImageUploader
                                singleImage={true}
                                withPreview={true}
                                withIcon={true}
                                buttonText='Choose Passport'
                                onChange={this.onDropPassportAdmin}
                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                maxFileSize={5242880}
                            />

                        </div>

                        <div className="form-group">
                            <ImageUploader
                                singleImage={true}
                                withPreview={true}
                                withIcon={true}
                                buttonText='Choose Photo'
                                onChange={this.onDropPhotoAdmin}
                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                maxFileSize={5242880}
                            />
                            {/*<i> {photo}</i>*/}

                        </div>

                    </div>

                    <button type="submit" className="site-btn text-success">Insert
                    </button>
                </form>

            </div>;
        }




        return(
            <React.Fragment>
                {errorPassword}
                <MainNavBar SignIn ={false} SignUp={false} dashboard = {true} socialMedia={false}/>
                <div className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 ml-auto mr-auto text-center">
                                <h3 className="title">Register for...?</h3>
                                <div className="card">
                                    <div className="card-header">
                                        <ul className="nav nav-tabs justify-content-center" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#individual"
                                                   role="tab">
                                                    <i className="now-ui-icons users_single-02"></i>
                                                    Individual
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#institution"
                                                   role="tab">
                                                    <i className="now-ui-icons business_bank"></i>
                                                    Organization
                                                </a>
                                            </li>
                                            {this.state.adminType === "System Admin" ?<li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#admin"
                                                   role="tab">
                                                    <i className="now-ui-icons ui-1_settings-gear-63 text-danger"></i>
                                                    Admin
                                                </a>
                                            </li> : <span></span> }

                                        </ul>
                                    </div>
                                    <div className="card-body">

                                        <div className="tab-content text-center">
                                            <div className="tab-pane active " id="individual" role="tabpanel">
                                                <form className="form "  onSubmit={this.onIndividualSubmit}
                                                      encType="multipart/form-data" method="POST"
                                                >
                                                    <div className="text-left">
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="inputEmail4">First Name</label>
                                                                <input
                                                                    required={true}
                                                                    onChange={this.onChange}
                                                                    value={this.state.individualFirstName}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="individualFirstName"
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="individualLastName">Last Name</label>
                                                                <input
                                                                    required={true}
                                                                    onChange={this.onChange}
                                                                    value={this.state.individualLastName}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="individualLastName"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="individualFirstInKhmer">Family
                                                                    Name(Khmer)</label>
                                                                <input
                                                                    required={true}
                                                                    onChange={this.onChange}
                                                                    value={this.state.individualFirstInKhmer}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="individualFirstInKhmer"
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="individualLastInKhmer">Sure
                                                                    Name(Khmer)</label>
                                                                <input
                                                                    required={true}
                                                                    onChange={this.onChange}
                                                                    value={this.state.individualLastInKhmer}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="individualLastInKhmer"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="inputEmail">Email</label>
                                                            <input
                                                                required={true}
                                                                onChange={this.onChange}
                                                                value={this.state.individualEmail}
                                                                type="email"
                                                                className="form-control"
                                                                id="individualEmail"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="inputAddress">Password</label>
                                                            <input
                                                                required={true}
                                                                onChange={this.onChange}
                                                                value={this.state.individualPassword}
                                                                type="password"
                                                                className="form-control"
                                                                id="individualPassword"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="inputAddress">Confirm Password</label>
                                                            <input
                                                                onChange={this.onChange}
                                                                value={this.state.individualPassword2}
                                                                type="password"
                                                                className="form-control"
                                                                id="individualPassword2"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="inputAddress">Identification Number</label>
                                                            <input
                                                                required={true}
                                                                onChange={this.onChange}
                                                                value={this.state.individualIdentificationNumber}
                                                                type="text"
                                                                className="form-control"
                                                                id="individualIdentificationNumber"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            {/*<div className="btn btn-info">Upload Passport</div>*/}
                                                            {/*<Input*/}
                                                            {/*    type="file"*/}
                                                            {/*    onChange={this.onChange}*/}
                                                            {/*    filename={this.state.individualPassport}*/}
                                                            {/*    error={errors.photo}*/}
                                                            {/*    name="individualPassport"*/}
                                                            {/*    className="form-control-file"*/}
                                                            {/*    id="individualPassport"*/}

                                                            {/*/>*/}
                                                            {/*<i> {passport}</i>*/}
                                                            <ImageUploader
                                                                singleImage={true}
                                                                withPreview={true}
                                                                withIcon={true}
                                                                buttonText='Choose Passport'
                                                                onChange={this.onDropPassport}
                                                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                                                maxFileSize={5242880}
                                                            />

                                                        </div>
                                                        <div className="form-group">
                                                            {/*<div className="btn btn-info">Upload Photo</div>*/}
                                                            {/*<Input*/}
                                                            {/*    type="file"*/}
                                                            {/*    onChange={this.onChange}*/}
                                                            {/*    filename={this.state.individualPhoto}*/}
                                                            {/*    error={errors.passport}*/}
                                                            {/*    name="individualPhoto"*/}
                                                            {/*    className="form-control-file"*/}
                                                            {/*    id="individualPhoto"*/}

                                                            {/*/>*/}
                                                            <ImageUploader
                                                                singleImage={true}
                                                                withPreview={true}
                                                                withIcon={true}
                                                                buttonText='Choose Photo'
                                                                onChange={this.onDropPhoto}
                                                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                                                maxFileSize={5242880}
                                                            />
                                                            {/*<i> {photo}</i>*/}

                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="inputAddress">Organization</label>
                                                            <input
                                                                required={true}
                                                                onChange={this.onChange}
                                                                value={this.state.individualOrganization}
                                                                type="text"
                                                                className="form-control"
                                                                id="individualOrganization"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="inputAddress">Position</label>
                                                            <input
                                                                required={true}
                                                                onChange={this.onChange}
                                                                value={this.state.individualPosition}
                                                                type="text"
                                                                className="form-control"
                                                                id="individualPosition"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="inputAddress">Current Address</label>
                                                            <input
                                                                required={true}
                                                                onChange={this.onChange}
                                                                value={this.state.individualAddress}
                                                                type="text"
                                                                className="form-control"
                                                                id="individualAddress"
                                                            />
                                                        </div>
                                                    </div>

                                                    <button type="submit" className="site-btn text-success">Insert
                                                    </button>
                                                </form>
                                            </div>
                                            <div className="tab-pane" id="institution" role="tabpanel">
                                                <form className="form " onSubmit={this.onOrganizationSubmit}>
                                                    <div className="text-left">
                                                        <div className="form-group">
                                                            <label htmlFor="inputAddress">Organization Name</label>
                                                            <input
                                                                required={true}
                                                                onChange={this.onChange}
                                                                value={this.state.organizationName}
                                                                type="text"
                                                                className="form-control"
                                                                id="organizationName"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="inputAddress">Organization Name in
                                                                Khmer</label>
                                                            <input
                                                                required={true}
                                                                onChange={this.onChange}
                                                                value={this.state.organizationNameKhmer}
                                                                type="text"
                                                                className="form-control"
                                                                id="organizationNameKhmer"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="inputEmail">Register Email</label>
                                                            <input
                                                                required={true}
                                                                onChange={this.onChange}
                                                                value={this.state.organizationEmail}
                                                                type="text"
                                                                className="form-control"
                                                                id="organizationEmail"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="inputPassword">Password</label>
                                                            <input
                                                                required={true}
                                                                onChange={this.onChange}
                                                                value={this.state.organizationPassword}
                                                                type="password"
                                                                className="form-control"
                                                                id="organizationPassword"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="inputAddress">Confirm Password</label>
                                                            <input
                                                                required={true}
                                                                onChange={this.onChange}
                                                                value={this.state.organizationPassword2}
                                                                type="password"
                                                                className="form-control"
                                                                id="organizationPassword2"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="inputAddress">Registered Number</label>
                                                            <input
                                                                required={true}
                                                                onChange={this.onChange}
                                                                value={this.state.organizationRegisteredNumber}
                                                                type="text"
                                                                className="form-control"
                                                                id="organizationRegisteredNumber"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="inputAddress">Tax Identification
                                                                Number</label>
                                                            <input
                                                                required={true}
                                                                onChange={this.onChange}
                                                                value={this.state.organizationTIN}
                                                                type="text"
                                                                className="form-control"
                                                                id="organizationTIN"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="inputAddress">Representative</label>
                                                            <input
                                                                required={true}
                                                                onChange={this.onChange}
                                                                value={this.state.organizationRepresentative}
                                                                type="text"
                                                                className="form-control"
                                                                id="organizationRepresentative"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="inputAddress">Position</label>
                                                            <input
                                                                required={true}
                                                                onChange={this.onChange}
                                                                value={this.state.organizationRePosition}
                                                                type="text"
                                                                className="form-control"
                                                                id="organizationRePosition"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="inputAddress">Current Address</label>
                                                            <input
                                                                required={true}
                                                                onChange={this.onChange}
                                                                value={this.state.organizationAddress}
                                                                type="text"
                                                                className="form-control"
                                                                id="organizationAddress"
                                                            />
                                                        </div>
                                                    </div>

                                                    <button type="submit" className="site-btn text-success">Insert
                                                    </button>
                                                </form>

                                            </div>
                                            {InsertAdmin}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps
)(withRouter(InsertUsers));