import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import NotFound from "../../../../components/404/404";
import axios from "axios";
import {trackPromise} from "react-promise-tracker";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import MergeData from "./MergeData";
import MainNavBar from "../../../../components/Navbars/MainNavBar";

class MergeDataValidator extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            IndividualUndecided : [],
            InstitutionUndecided : [],
            IndividualNotFound : [],
            InstitutionNotFound : [],
            deletePermission:'',
            authorized : false,

        }
    }
    componentDidMount() {
        trackPromise(
            axios.all([
                axios.get('/api-users/getUserPermissions/' + this.props.auth.user.id),
                axios.get('/api-counts/allUndecided/'+ this.props.auth.user.id)
            ]).then( axios.spread((res1,res2)=>{
                this.setState({
                    authorized : res1.data.permissions_list.MergeData,
                    permission_classification: res1.data.permission_classification,
                    deletePermission : res1.data.permissions_list.DeleteData.includes("Histories"),
                    IndividualUndecided : res2.data.IndividualUndecided,
                    InstitutionUndecided : res2.data.InstitutionUndecided,
                    IndividualNotFound : res2.data.IndividualNotFound,
                    InstitutionNotFound : res2.data.InstitutionNotFound,
                    loading: false
                })
            }))
        ).catch( () =>{
            this.setState({
                authorized : false,
                loading: false
            })
        })

    }


    render() {
        return(
            <div>
                <MainNavBar SignIn={false} SignUp={false} dashboard={true} socialMedia={false}/>
                {this.state.loading? <LoadingScreen/> : this.state.authorized ?
                    <MergeData
                        user={this.props.auth.user.id}
                        deletePermission={this.state.deletePermission}
                        IndividualUndecided = {this.state.IndividualUndecided}
                        InstitutionUndecided = {this.state.InstitutionUndecided}
                        IndividualNotFound = {this.state.IndividualNotFound}
                        InstitutionNotFound = {this.state.InstitutionNotFound}
                        permission_classification = {this.state.permission_classification}
                    />
                    : <NotFound/>}
            </div>

        )
    }
}
MergeDataValidator.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(MergeDataValidator));