import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter,Redirect} from "react-router";
import PropTypes from "prop-types";
import axios from "axios";
import {trackPromise} from "react-promise-tracker";
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import WelcomePage from "./WelcomePage";
import MainNavBar from "../../../components/Navbars/MainNavBar";

class SearchSwitch extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            authorized : false,
            IndividualProfiles : [],
            InstitutionProfiles : [],
            checkCount : 0,
            institutionType : [],
            institutionSector : []
        }
    }
    componentDidMount() {
        trackPromise(
            axios.all([
                axios.get("/api-users/getCheckCounts/" + this.props.auth.user.id),
                axios.get("/api-counts/getCheckLogs/" + this.props.auth.user.id),
                axios.get("/api-counts/allMiscellaneous")
            ]).then(axios.spread((res1,res2,res3) =>{
                this.setState({
                    checkCount : res1.data.count,
                    IndividualProfiles : res2.data.IndividualProfiles,
                    InstitutionProfiles : res2.data.InstitutionProfiles,
                    institutionType : res3.data.institutionType,
                    institutionSector : res3.data.institutionSector
                });
                if(this.props.auth.user.type === "AdminUser"){
                    trackPromise(
                        axios.get('/api-users/getUserPermissions/' + this.props.auth.user.id)
                    ).then( res =>{
                        this.setState({
                            authorized : res.data.permissions_list.ViewData.includes("Search"),
                            loading: false
                        })
                    })

                }else{
                    this.setState({
                        authorized : true,
                        loading: false
                    })
                }
            }))
        ).catch( () =>{
            this.setState({
                authorized : false,
                loading: false
            })
        });



    }


    render() {
        return(
            <div>
                <MainNavBar SignIn={false} SignUp={false} dashboard={true} socialMedia={false}/>
                {this.state.loading? <LoadingScreen/> : this.state.authorized ?
                    <WelcomePage
                        count = {this.state.checkCount}
                        IndividualProfiles = {this.state.IndividualProfiles}
                        InstitutionProfiles = {this.state.InstitutionProfiles}
                        institutionType = {this.state.institutionType}
                        institutionSector = {this.state.institutionSector}
                    />
                : <Redirect to="/dashboard" />}
            </div>

        )
    }
}
SearchSwitch.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(SearchSwitch));