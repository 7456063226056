import React, {Component} from "react";
import axios from 'axios';
import Alert from "react-s-alert";

import { FiArrowUp } from "react-icons/fi";

import {  animateScroll as scroll } from 'react-scroll'

class EditOrganization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            name_in_khmer: '',
            register_number: '',
            tax_identification_number: '',
            representative: '',
            institution_type : '',
            sector:'',
            current_address:'',
            contact:'',
            fax:'',
            email:'',
            name_p: '',
            name_in_khmer_p: '',
            register_number_p: '',
            tax_identification_number_p: '',
            representative_p: '',
            institution_type_p : '',
            sector_p:'',
            current_address_p:'',
            contact_p:'',
            fax_p:'',
            email_p:''
        };
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    scrollToTop() {
        scroll.scrollToTop({duration:200})
    }

    onSubmit = e => {
        e.preventDefault();
        const update = new FormData();
        const viewP = {
            name: this.state.name_p?this.state.name_p:this.props.dataRevealPercentage.name,
            name_in_khmer: this.state.name_in_khmer_p?this.state.name_in_khmer_p:this.props.dataRevealPercentage.name_in_khmer,
            register_number: this.state.register_number_p?this.state.register_number_p:this.props.dataRevealPercentage.register_number,
            tax_identification_number: this.state.tax_identification_number_p?this.state.tax_identification_number_p:this.props.dataRevealPercentage.tax_identification_number,
            representative: this.state.representative_p?this.state.representative_p:this.props.dataRevealPercentage.representative,
            institution_type : this.state.institution_type_p?this.state.institution_type_p:this.props.dataRevealPercentage.institution_type,
            sector:this.state.sector_p?this.state.sector_p:this.props.dataRevealPercentage.sector,
            current_address:this.state.current_address_p?this.state.current_address_p:this.props.dataRevealPercentage.current_address,
            contact:this.state.contact_p?this.state.contact_p:this.props.dataRevealPercentage.contact,
            fax:this.state.fax_p?this.state.fax_p:this.props.dataRevealPercentage.fax,
            email:this.state.email_p?this.state.email_p:this.props.dataRevealPercentage.email,
        };
        // update.append("name",this.state.name ? this.state.name : this.props.user.name);
        // update.append("name_in_khmer",this.state.name_in_khmer ? this.state.name_in_khmer : this.props.user.name_in_khmer);
        // update.append("register_number",this.state.register_number ? this.state.register_number :this.props.user.register_number);
        // update.append("tax_identification_number",this.state.tax_identification_number ? this.state.tax_identification_number : this.props.user.tax_identification_number);
        update.append("representative",this.state.representative?this.state.representative : this.props.user.representative);
        // update.append("institution_type",this.state.institution_type?this.state.institution_type : this.props.user.institution_type);
        // update.append("sector",this.state.sector ? this.state.sector : this.props.user.sector);
        update.append("current_address",this.state.current_address?this.state.current_address : this.props.user.current_address);
        update.append("contact",this.state.contact?this.state.contact : this.props.user.contact);
        update.append("fax",this.state.fax?this.state.fax : this.props.user.fax);
        update.append("email",this.state.email?this.state.email : this.props.user.email);
        update.append("dataRevealPercentage",JSON.stringify(viewP));

        axios.patch('/api-institutionProfile/update/' + this.props.user._id +'/' + this.props.member,update).then(res => {
            window.location.reload();
        }).catch(err => {

            window.location.reload();
        })
    };
    onChange = e => {
        e.preventDefault();
        this.setState({[e.target.id]: e.target.value});
    };

    render() {
        console.log(this.props);
        return (
            <React.Fragment>
                <Alert stack={{limit: 3}}/>
                <h3 className="font-weight-bold">Edit Institution Record</h3>
                <form className="form editPanel" onSubmit={this.onSubmit}
                      encType="multipart/form-data" method="POST"
                >
                    <div className="text-left">
                        <div className="row">
                            <div className="col">
                                <p className="font-weight-bold">Name </p>
                            </div>
                            <div className="col">
                                {/*<input*/}
                                {/*    onChange={this.onChange}*/}
                                {/*    className="form-control"*/}
                                {/*    id="name"*/}
                                {/*    required*/}
                                {/*    defaultValue={this.props.user.name}*/}

                                {/*/>*/}
                                <p className="font-weight-normal">{this.props.user.name} </p>
                            </div>
                            {this.props.formatReportOrNot?<div className="col-sm-2">
                                <select
                                    onChange={this.onChange}
                                    required={true}
                                    //value={this.state.name_p}
                                    id="name_p"
                                    className="form-control"
                                    //defaultValue={this.props.dataRevealPercentage.name}
                                >
                                    <option selected={this.props.dataRevealPercentage.name === 0}value={0}>0%</option>
                                    <option selected={this.props.dataRevealPercentage.name === 30}value={30}>30%</option>
                                    <option selected={this.props.dataRevealPercentage.name === 50}value={50}>50%</option>
                                    <option selected={this.props.dataRevealPercentage.name === 75} value={75}>75%</option>
                                    <option selected={this.props.dataRevealPercentage.name === 100}value={100}>100%</option>
                                </select>
                            </div>:<span/>}

                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="font-weight-bold">Name In Khmer </p>
                            </div>
                            <div className="col">
                                {/*<input*/}
                                {/*    onChange={this.onChange}*/}
                                {/*    className="form-control"*/}
                                {/*    defaultValue={this.props.user.name_in_khmer}*/}
                                {/*    id="name_in_khmer"*/}
                                {/*    required*/}
                                {/*/>*/}
                                <p className="font-weight-normal">{this.props.user.name_in_khmer}</p>
                            </div>
                            {this.props.formatReportOrNot?<div className="col-sm-2">
                                <select
                                    onChange={this.onChange}
                                    required={true}
                                    //value={this.state.name_p}
                                    id="name_in_khmer_p"
                                    className="form-control"
                                    //defaultValue={this.props.dataRevealPercentage.name}
                                >
                                    <option selected={this.props.dataRevealPercentage.name_in_khmer === 0}value={0}>0%</option>
                                    <option selected={this.props.dataRevealPercentage.name_in_khmer === 30}value={30}>30%</option>
                                    <option selected={this.props.dataRevealPercentage.name_in_khmer === 50}value={50}>50%</option>
                                    <option selected={this.props.dataRevealPercentage.name_in_khmer === 75} value={75}>75%</option>
                                    <option selected={this.props.dataRevealPercentage.name_in_khmer === 100}value={100}>100%</option>
                                </select>
                            </div>:<span/>}

                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="font-weight-bold">Tax Identification Number: </p>
                            </div>
                            <div className="col">
                                {/*<input*/}
                                {/*    onChange={this.onChange}*/}
                                {/*    className="form-control"*/}
                                {/*    defaultValue={this.props.user.tax_identification_number}*/}
                                {/*    id="identification_number"*/}
                                {/*    required*/}
                                {/*/>*/}
                                <p className="font-weight-normal">{this.props.user.tax_identification_number}</p>
                            </div>
                            {this.props.formatReportOrNot?<div className="col-sm-2">
                                <select
                                    onChange={this.onChange}
                                    required={true}
                                    //value={this.state.name_p}
                                    id="tax_identification_number_p"
                                    className="form-control"
                                    //defaultValue={this.props.dataRevealPercentage.name}
                                >
                                    <option selected={this.props.dataRevealPercentage.tax_identification_number === 0}value={0}>0%</option>
                                    <option selected={this.props.dataRevealPercentage.tax_identification_number === 30}value={30}>30%</option>
                                    <option selected={this.props.dataRevealPercentage.tax_identification_number === 50}value={50}>50%</option>
                                    <option selected={this.props.dataRevealPercentage.tax_identification_number === 75} value={75}>75%</option>
                                    <option selected={this.props.dataRevealPercentage.tax_identification_number === 100}value={100}>100%</option>
                                </select>
                            </div>:<span/>}

                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="font-weight-bold">Registered Number: </p>
                            </div>
                            <div className="col">
                                {/*<input*/}
                                {/*    onChange={this.onChange}*/}
                                {/*    className="form-control"*/}
                                {/*    defaultValue={this.props.user.register_number}*/}
                                {/*    id="register_number"*/}
                                {/*    required*/}
                                {/*/>*/}
                                <p className="font-weight-normal">{this.props.user.register_number}</p>
                            </div>
                            {this.props.formatReportOrNot?<div className="col-sm-2">
                                <select
                                    onChange={this.onChange}
                                    required={true}
                                    //value={this.state.name_p}
                                    id="register_number_p"
                                    className="form-control"
                                    //defaultValue={this.props.dataRevealPercentage.name}
                                >
                                    <option selected={this.props.dataRevealPercentage.register_number === 0}value={0}>0%</option>
                                    <option selected={this.props.dataRevealPercentage.register_number === 30}value={30}>30%</option>
                                    <option selected={this.props.dataRevealPercentage.register_number === 50}value={50}>50%</option>
                                    <option selected={this.props.dataRevealPercentage.register_number === 75} value={75}>75%</option>
                                    <option selected={this.props.dataRevealPercentage.register_number === 100}value={100}>100%</option>
                                </select>
                            </div>:<span/>}

                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="font-weight-bold">Institution Type: </p>
                            </div>
                            <div className="col">
                                {/*<input*/}
                                {/*    onChange={this.onChange}*/}
                                {/*    className="form-control"*/}
                                {/*    defaultValue={this.props.user.institution_type}*/}
                                {/*    id="institution_type"*/}
                                {/*    required*/}
                                {/*/>*/}
                                <p className="font-weight-normal">{this.props.user.institution_type}</p>
                            </div>
                            {this.props.formatReportOrNot?<div className="col-sm-2">
                                <select
                                    onChange={this.onChange}
                                    required={true}
                                    //value={this.state.name_p}
                                    id="institution_type_p"
                                    className="form-control"
                                    //defaultValue={this.props.dataRevealPercentage.name}
                                >
                                    <option selected={this.props.dataRevealPercentage.institution_type === 0}value={0}>0%</option>
                                    <option selected={this.props.dataRevealPercentage.institution_type === 30}value={30}>30%</option>
                                    <option selected={this.props.dataRevealPercentage.institution_type === 50}value={50}>50%</option>
                                    <option selected={this.props.dataRevealPercentage.institution_type === 75} value={75}>75%</option>
                                    <option selected={this.props.dataRevealPercentage.institution_type === 100}value={100}>100%</option>
                                </select>
                            </div>:<span/>}

                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="font-weight-bold">Sector: </p>
                            </div>
                            <div className="col">
                                {/*<input*/}
                                {/*    onChange={this.onChange}*/}
                                {/*    className="form-control"*/}
                                {/*    defaultValue={this.props.user.sector}*/}
                                {/*    id="sector"*/}
                                {/*    required*/}
                                {/*/>*/}
                                <p className="font-weight-normal">{this.props.user.sector}</p>
                            </div>
                            {this.props.formatReportOrNot?<div className="col-sm-2">
                                <select
                                    onChange={this.onChange}
                                    required={true}
                                    //value={this.state.name_p}
                                    id="sector_p"
                                    className="form-control"
                                    //defaultValue={this.props.dataRevealPercentage.name}
                                >
                                    <option selected={this.props.dataRevealPercentage.sector === 0}value={0}>0%</option>
                                    <option selected={this.props.dataRevealPercentage.sector === 30}value={30}>30%</option>
                                    <option selected={this.props.dataRevealPercentage.sector === 50}value={50}>50%</option>
                                    <option selected={this.props.dataRevealPercentage.sector === 75} value={75}>75%</option>
                                    <option selected={this.props.dataRevealPercentage.sector === 100}value={100}>100%</option>
                                </select>
                            </div>:<span/>}

                        </div>


                        <div className="row">
                            <div className="col">
                                <p className="font-weight-bold">Representative: </p>
                            </div>
                            <div className="col">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.user.representative}
                                    id="representative"
                                    required
                                />
                            </div>
                            {this.props.formatReportOrNot?<div className="col-sm-2">
                                <select
                                    onChange={this.onChange}
                                    required={true}
                                    //value={this.state.name_p}
                                    id="representative_p"
                                    className="form-control"
                                    //defaultValue={this.props.dataRevealPercentage.name}
                                >
                                    <option selected={this.props.dataRevealPercentage.representative === 0}value={0}>0%</option>
                                    <option selected={this.props.dataRevealPercentage.representative === 30}value={30}>30%</option>
                                    <option selected={this.props.dataRevealPercentage.representative === 50}value={50}>50%</option>
                                    <option selected={this.props.dataRevealPercentage.representative === 75} value={75}>75%</option>
                                    <option selected={this.props.dataRevealPercentage.representative === 100}value={100}>100%</option>
                                </select>
                            </div>:<span/>}

                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="font-weight-bold">Current Address: </p>
                            </div>
                            <div className="col">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.user.current_address}
                                    id="current_address"
                                    required
                                />
                            </div>
                            {this.props.formatReportOrNot?<div className="col-sm-2">
                                <select
                                    onChange={this.onChange}
                                    required={true}
                                    //value={this.state.name_p}
                                    id="current_address_p"
                                    className="form-control"
                                    //defaultValue={this.props.dataRevealPercentage.name}
                                >
                                    <option selected={this.props.dataRevealPercentage.current_address === 0}value={0}>0%</option>
                                    <option selected={this.props.dataRevealPercentage.current_address === 30}value={30}>30%</option>
                                    <option selected={this.props.dataRevealPercentage.current_address === 50}value={50}>50%</option>
                                    <option selected={this.props.dataRevealPercentage.current_address === 75} value={75}>75%</option>
                                    <option selected={this.props.dataRevealPercentage.current_address === 100}value={100}>100%</option>
                                </select>
                            </div>:<span/>}

                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="font-weight-bold">Contact Number: </p>
                            </div>
                            <div className="col">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.user.contact}
                                    id="contact"
                                    required
                                />
                            </div>
                            {this.props.formatReportOrNot?<div className="col-sm-2">
                                <select
                                    onChange={this.onChange}
                                    required={true}
                                    //value={this.state.name_p}
                                    id="contact_p"
                                    className="form-control"
                                    //defaultValue={this.props.dataRevealPercentage.name}
                                >
                                    <option selected={this.props.dataRevealPercentage.contact === 0}value={0}>0%</option>
                                    <option selected={this.props.dataRevealPercentage.contact === 30}value={30}>30%</option>
                                    <option selected={this.props.dataRevealPercentage.contact === 50}value={50}>50%</option>
                                    <option selected={this.props.dataRevealPercentage.contact === 75} value={75}>75%</option>
                                    <option selected={this.props.dataRevealPercentage.contact === 100}value={100}>100%</option>
                                </select>
                            </div>:<span/>}

                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="font-weight-bold">Fax: </p>
                            </div>
                            <div className="col">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.user.fax}
                                    id="fax"
                                    required
                                />
                            </div>
                            {this.props.formatReportOrNot?<div className="col-sm-2">
                                <select
                                    onChange={this.onChange}
                                    required={true}
                                    //value={this.state.name_p}
                                    id="fax_p"
                                    className="form-control"
                                    //defaultValue={this.props.dataRevealPercentage.name}
                                >
                                    <option selected={this.props.dataRevealPercentage.fax === 0}value={0}>0%</option>
                                    <option selected={this.props.dataRevealPercentage.fax === 30}value={30}>30%</option>
                                    <option selected={this.props.dataRevealPercentage.fax === 50}value={50}>50%</option>
                                    <option selected={this.props.dataRevealPercentage.fax === 75} value={75}>75%</option>
                                    <option selected={this.props.dataRevealPercentage.fax === 100}value={100}>100%</option>
                                </select>
                            </div>:<span/>}
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="font-weight-bold">Email: </p>
                            </div>
                            <div className="col">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.user.email}
                                    id="email"
                                    required
                                />
                            </div>
                            {this.props.formatReportOrNot?<div className="col-sm-2">
                                <select
                                    onChange={this.onChange}
                                    required={true}
                                    //value={this.state.name_p}
                                    id="email_p"
                                    className="form-control"
                                    //defaultValue={this.props.dataRevealPercentage.name}
                                >
                                    <option selected={this.props.dataRevealPercentage.email === 0}value={0}>0%</option>
                                    <option selected={this.props.dataRevealPercentage.email === 30}value={30}>30%</option>
                                    <option selected={this.props.dataRevealPercentage.email === 50}value={50}>50%</option>
                                    <option selected={this.props.dataRevealPercentage.email === 75} value={75}>75%</option>
                                    <option selected={this.props.dataRevealPercentage.email === 100}value={100}>100%</option>
                                </select>
                            </div>:<span/>}

                        </div>


                    </div>


                    <button type="submit" className="site-btn text-info">Update Info
                    </button>

                </form>
                <a   href="#editProfile"
                     data-toggle="collapse"
                     aria-expanded="false"
                     aria-controls="editProfile"
                     onClick={this.scrollToTop}
                >
                    <FiArrowUp size ="4em" color="black"/>
                </a>
            </React.Fragment>
        )
    }
}

export default EditOrganization;