import React, {Component} from "react";
import axios from 'axios';
import Alert from "react-s-alert";
import ImageUploader from "react-images-upload";

const Members = "Member";
const Profiles = "Profile";
const Histories = "Histories";
const MembersActivities = "MembersActivities";
const ExportPDF = "ExportPDF";
const ImportFile = "ImportFile";
const ReferenceCheckReports = "ReferenceCheckReports";
const FormattingReports = "FormattingReports";
const Search = "Search";
const Miscellaneous = "Miscellaneous";
const Merge = "Merge";

class UpdateAdminInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            permission_classification: '',
            identification_number: '',
            current_address: '',
            photo: [],
            passport: [],
            trustLevel :'',
            //Permission_list
            insertMembers : this.props.user.permissions_list.InsertData.includes("Member"),
            insertProfiles : this.props.user.permissions_list.InsertData.includes("Profile"),
            insertHistories : this.props.user.permissions_list.InsertData.includes("Histories"),
            insertMiscellaneous : this.props.user.permissions_list.InsertData.includes("Miscellaneous"),
            viewMembers : this.props.user.permissions_list.ViewData.includes("Member"),
            viewProfiles : this.props.user.permissions_list.ViewData.includes("Profile"),
            viewHistories : this.props.user.permissions_list.ViewData.includes("Histories"),
            viewMiscellaneous : this.props.user.permissions_list.ViewData.includes("Miscellaneous"),
            viewFormattingReports : this.props.user.permissions_list.ViewData.includes("FormattingReports"),
            viewImportFile : this.props.user.permissions_list.ViewData.includes("ImportFile"),
            viewExportPDF : this.props.user.permissions_list.ViewData.includes("ExportPDF"),
            viewCheckReport : this.props.user.permissions_list.ViewData.includes("ReferenceCheckReports"),
            viewMembersActivities : this.props.user.permissions_list.ViewData.includes("MembersActivities"),
            viewSearch : this.props.user.permissions_list.ViewData.includes("Search"),
            editMembers : this.props.user.permissions_list.EditData.includes("Member"),
            editProfiles : this.props.user.permissions_list.EditData.includes("Profile"),
            editHistories : this.props.user.permissions_list.EditData.includes("Histories"),
            editMiscellaneous : this.props.user.permissions_list.EditData.includes("Miscellaneous"),
            editFormattingReports : this.props.user.permissions_list.EditData.includes("FormattingReports"),
            deleteMembers : this.props.user.permissions_list.DeleteData.includes("Member"),
            deleteProfiles : this.props.user.permissions_list.DeleteData.includes("Profile"),
            deleteHistories : this.props.user.permissions_list.DeleteData.includes("Histories"),
            deleteMiscellaneous : this.props.user.permissions_list.DeleteData.includes("Miscellaneous"),
            Merge : this.props.user.permissions_list.MergeData
        }
    }

    onSubmit = e => {
        e.preventDefault();
        const update = new FormData();
        let updatePassportOrNot;
        let updatePhotoOrNot;
        if (this.state.photo[0]) {
            updatePhotoOrNot = "Update";
        }
        if (this.state.passport[0]) {
            updatePassportOrNot = "Update";
        }
        const InsertData = [
            this.state.insertMembers ? Members : '',
            this.state.insertProfiles ? Profiles : '',
            this.state.insertHistories ? Histories : '',
            this.state.insertMiscellaneous ? Miscellaneous : ''
        ];
        const ViewData = [
            this.state.viewMembers ? Members :'',
            this.state.viewProfiles ? Profiles :'',
            this.state.viewHistories ? Histories :'',
            this.state.viewMembersActivities ? MembersActivities : '',
            this.state.viewImportFile ? ImportFile : '',
            this.state.viewExportPDF ? ExportPDF : '',
            this.state.viewCheckReport ? ReferenceCheckReports :'',
            this.state.viewFormattingReports ? FormattingReports : '',
            this.state.viewSearch ? Search :'',
            this.state.viewMiscellaneous ? Miscellaneous : ''

        ];
        const EditData = [
            this.state.editMembers ? Members :'',
            this.state.editProfiles ? Profiles :'',
            this.state.editHistories ? Histories :'',
            this.state.editMiscellaneous ? Miscellaneous :'',
            this.state.editFormattingReports ? FormattingReports : ''
        ];
        const DeleteData = [
            this.state.deleteMembers? Members : '',
            this.state.deleteProfiles? Profiles : '',
            this.state.deleteHistories? Histories :'',
            this.state.deleteMiscellaneous? Miscellaneous : ''
        ];
        const MergeData = this.state.Merge ? true :this.props.user.permissions_list.MergeData? "True" : "False";
        update.append("myFiles", this.state.photo[0]);
        update.append("myFiles", this.state.passport[0]);
        update.append("name", this.state.name ? this.state.name : this.props.user.name);
        update.append("trustLevel", this.state.trustLevel ? this.state.trustLevel : this.props.user.trustLevel);
        update.append("permission_classification", this.state.permission_classification ? this.state.permission_classification : this.props.user.permission_classification);
        //update.append("name_in_khmer",this.state.name_in_khmer ? this.state.name_in_khmer : this.props.user.name_in_khmer);
        //update.append("date_of_birth",this.state.date_of_birth ? this.state.date_of_birth :this.props.user.date_of_birth);
        update.append("identification_number", this.state.identification_number ? this.state.identification_number : this.props.user.identification_number);
        update.append("current_address", this.state.current_address ? this.state.current_address : this.props.user.current_address);
        //update.append("organization",this.state.organization?this.state.organization : this.props.user.organization);
        //update.append("position",this.state.position?this.state.position : this.props.user.position);
        //update.append("reference_check_number",this.state.number?this.state.number : this.props.user.reference_check_number);
        update.append("photo", updatePhotoOrNot);
        update.append("passport", updatePassportOrNot);
        update.append('InsertData',JSON.stringify(InsertData));
        update.append('ViewData',JSON.stringify(ViewData));
        update.append('EditData',JSON.stringify(EditData));
        update.append('DeleteData',JSON.stringify(DeleteData));
        update.append('MergeData',MergeData);
        axios.put('/api-adminMember/update/' + this.props.user._id, update).then(res => {
            window.location.reload();
        }).catch(err => {
            window.location.reload();
        })
    };
    onChange = e => {
        e.preventDefault();
        this.setState({[e.target.id]: e.target.value});
    };
    onDropPassport = file => {
        this.setState({
            passport: this.state.passport.concat(file)
        });
    };

    onDropPhoto = file => {
        this.setState({
            photo: this.state.photo.concat(file)
        });
    };
    handleChange = e => {
        this.setState({[e.target.id]: e.target.checked});
    };

    render() {
        let EditPermission;
        if(this.props.system){
            EditPermission = <div className="row">
                <div className="col-sm-4 ml-auto mr-auto">
                    <p className="font-weight-bold">Permission Classification</p>
                </div>
                <div className="col-sm-4 ml-auto mr-auto">

                    <select
                        onChange={this.onChange}
                        required={true}
                        //value={this.state.permission_classification}
                        id="permission_classification"
                        className="form-control"
                        defaultValue={this.props.user.permission_classification}
                    >
                        {/*<option defaultValue>Choose...</option>*/}
                        <option value="Data Entry">Data Entry</option>
                        {/*<option value="Casual Member">Casual Member</option>*/}
                        <option value="Ordinary Member">Ordinary Member</option>
                        <option value="Special Member">Special Member</option>
                        <option value="System Admin">System Admin</option>
                    </select>

                </div>
            </div>;
        }
        let trustLevelOrNot;

        if(!this.props.user.permissions_list.ViewData.includes("FormattingReports") && !this.props.user.permissions_list.EditData.includes("FormattingReports")){
            if(this.props.system._id !== this.props.id){
                trustLevelOrNot = <div className="row">
                    <div className="col-sm-4 ml-auto mr-auto">
                        <p className="font-weight-bold">Trust Level: </p>
                    </div>
                    <div className="col-sm-4 ml-auto mr-auto">
                        <select
                            onChange={this.onChange}
                            className="form-control"

                            id="trustLevel"
                            required
                        >
                            <option selected={this.props.user.trustLevel === 0} value={0}>0%</option>
                            <option selected={this.props.user.trustLevel === 30} value={30}>30%</option>
                            <option selected={this.props.user.trustLevel === 50} value={50}>50%</option>
                            <option selected={this.props.user.trustLevel === 75} value={75}>75%</option>
                            <option selected={this.props.user.trustLevel === 100} value={100}>100%</option>

                        </select>
                    </div>
                </div>;
            }

        }

        //console.log(this.props);
        return (
            <React.Fragment>
                <Alert stack={{limit: 3}}/>
                <form className="form editPanel" onSubmit={this.onSubmit}
                      encType="multipart/form-data" method="POST"
                >
                    <div className="text-left">
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Name </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    id="name"
                                    required
                                    defaultValue={this.props.user.name}

                                />
                            </div>
                        </div>

                        {EditPermission}

                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Email</p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">{this.props.user.email}</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Identification Number: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.user.identification_number}
                                    id="identification_number"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Current Address: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.user.current_address}
                                    id="current_address"
                                    required
                                />
                            </div>
                        </div>
                        {trustLevelOrNot}
                        {this.props.system === true ? <div className="row">

                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold ">Item</p>
                                <h6 className="font-weight-normal category">Members</h6>
                                <h6 className="font-weight-normal category">Profiles</h6>
                                <h6 className="font-weight-normal category">Histories</h6>
                                <h6 className="font-weight-normal category">Miscellaneous</h6>
                                <h6 className="font-weight-normal category">Format Report</h6>
                                <h6 className="font-weight-normal category">Import File</h6>
                                <h6 className="font-weight-normal category">Export PDF</h6>
                                <h6 className="font-weight-normal category">Check Report</h6>
                                <h6 className="font-weight-normal category">Member Activities</h6>
                                <h6 className="font-weight-normal category">Search</h6>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <div className="row">
                                    <div className="col text-center">
                                        <h6 className="category">Insert</h6>
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="insertMembers"
                                                onChange={this.handleChange}
                                                //checked = {this.state.insertMembers}
                                                defaultChecked={this.props.user.permissions_list.InsertData.includes("Member")}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="insertProfiles"
                                                onChange={this.handleChange}
                                                //checked = {this.state.insertProfiles}
                                                defaultChecked={this.props.user.permissions_list.InsertData.includes("Profile")}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="insertHistories"
                                                onChange={this.handleChange}
                                                //checked = {this.state.insertHistories}
                                                defaultChecked={this.props.user.permissions_list.InsertData.includes("Histories")}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="insertMiscellaneous"
                                                onChange={this.handleChange}
                                                //checked = {this.state.insertMiscellaneous}
                                                defaultChecked={this.props.user.permissions_list.InsertData.includes("Miscellaneous")}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox disable-checkbox" disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox disable-checkbox" disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>

                                    </div>
                                    <div className="col text-center">
                                        <h6 className="category ">View/Action</h6>
                                        <div className="form-group">

                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="viewMembers"
                                                onChange={this.handleChange}
                                                //checked = {this.state.viewMembers}
                                                defaultChecked={this.props.user.permissions_list.ViewData.includes("Member")}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="viewProfiles"
                                                onChange={this.handleChange}
                                                //checked = {this.state.viewProfiles}
                                                defaultChecked={this.props.user.permissions_list.ViewData.includes("Profile")}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="viewHistories"
                                                onChange={this.handleChange}
                                                //checked = {this.state.viewHistories}
                                                defaultChecked={this.props.user.permissions_list.ViewData.includes("Histories")}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="viewMiscellaneous"
                                                onChange={this.handleChange}
                                                //checked = {this.state.viewMiscellaneous}
                                                defaultChecked={this.props.user.permissions_list.ViewData.includes("Miscellaneous")}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="viewFormattingReports"
                                                onChange={this.handleChange}
                                                //checked = {this.state.viewFormattingReports}
                                                defaultChecked={this.props.user.permissions_list.ViewData.includes("FormattingReports")}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="viewImportFile"
                                                onChange={this.handleChange}
                                                //checked = {this.state.viewExportPDF}
                                                defaultChecked={this.props.user.permissions_list.ViewData.includes("ImportFile")}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="viewExportPDF"
                                                onChange={this.handleChange}
                                                //checked = {this.state.viewExportPDF}
                                                defaultChecked={this.props.user.permissions_list.ViewData.includes("ExportPDF")}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="viewCheckReport"
                                                onChange={this.handleChange}
                                                //checked = {this.state.viewCheckReport}
                                                defaultChecked={this.props.user.permissions_list.ViewData.includes("ReferenceCheckReports")}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="viewMembersActivities"
                                                onChange={this.handleChange}
                                                //checked = {this.state.viewMembersActivities}
                                                defaultChecked={this.props.user.permissions_list.ViewData.includes("MembersActivities")}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="viewSearch"
                                                onChange={this.handleChange}
                                                //checked = {this.state.viewSearch}
                                                defaultChecked={this.props.user.permissions_list.ViewData.includes("Search")}
                                            />
                                        </div>

                                    </div>
                                    <div className="col text-center">
                                        <h6 className="category">Edit</h6>
                                        <div className="form-group">

                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="editMembers"
                                                onChange={this.handleChange}
                                                defaultChecked={this.props.user.permissions_list.EditData.includes("Member")}
                                                //checked = {this.state.editMembers}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="editProfiles"
                                                onChange={this.handleChange}
                                                //checked = {this.state.editProfiles}
                                                defaultChecked={this.props.user.permissions_list.EditData.includes("Profile")}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="editHistories"
                                                onChange={this.handleChange}
                                                defaultChecked={this.props.user.permissions_list.EditData.includes("Histories")}
                                                //checked = {this.state.editHistories}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="editMiscellaneous"
                                                onChange={this.handleChange}
                                                defaultChecked={this.props.user.permissions_list.EditData.includes("Miscellaneous")}
                                                //checked = {this.state.editMiscellaneous}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="editFormattingReports"
                                                onChange={this.handleChange}
                                                defaultChecked={this.props.user.permissions_list.EditData.includes("FormattingReports")}
                                                //checked = {this.state.editFormattingReports}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>

                                    </div>
                                    <div className="col text-center">
                                        <h6 className="category text-danger">Delete</h6>
                                        <div className="form-group">

                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="deleteMembers"
                                                onChange={this.handleChange}
                                                defaultChecked={this.props.user.permissions_list.DeleteData.includes("Member")}
                                                //checked = {this.state.deleteMembers}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="deleteProfiles"
                                                onChange={this.handleChange}
                                                defaultChecked={this.props.user.permissions_list.EditData.includes("Profile")}
                                                //checked = {this.state.deleteProfiles}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="deleteHistories"
                                                onChange={this.handleChange}
                                                defaultChecked={this.props.user.permissions_list.EditData.includes("Histories")}
                                                //checked = {this.state.deleteHistories}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="deleteMiscellaneous"
                                                onChange={this.handleChange}
                                                //checked = {this.state.deleteMiscellaneous}
                                                defaultChecked={this.props.user.permissions_list.EditData.includes("Miscellaneous")}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>

                                    </div>
                                    <div className="col text-center">
                                        <h6 className="category text-warning">Merge</h6>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                className="big-checkbox"
                                                id="Merge"
                                                onChange={this.handleChange}
                                                //checked = {this.state.Merge}
                                                defaultChecked={this.props.user.permissions_list.MergeData}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox"disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" className="big-checkbox" disabled/>
                                        </div>

                                    </div>
                                </div>

                            </div>


                        </div> : <span/>}
                        <ImageUploader
                            singleImage={true}
                            withPreview={true}
                            withIcon={true}
                            buttonText='Update Photo'
                            onChange={this.onDropPhoto}
                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                            maxFileSize={5242880}
                        />
                        <ImageUploader
                            singleImage={true}
                            withPreview={true}
                            withIcon={true}
                            buttonText='Update Passport'
                            onChange={this.onDropPassport}
                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                            maxFileSize={5242880}
                        />
                    </div>


                    <button type="submit" className="site-btn text-success">Update Info</button>
                </form>
            </React.Fragment>
        )
    }
}

export default UpdateAdminInfo;