import React, {Component} from "react";
import { Link } from "react-router-dom";

import "./404.scss";
import MainNavBar from "../Navbars/MainNavBar";

class NotFound extends Component {
    render() {
        return (
            <React.Fragment>

                <div className="not-found">
                    <Link to="/">
                        <b>404</b>
                    </Link>
                    <br />
                    <p>The requested page was not found on our server.</p>
                    <p>
                        Either you the url you typed in is incorrect, you do not have access
                        privileges to the page, or the page you are looking for has been
                        removed.
                    </p>
                </div>
            </React.Fragment>

        );
    }
};

export default NotFound;
