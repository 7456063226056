import {usePromiseTracker} from "react-promise-tracker";
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import React from "react";

import * as loading from "./animation";
import * as doneLoading from "./doneLoading";

import "./style.css";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
const defaultOptions2 = {
    loop: false,
    autoplay: true,
    animationData: doneLoading.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

const LoadingScreen = props => {
    const {promiseInProgress} = usePromiseTracker();
    return (

        <FadeIn>
            <div className="d-flex justify-content-center align-items-center loading-screen">
                {promiseInProgress ? (
                    <Lottie options={defaultOptions} height={120} width={120} />
                ) : (
                    <Lottie options={defaultOptions2} height={120} width={120} />
                )}
            </div>
        </FadeIn>
    );
};

export default LoadingScreen;