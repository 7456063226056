import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import AdminDashboard from "./AdminDashboard";
import Dashboard from "./Dashboard";
import MainNavBar from "../../../components/Navbars/MainNavBar";
class DashboardSwitch extends Component{

    render() {

        // if(this.props.auth.user.type === "AdminUser"){
        //     return (
        //         <AdminDashboard/>
        //     )
        // }else {
        //     return (
        //         <Dashboard/>
        //     )
        // }
        return(
            <div>
                <MainNavBar SignIn ={false} SignUp={false} dashboard = {false} socialMedia={false}/>
                {this.props.auth.user.type === "AdminUser"?<AdminDashboard/>:<Dashboard/>}
            </div>
        )

    }


}

DashboardSwitch.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(DashboardSwitch));