import React, {Component} from "react";

import ImageUploader from "react-images-upload";
import { Button, ButtonGroup } from 'reactstrap';
import axios from 'axios';

import {IoIosWarning} from "react-icons/all";
import Modal from "react-bootstrap/Modal";


class InsertInstitutionHistory extends Component{
    constructor(props) {
        super(props);
        this.state = {
            fromYear:'',
            toYear:'',
            fromMonth:'',
            toMonth:'',
            fromDay:'',
            toDay:'',
            reference:'',
            summary:' ',
            positive: true,
            negative: false,
            evidence_document:[],
            access_classification : '',
            status :'',
            errors:'',
            open: false
        }
    }
    onChange = e => {
        e.preventDefault();
        this.setState({[e.target.id]: e.target.value});
    };
    toggleModal = e =>{
        this.setState({
            open : !this.state.open
        })
    };
    onSubmit = e =>{
        e.preventDefault();
        const Data = new FormData();
        Data.append('myFile',this.state.evidence_document[0]);
        Data.append('fromYear',this.state.fromYear);
        Data.append('toYear',this.state.toYear);
        Data.append('fromMonth',this.state.fromMonth);
        Data.append('toMonth',this.state.toMonth);
        Data.append('fromDay',this.state.fromDay);
        Data.append('toDay',this.state.toDay);
        Data.append('reference',this.state.reference);
        Data.append('summary',this.state.summary);
        Data.append('info_type',!!this.state.positive);
        Data.append('data_contributor', this.props.user);
        Data.append('profile', this.props.profile);
        Data.append('status', this.state.status);
        Data.append('access_classification', this.state.access_classification);
        axios.post('/api-institutionProfile/add_history',Data).then( res =>{
            window.location.reload();
        }).catch( err =>{
            this.setState({
                open : true,
                errors : err.response.data.msg
            });
        })

    };
    onDropEvidence = file => {
        this.setState({
            evidence_document: this.state.evidence_document.concat(file)
        });
    };
    render() {
        //console.log(this.state);
        const errorBox =
            <Modal
                show={this.state.open}
                onHide={this.toggleModal}
            >
                <Modal.Header closeButton>
                    <h3 className="font-weight-bold text-danger"><IoIosWarning/>Error!</h3>
                </Modal.Header>
                <Modal.Body>
                    {this.state.errors}
                </Modal.Body>
                <Modal.Footer>
                    <button className="site-btn text-danger text-center"
                            onClick={this.toggleModal}>Okay
                    </button>
                </Modal.Footer>
            </Modal>;
        let StatusOrNot;
        if(this.props.status){
            StatusOrNot = <div className="row">
                <div className="col-sm-4 ml-auto mr-auto">
                    <p className="font-weight-bold">History Status: </p>
                </div>
                <div className="col-sm-4 ml-auto mr-auto">
                    <select
                        id="status"
                        className="form-control"
                        onChange={this.onChange}
                        value={this.state.status}
                    >
                        <option selected>Select One...</option>
                        <option selected>In Review</option>
                        <option >Published</option>
                        <option >Rejected</option>
                    </select>
                </div>
            </div>
        }
        return(
            <React.Fragment>
                {errorBox}
                <form className="form " onSubmit={this.onSubmit}
                      encType="multipart/form-data" method="POST"
                >

                    <div className="text-left">
                        <div className="row">
                            <div className="col-sm-6 ml-auto mr-auto text-center">
                                <h4 className="title">Insert Institution History</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">From Year/ To Year: </p>
                            </div>
                            <div className="col-sm-2 ml-auto ">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    value={this.state.fromYear}
                                    id="fromYear"
                                    type="Number"
                                    placeholder="ex. 2015"
                                    required
                                />

                            </div>
                            <div className="col-sm-2 mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    value={this.state.toYear}
                                    type="Number"
                                    id="toYear"
                                    placeholder="ex. 2019"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">From Month/ To Month: </p>
                            </div>
                            <div className="col-sm-2 ml-auto">
                                <select
                                    onChange={this.onChange}
                                    className="form-control"
                                    value={this.state.fromMonth}
                                    id="fromMonth"
                                    //type="Number"
                                    //placeholder="ex. 2015"
                                >
                                    <option >...</option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August</option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December</option>

                                </select>

                            </div>
                            <div className="col-sm-2 mr-auto">
                                <select
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.state.toMonth}
                                    id="toMonth"
                                    //type="Number"
                                    //placeholder="ex. 2015"
                                >
                                    <option >...</option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August</option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December</option>

                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">From Day/ To Day: </p>
                            </div>
                            <div className="col-sm-2 ml-auto ">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.state.fromDay}
                                    id="fromDay"
                                    type="Number"
                                    //placeholder="ex. 2015"
                                    //required
                                />

                            </div>
                            <div className="col-sm-2 mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.state.toDay}
                                    type="Number"
                                    id="toDay"
                                    //placeholder="ex. 2019"
                                    //required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Summary: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                            <textarea
                                onChange={this.onChange}
                                className="form-control"
                                value={this.state.summary}
                                id="summary"
                                required
                            />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Reference: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                            <textarea
                                onChange={this.onChange}
                                className="form-control"
                                value={this.state.reference}
                                id="reference"
                                required
                            />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Access Classification: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <select
                                    id="access_classification"
                                    className="form-control"
                                    onChange={this.onChange}
                                    value={this.state.access_classification}
                                >
                                    <option selected>Select One...</option>
                                    <option >Public</option>
                                    <option >Confidential</option>
                                    <option >Strictly-Confidential</option>
                                    <option >Not-Accessible</option>
                                </select>
                            </div>
                        </div>
                        {StatusOrNot}
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Information Type: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto text-center">
                                <ButtonGroup>
                                    <Button color="success" onClick={() =>
                                        this.setState({
                                            positive : true,
                                            negative : false
                                        })} active={this.state.positive === true}>Positive</Button>
                                    <Button color="danger" onClick={() => {
                                        this.setState({
                                            negative : true,
                                            positive : false
                                        })
                                    }} active={this.state.negative === true}>Negative</Button>
                                </ButtonGroup>
                            </div>
                        </div>
                        <div className="row">
                            <ImageUploader
                                singleImage={true}
                                withPreview={true}
                                withIcon={true}
                                buttonText='Upload Evidence Document'
                                onChange={this.onDropEvidence}
                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                maxFileSize={5242880}
                            />
                        </div>
                    </div>
                    <button type="submit" className="site-btn text-info">Insert Institution Record
                    </button>
                </form>
                <Modal open={this.state.open} onClose={this.onCloseModal}>
                    <h2>Message</h2>
                    <p>
                        {this.state.errors}
                    </p>
                </Modal>
            </React.Fragment>

        )
    }
}
export default InsertInstitutionHistory;