import React, {Component} from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {registerIndividual,registerOrganization} from "../../actions/authActions";

import MainNavBar from "../../components/Navbars/MainNavBar";
import ImageUploader from 'react-images-upload';

import Alert from 'react-s-alert';
import ShowAlert from "../../utils/ShowAlert";


class SignUpPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            individualFirstName: "",
            individualLastName: "",
            individualFirstInKhmer: "",
            individualLastInKhmer: "",
            individualEmail: "",
            individualPassword: "",
            individualPassword2: "",
            individualIdentificationNumber: "",
            individualPassport: [],
            individualPhoto: [],
            individualOrganization: "",
            individualPosition: "",
            individualAddress: "",
            organizationName: "",
            organizationNameKhmer: "",
            organizationEmail: "",
            organizationPassword: "",
            organizationPassword2: "",
            organizationRegisteredNumber: "",
            organizationTIN: "",
            organizationRepresentative: "",
            organizationRePosition: "",
            organizationAddress: ""
        };


    }

    componentDidMount() {
        // If logged in and user navigates to Register page, should redirect them to dashboard
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/welcome");
        }
    }

    alertError = () => {
        if (this.state.errors.email) {
            Alert.error(this.state.errors.email, {
                position: 'bottom-right',
                effect: 'jelly'
            });
        }
        if (this.state.errors.photo) {
            Alert.error(this.state.errors.photo, {
                position: 'bottom-right',
                effect: 'jelly'
            });
        }
        if (this.state.errors.passport) {
            Alert.error(this.state.errors.passport, {
                position: 'bottom-right',
                effect: 'jelly'
            });
        }
    };
    //
    onChange = e => {
        e.preventDefault();
        this.setState({[e.target.id]: e.target.value});

    };
    // onFileChange (e) {
    //     this.setState({ individualPassport: e.target.files[0] })
    // };
    onIndividualSubmit = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('myFile', this.state.individualPassport[0]);
        formData.append('myFile', this.state.individualPhoto[0]);
        formData.append('name', this.state.individualFirstName + this.state.individualLastName);
        formData.append('name_in_khmer', this.state.individualFirstInKhmer + this.state.individualLastInKhmer);
        formData.append('identification_number', this.state.individualIdentificationNumber);
        formData.append('password', this.state.individualPassword);
        formData.append('password2', this.state.individualPassword2);
        formData.append('email', this.state.individualEmail);
        formData.append('organization', this.state.individualOrganization);
        formData.append('position', this.state.individualPosition);
        formData.append('current_address', this.state.individualAddress);
        // const config = {
        //     headers: {
        //         'content-type': 'multipart/form-data'
        //     }
        // };

        // const newIndividual = {
        //     name: this.state.individualFirstName + this.state.individualLastName,
        //     name_in_khmer: this.state.individualFirstInKhmer + this.state.individualLastInKhmer,
        //     identification_number: this.state.individualIdentificationNumber,
        //     password: this.state.individualPassword,
        //     passport: this.state.individualPassport,
        //     password2: this.state.individualPassword2,
        //     email: this.state.email,
        //     organization: this.state.individualOrganization,
        //     position: this.state.individualPosition,
        //     current_address: this.state.individualAddress
        // };
        // console.log(newIndividual);
        //console.log(formData.entries());
        this.props.registerIndividual(formData,this.props.history);
        //this.alertError();


    };

    onDropPassport = file => {
        this.setState({
            individualPassport: this.state.individualPassport.concat(file)
        });
    };

    onDropPhoto = file => {
        this.setState({
            individualPhoto: this.state.individualPhoto.concat(file)
        });
    };

    onOrganizationSubmit = e => {
        e.preventDefault();

        if(this.state.organizationPassword !==this.state.organizationPassword2){
            Alert.error("Password isn't matched!", {
                position: 'bottom-right',
                effect: 'jelly'
            });
            return;
        }
        const newOrganization = {
            name_in_khmer : this.state.organizationNameKhmer,
            register_number : this.state.organizationRegisteredNumber,
            tax_identification_number : this.state.organizationTIN,
            representative : this.state.organizationRepresentative,
            position : this.state.organizationRePosition,
            current_address : this.state.organizationAddress,
            name : this.state.organizationName,
            email : this.state.organizationEmail,
            password : this.state.organizationPassword
        };
        this.props.registerOrganization(newOrganization,this.props.history);

    };



    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            ShowAlert(nextProps.errors);
        }
    }

    render() {
        const {errors} = this.state;
        // let passport, photo;
        // if (this.state.individualPassport) {
        //     passport = this.state.individualPassport.name;
        // } else {
        //     passport = "";
        // }
        // if (this.state.individualPhoto) {
        //     photo = this.state.individualPhoto.name;
        // } else {
        //     photo = "";
        // }
        return (
            <React.Fragment>
                <Alert stack={{limit: 3}}/>
                <MainNavBar SignIn={true}/>
                <div className="section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 ml-auto mr-auto text-center">
                                <h3 className="title">Register for...?</h3>
                                <div className="card">
                                        <div className="card-header">
                                            <ul className="nav nav-tabs justify-content-center" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" data-toggle="tab" href="#individual"
                                                       role="tab">
                                                        <i className="now-ui-icons users_single-02"></i>
                                                        Individual
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" data-toggle="tab" href="#institution"
                                                       role="tab">
                                                        <i className="now-ui-icons business_bank"></i>
                                                        Organization
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="card-body">

                                            <div className="tab-content text-center">
                                                <div className="tab-pane active " id="individual" role="tabpanel">
                                                    <form className="form "  onSubmit={this.onIndividualSubmit}
                                                          encType="multipart/form-data" method="POST"
                                                    >
                                                        <div className="text-left">
                                                            <div className="form-row">
                                                                <div className="form-group col-md-6">
                                                                    <label htmlFor="inputEmail4">First Name</label>
                                                                    <input
                                                                        required={true}
                                                                        onChange={this.onChange}
                                                                        value={this.state.individualFirstName}
                                                                        error={errors.name}
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="individualFirstName"
                                                                    />
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label htmlFor="individualLastName">Last Name</label>
                                                                    <input
                                                                        required={true}
                                                                        onChange={this.onChange}
                                                                        value={this.state.individualLastName}
                                                                        error={errors.name}
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="individualLastName"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-row">
                                                                <div className="form-group col-md-6">
                                                                    <label htmlFor="individualFirstInKhmer">Family
                                                                        Name(Khmer)</label>
                                                                    <input
                                                                        required={true}
                                                                        onChange={this.onChange}
                                                                        value={this.state.individualFirstInKhmer}
                                                                        error={errors.name_in_khmer}
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="individualFirstInKhmer"
                                                                    />
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label htmlFor="individualLastInKhmer">Sure
                                                                        Name(Khmer)</label>
                                                                    <input
                                                                        required={true}
                                                                        onChange={this.onChange}
                                                                        value={this.state.individualLastInKhmer}
                                                                        error={errors.name_in_khmer}
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="individualLastInKhmer"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="inputEmail">Email</label>
                                                                <input
                                                                    required={true}
                                                                    onChange={this.onChange}
                                                                    value={this.state.individualEmail}
                                                                    error={errors.email}
                                                                    type="email"
                                                                    className="form-control"
                                                                    id="individualEmail"
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="inputAddress">Password</label>
                                                                <input
                                                                    required={true}
                                                                    onChange={this.onChange}
                                                                    value={this.state.individualPassword}
                                                                    error={errors.password}
                                                                    type="password"
                                                                    className="form-control"
                                                                    id="individualPassword"
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="inputAddress">Confirm Password</label>
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.individualPassword2}
                                                                    error={errors.password2}
                                                                    type="password"
                                                                    className="form-control"
                                                                    id="individualPassword2"
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="inputAddress">Identification Number</label>
                                                                <input
                                                                    required={true}
                                                                    onChange={this.onChange}
                                                                    value={this.state.individualIdentificationNumber}
                                                                    error={errors.identification_number}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="individualIdentificationNumber"
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                {/*<div className="btn btn-info">Upload Passport</div>*/}
                                                                {/*<Input*/}
                                                                {/*    type="file"*/}
                                                                {/*    onChange={this.onChange}*/}
                                                                {/*    filename={this.state.individualPassport}*/}
                                                                {/*    error={errors.photo}*/}
                                                                {/*    name="individualPassport"*/}
                                                                {/*    className="form-control-file"*/}
                                                                {/*    id="individualPassport"*/}

                                                                {/*/>*/}
                                                                {/*<i> {passport}</i>*/}
                                                                <ImageUploader
                                                                    singleImage={true}
                                                                    withPreview={true}
                                                                    withIcon={true}
                                                                    buttonText='Choose Passport/ID'
                                                                    onChange={this.onDropPassport}
                                                                    imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                                                    maxFileSize={5242880}
                                                                />

                                                            </div>
                                                            <div className="form-group">
                                                                {/*<div className="btn btn-info">Upload Photo</div>*/}
                                                                {/*<Input*/}
                                                                {/*    type="file"*/}
                                                                {/*    onChange={this.onChange}*/}
                                                                {/*    filename={this.state.individualPhoto}*/}
                                                                {/*    error={errors.passport}*/}
                                                                {/*    name="individualPhoto"*/}
                                                                {/*    className="form-control-file"*/}
                                                                {/*    id="individualPhoto"*/}

                                                                {/*/>*/}
                                                                <ImageUploader
                                                                    singleImage={true}
                                                                    withPreview={true}
                                                                    withIcon={true}
                                                                    buttonText='Choose Photo'
                                                                    onChange={this.onDropPhoto}
                                                                    imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                                                    maxFileSize={5242880}
                                                                />
                                                                {/*<i> {photo}</i>*/}

                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="inputAddress">Organization</label>
                                                                <input
                                                                    required={true}
                                                                    onChange={this.onChange}
                                                                    value={this.state.individualOrganization}
                                                                    error={errors.individualOrganization}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="individualOrganization"
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="inputAddress">Position</label>
                                                                <input
                                                                    required={true}
                                                                    onChange={this.onChange}
                                                                    value={this.state.individualPosition}
                                                                    error={errors.individualPosition}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="individualPosition"
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="inputAddress">Current Address</label>
                                                                <input
                                                                    required={true}
                                                                    onChange={this.onChange}
                                                                    value={this.state.individualAddress}
                                                                    error={errors.individualAddress}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="individualAddress"
                                                                />
                                                            </div>
                                                        </div>

                                                        <button type="submit" className="site-btn text-success">Sign Up
                                                        </button>
                                                    </form>

                                                </div>
                                                <div className="tab-pane" id="institution" role="tabpanel">
                                                    <form className="form " onSubmit={this.onOrganizationSubmit}>
                                                        <div className="text-left">
                                                            <div className="form-group">
                                                                <label htmlFor="inputAddress">Organization Name</label>
                                                                <input
                                                                    required={true}
                                                                    onChange={this.onChange}
                                                                    value={this.state.organizationName}
                                                                    error={errors.organizationName}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="organizationName"
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="inputAddress">Organization Name in
                                                                    Khmer</label>
                                                                <input
                                                                    required={true}
                                                                    onChange={this.onChange}
                                                                    value={this.state.organizationNameKhmer}
                                                                    error={errors.organizationNameKhmer}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="organizationNameKhmer"
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="inputEmail">Register Email</label>
                                                                <input
                                                                    required={true}
                                                                    onChange={this.onChange}
                                                                    value={this.state.organizationEmail}
                                                                    error={errors.organizationEmail}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="organizationEmail"
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="inputPassword">Password</label>
                                                                <input
                                                                    required={true}
                                                                    onChange={this.onChange}
                                                                    value={this.state.organizationPassword}
                                                                    error={errors.organizationPassword}
                                                                    type="password"
                                                                    className="form-control"
                                                                    id="organizationPassword"
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="inputAddress">Confirm Password</label>
                                                                <input
                                                                    required={true}
                                                                    onChange={this.onChange}
                                                                    value={this.state.organizationPassword2}
                                                                    error={errors.organizationPassword2}
                                                                    type="password"
                                                                    className="form-control"
                                                                    id="organizationPassword2"
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="inputAddress">Registered Number</label>
                                                                <input
                                                                    required={true}
                                                                    onChange={this.onChange}
                                                                    value={this.state.organizationRegisteredNumber}
                                                                    error={errors.organizationRegisteredNumber}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="organizationRegisteredNumber"
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="inputAddress">Tax Identification
                                                                    Number</label>
                                                                <input
                                                                    required={true}
                                                                    onChange={this.onChange}
                                                                    value={this.state.organizationTIN}
                                                                    error={errors.organizationTIN}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="organizationTIN"
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="inputAddress">Representative</label>
                                                                <input
                                                                    required={true}
                                                                    onChange={this.onChange}
                                                                    value={this.state.organizationRepresentative}
                                                                    error={errors.organizationRepresentative}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="organizationRepresentative"
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="inputAddress">Position</label>
                                                                <input
                                                                    required={true}
                                                                    onChange={this.onChange}
                                                                    value={this.state.organizationRePosition}
                                                                    error={errors.organizationRePosition}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="organizationRePosition"
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="inputAddress">Current Address</label>
                                                                <input
                                                                    required={true}
                                                                    onChange={this.onChange}
                                                                    value={this.state.organizationAddress}
                                                                    error={errors.organizationAddress}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="organizationAddress"
                                                                />
                                                            </div>
                                                        </div>

                                                        <button type="submit" className="site-btn text-success">Sign Up
                                                        </button>
                                                    </form>

                                                </div>
                                            </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

SignUpPage.propTypes = {
    registerIndividual: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    {registerIndividual,registerOrganization}
)(withRouter(SignUpPage));