import React from "react";
import {FaTrashRestoreAlt, MdDeleteForever} from "react-icons/all";

let href = "deletehistory";
let restorehref = "restorehistory";



const OrganizationColumns = [
    {
        Header: 'Action',
        columns: [
            {
                Header: 'Delete',
                accessor: '',
                Cell: props => (

                    <div>
                        {props.value.status === "Deleted" ?
                            <a href="#"
                               data-toggle="modal"
                               data-target={`#${restorehref}`}
                               className="text-success"
                            > <FaTrashRestoreAlt/>Restore</a> :
                            <a href="#"
                               data-toggle="modal"
                               data-target={`#${href}`}
                               className="text-danger"
                            > <MdDeleteForever/>Delete</a>}

                    </div>
                )
            }, {
                Header: 'View',
                accessor: 'profile',
                Cell: props => (
                    <div>
                        <a href={"/institution/" + props.value._id + "/" + new Date()}> View Profile </a>
                    </div>
                ),
            }]
    },
    {
        Header: 'From Year',
        accessor: 'fromYear'
    },
    {
        Header: 'To Year',
        accessor: 'toYear'
    }
    ,
    {
        Header: 'Access Classification',
        accessor: 'access_classification',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Public" ? '#3498db' : row.value === "Confidential" ? '#f39c12' :
                    row.value === "Strictly-Confidential" ? '#8e44ad' : '#c0392b', transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    },
    {
        Header: 'Information Type',
        accessor: 'info_type',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === true ? '#2ecc71'
                    : row.value === false ? '#ffbf00'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value === true ? 'Positive'
                    : row.value === false ? `Negative`
                    : 'Invalid'
            }
          </span>
        )
    }
    // ,
    // {
    //     Header: 'Status',
    //     accessor: 'status',
    //     Cell: row => (
    //         <span>
    //         <span style={{
    //             color: row.value === "Published" ? '#2ecc71'
    //                 : row.value === "In Review" ? '#ffbf00'
    //                     : '#e74c3c',
    //             transition: 'all .3s ease'
    //         }}>
    //           &#x25cf;
    //         </span> {
    //             row.value
    //         }
    //       </span>
    //     )
    // }
];
const IndividualColumns = [
    {
        Header: 'Action',
        columns: [
            {
                Header: 'Delete',
                accessor: '',
                Cell: props => (

                    <div>
                        {props.value.status === "Deleted" ?
                            <a href="#"
                               data-toggle="modal"
                               data-target={`#${restorehref}`}
                               className="text-success"
                            > <FaTrashRestoreAlt/>Restore</a> :
                            <a href="#"
                               data-toggle="modal"
                               data-target={`#${href}`}
                               className="text-danger"
                            > <MdDeleteForever/>Delete</a>}

                    </div>
                )
            }, {
                Header: 'View',
                accessor: 'profile',
                Cell: props => (
                    <div>
                        <a href={"/individualEmployee/" + props.value._id + "/" + new Date()}> View Profile </a>
                    </div>
                ),
            }]
    },
    {
        Header: 'From Year',
        accessor: 'fromYear'
    },
    {
        Header: 'To Year',
        accessor: 'toYear'
    }
    ,
    {
        Header: 'Organization',
        accessor: 'organization'
    },
    {
        Header: 'Position',
        accessor: 'position'
    },
    {
        Header: 'Access Classification',
        accessor: 'access_classification',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Public" ? '#3498db' : row.value === "Confidential" ? '#f39c12' :
                    row.value === "Strictly-Confidential" ? '#8e44ad' : '#c0392b', transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    },
    {
        Header: 'Information Type',
        accessor: 'info_type',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === true ? '#2ecc71'
                    : row.value === false ? '#ffbf00'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value === true ? 'Positive'
                    : row.value === false ? `Negative`
                    : 'Invalid'
            }
          </span>
        )
    }
    // ,
    // {
    //     Header: 'Status',
    //     accessor: 'status',
    //     Cell: row => (
    //         <span>
    //         <span style={{
    //             color: row.value === "Published" ? '#2ecc71'
    //                 : row.value === "In Review" ? '#ffbf00'
    //                     : '#e74c3c',
    //             transition: 'all .3s ease'
    //         }}>
    //           &#x25cf;
    //         </span> {
    //             row.value
    //         }
    //       </span>
    //     )
    // }
];
const OrganizationColumnsNoDelete = [
    {
        Header: 'View',
        accessor: 'profile',
        Cell: props => (
            <div>
                <a href={"/institution/" + props.value._id + "/" + new Date()}> View Profile </a>
            </div>
        ),
    },
    {
        Header: 'From Year',
        accessor: 'fromYear'
    },
    {
        Header: 'To Year',
        accessor: 'toYear'
    }
    ,
    {
        Header: 'Access Classification',
        accessor: 'access_classification',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Public" ? '#3498db' : row.value === "Confidential" ? '#f39c12' :
                    row.value === "Strictly-Confidential" ? '#8e44ad' : '#c0392b', transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    },
    {
        Header: 'Information Type',
        accessor: 'info_type',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === true ? '#2ecc71'
                    : row.value === false ? '#ffbf00'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value === true ? 'Positive'
                    : row.value === false ? `Negative`
                    : 'Invalid'
            }
          </span>
        )
    }
    // ,
    // {
    //     Header: 'Status',
    //     accessor: 'status',
    //     Cell: row => (
    //         <span>
    //         <span style={{
    //             color: row.value === "Published" ? '#2ecc71'
    //                 : row.value === "In Review" ? '#ffbf00'
    //                     : '#e74c3c',
    //             transition: 'all .3s ease'
    //         }}>
    //           &#x25cf;
    //         </span> {
    //             row.value
    //         }
    //       </span>
    //     )
    // }
];
const IndividualColumnsNoDelete = [
    {
        Header: 'View',
        accessor: 'profile',
        Cell: props => (
            <div>
                <a href={"/individualEmployee/" + props.value._id + "/" + new Date()}> View Profile </a>
            </div>
        ),
    },
    {
        Header: 'From Year',
        accessor: 'fromYear'
    },
    {
        Header: 'To Year',
        accessor: 'toYear'
    }
    ,
    {
        Header: 'Organization',
        accessor: 'organization'
    },
    {
        Header: 'Position',
        accessor: 'position'
    },
    {
        Header: 'Access Classification',
        accessor: 'access_classification',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === "Public" ? '#3498db' : row.value === "Confidential" ? '#f39c12' :
                    row.value === "Strictly-Confidential" ? '#8e44ad' : '#c0392b', transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value
            }
          </span>
        )
    },
    {
        Header: 'Information Type',
        accessor: 'info_type',
        Cell: row => (
            <span>
            <span style={{
                color: row.value === true ? '#2ecc71'
                    : row.value === false ? '#ffbf00'
                        : '#57d500',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                row.value === true ? 'Positive'
                    : row.value === false ? `Negative`
                    : 'Invalid'
            }
          </span>
        )
    }
    // ,
    // {
    //     Header: 'Status',
    //     accessor: 'status',
    //     Cell: row => (
    //         <span>
    //         <span style={{
    //             color: row.value === "Published" ? '#2ecc71'
    //                 : row.value === "In Review" ? '#ffbf00'
    //                     : '#e74c3c',
    //             transition: 'all .3s ease'
    //         }}>
    //           &#x25cf;
    //         </span> {
    //             row.value
    //         }
    //       </span>
    //     )
    // }
];

export {
    OrganizationColumns,
    IndividualColumns,
    OrganizationColumnsNoDelete,
    IndividualColumnsNoDelete
}