import React, {Component} from "react";
import MainNavBar from "../../../components/Navbars/MainNavBar";
import PasswordMask from 'react-password-mask';
import axios from 'axios';
import Alert from 'react-s-alert';


class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password:'',
            password2:'',
            token: this.props.match.params.id
        }
    }
    onChange = e => {
        e.preventDefault();
        this.setState({[e.target.id]: e.target.value});
    };
    onSubmit = e =>{
        e.preventDefault();
        if(this.state.password !== this.state.password2){
            Alert.error("Passwords aren't matched", {
                position: 'bottom-left',
                effect: 'slide'
            });
            return;
        }
        const data = {
            password: this.state.password
        };
        axios.post('/api-users/changePasswordToken/' + this.props.match.params.id,data).then(res =>{
            Alert.error(res.data, {
                position: 'bottom-left',
                effect: 'slide'
            });
            if(res.status === 200){
                this.props.history.push('/sign-in');
            }
        })
    };
    componentDidMount() {
        axios.get('/api-users/verifyResetToken/' + this.props.match.params.id).then( res =>{

        }).catch(err =>{
            this.props.history.push('/');
        })
    }

    render() {
        return (
            <React.Fragment>
                <Alert stack={{limit: 3}}/>
                <MainNavBar/>
                <div className="section">
                    <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-justify ">
                        <h1 className="display-4">Change Your Password</h1>
                        <p className="lead">Type your new password and make sure they are matched!</p>
                    </div>
                    <div className="col-md-6 ml-auto mr-auto">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={this.onSubmit}>
                                    <div className="row">
                                        <div className="col-sm-3 ">
                                            <p className="font-weight-bold">New Password </p>
                                        </div>
                                        <div className="col-sm-6 ml-auto mr-auto">
                                            <PasswordMask
                                                // className = "form-control"
                                                id="password"
                                                name="password"
                                                value={this.state.password}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3 ">
                                            <p className="font-weight-bold">Confirm Password</p>
                                        </div>
                                        <div className="col-sm-6 ml-auto mr-auto">
                                            <PasswordMask
                                                // className = "form-control"
                                                id="password2"
                                                name="password2"
                                                value={this.state.password2}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button className="btn btn-round btn-outline-success">Reset Password</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default ChangePassword;