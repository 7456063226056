import React, {Component} from "react";
import MainNavBar from "../../../components/Navbars/MainNavBar";
import axios from 'axios';
import './style.css';

import Alert from 'react-s-alert';
import ShowAlert from "../../../utils/ShowAlert";

const validEmailRegex =
    RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        // if we have an error string set valid to false
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
};

class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: null,
            token: null,
            errors: {
                email: true,
                token: true
            },
            disabled: false
        }
    }

    handleChange = (event) => {
        event.preventDefault();
        const {name, value} = event.target;
        let errors = this.state.errors;

        switch (name) {
            case 'email':
                errors.email =
                    validEmailRegex.test(value)
                        ? ''
                        : 'Email is not valid!';
                break;
            case 'token':
                errors.password =
                    value.length < 4
                        ? 'Token is invalid'
                        : '';
                break;
            default:
                break;
        }
        this.setState({errors, [name]: value});
    };

    onSend = e => {
        e.preventDefault();
        console.log(this.state);
        if (validateForm(this.state.errors)) {
            this.setState({
                disabled: true
            });

            const userData = {
                email: this.state.email
            };
            axios.post('/api-users/forgot', userData).then(res => {

                console.log(res.data);
            }).catch(err => {
                this.setState({
                    errors: {
                        email: true
                    },
                    disabled : false
                });
                ShowAlert(err.response.data);
            })
        } else {
            console.error('Invalid Form');
        }

    };
    onProceed = e => {
        e.preventDefault();
        axios.get('/api-users/verifyResetToken/'+this.state.token).then( res =>{
            this.props.history.push(`/change-password/${res.data.token}`);
        }).catch(err =>{
            for (let i in err.response.data) {
                Alert.error(err.response.data[i], {
                    position: 'bottom-left',
                    effect: 'slide'
                });
            }
        })
    };

    render() {
        const {errors} = this.state;
        let tokenPanel =
            <div className="collapse" id="collapseExample">
                <div className="card card-body">
                    <form onSubmit={this.onProceed}>

                        <div className="form-group text-center">
                            <label htmlFor="exampleInputEmail1">Enter a token we sent to you.</label>
                            <input
                                type="text"
                                onChange={this.handleChange}
                                className="form-control"
                                name="token"
                                aria-describedby="emailHelp"
                                placeholder="Enter token"
                            />
                            <button type="submit" className="btn btn-outline-success">Proceed</button>
                        </div>
                    </form>
                </div>
            </div>;
        return (
            <React.Fragment>

                <Alert stack={{limit: 3}}/>
                <MainNavBar SignUp={true}/>
                <div className="section">
                    <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-justify ">
                        <h1 className="display-4">Forgot Your Password?</h1>
                        <p className="lead">Enter your email address below and we'll send token verification to you.</p>
                        <form onSubmit={this.onSend}>
                            <fieldset disabled={this.state.disabled}>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Email address</label>
                                    <input
                                        type="email"
                                        onChange={this.handleChange}
                                        value={this.state.email}
                                        className="form-control"
                                        name="email"
                                        aria-describedby="emailHelp"
                                        placeholder="Enter email"
                                        required
                                    />
                                    {errors.email ? <div className="text-danger" role="alert">
                                        {errors.email}
                                    </div> : <span/>}
                                    <small id="emailHelp" className="form-text text-muted">We'll never share your email
                                        with
                                        anyone else.</small>
                                </div>
                                <div className="text-center">
                                    <button
                                        type="button submit"
                                        className="btn btn-outline-info"
                                        data-toggle="collapse"
                                        data-target="#collapseExample"
                                        aria-expanded="false"
                                        aria-controls="collapseExample"
                                        disabled={this.state.disabled}

                                    >Submit
                                    </button>

                                </div>
                            </fieldset>
                        </form>
                        {errors.email ? <span/> : tokenPanel}
                    </div>

                </div>
            </React.Fragment>
        )
    }
}

export default ForgotPassword;