import React, {Component} from "react";
import Alert from "react-s-alert";
import axios from 'axios';
import ReactTable from 'react-table';

import {IndividualColumns,OrganizationColumns,OrganizationColumnsNoDelete,
    IndividualColumnsNoDelete} from './Utilities';
import {Badge} from "reactstrap";
import {
    AiOutlineLoading3Quarters,
    AiOutlineUsergroupDelete,
    AiOutlineWarning,
    AiTwotoneDelete, FaBuilding,
    FaFileAlt, FaFileArchive, FaSearch
} from "react-icons/all";
import MiniIndividualHistory from "../MergeData/MiniIndividualHistory";
import MiniInstitutionHistory from "../MergeData/MiniInstitutionHistory";

class HistoriesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentHistory : ''
        };

    }
    onDeleteHistory = e => {
        e.preventDefault();
        axios.post('/api-counts/delete_history/' + this.state.currentHistory._id + '/' + this.props.user).then(res => {
            window.location.reload();
        }).catch(err => {
            console.log(err.response);
        })
    };
    onRestoreHistory = e => {
        e.preventDefault();
        axios.post('/api-counts/restore_history/' + this.state.currentHistory._id + '/' + this.props.user).then(res => {
            window.location.reload();
        }).catch(err => {
            console.log(err.response);
        })
    };


    render() {
        let DeleteConfirm =
            <div
                className="modal fade "
                id="deletehistory"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"

            >

                <div className="modal-dialog " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirm Delete History</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h3 className="font-weight-bold">Are you sure that you wish to delete this history?</h3>

                            Your action cannot be reverted!
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="site-btn text-black" data-dismiss="modal">Close</button>
                            <button type="button" className="site-btn text-danger" data-dismiss="modal"
                                    onClick={this.onDeleteHistory}>Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>;
        let RestoreConfirm = <div
            className="modal fade"
            id='restorehistory'
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true">

            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Restore Deleted History</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h3 className="font-weight-bold">Are you sure that you wish to restore this history?</h3>

                        Your action cannot be reverted!
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="site-btn text-black" data-dismiss="modal">Close</button>
                        <button type="button" className="site-btn text-success" data-dismiss="modal"
                                onClick={this.onRestoreHistory}>Restore
                        </button>
                    </div>
                </div>
            </div>
        </div>;

        return (
            <div>
                <Alert stack={{limit: 3}}/>
                {DeleteConfirm}
                {RestoreConfirm}
                <div className="section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 ml-auto mr-auto text-center">
                                <div className="historyListTitle">
                                    <h2><FaSearch size="3rem"/> Inklusivity : Reference Check</h2>
                                </div>
                                <h3 className="title"><FaFileArchive/> {this.props.title}</h3>
                                <div className="card" >
                                    <div className="card-header">
                                        <ul className="nav nav-tabs justify-content-center" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#published"
                                                   role="tab">
                                                    <FaFileAlt/><br/>
                                                    Published<br/>
                                                    <Badge
                                                        color="success">
                                                        {
                                                            this.props.PublishedIndividualHistories.length +
                                                            this.props.PublishedInstitutionHistories.length}
                                                    </Badge>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#inreview"
                                                   role="tab">
                                                    <AiOutlineLoading3Quarters/><br/>
                                                    In Review<br/>
                                                    <Badge
                                                        color="info">
                                                        {this.props.InReviewIndividualHistories.length +
                                                        this.props.InReviewInstitutionHistories.length
                                                        }
                                                    </Badge>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#rejected"
                                                   role="tab">
                                                    <AiOutlineWarning/><br/>
                                                    Rejected<br/>
                                                    <Badge
                                                        color="warning">
                                                        {this.props.RejectedIndividualHistories.length +
                                                        this.props.RejectedInstitutionHistories.length
                                                        }
                                                    </Badge>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#deleted"
                                                   role="tab">
                                                    <AiTwotoneDelete/><br/>
                                                    Deleted<br/>
                                                    <Badge
                                                        color="danger">
                                                        {this.props.DeletedIndividualHistories.length +
                                                            this.props.DeletedInstitutionHistories.length
                                                        }</Badge>
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content text-center">
                                            <div className="tab-pane active " id="published" role="tabpanel">
                                                <AiOutlineUsergroupDelete size="2rem"/>
                                                <h3>Individual Histories</h3>
                                                <ReactTable
                                                    data={this.props.PublishedIndividualHistories}
                                                    columns={this.props.deletePermission?IndividualColumns:IndividualColumnsNoDelete}
                                                    showPagination={true}
                                                    filterable={true}
                                                    defaultPageSize={5}
                                                    getTrProps={(state, rowInfo, column, instance) => {
                                                        return {
                                                            onClick: (e) => {
                                                                this.setState({
                                                                    currentHistory : rowInfo.original
                                                                });
                                                            }
                                                        }
                                                    }}
                                                    SubComponent = {row =>{
                                                        return(
                                                            <MiniIndividualHistory
                                                                history={row.original}
                                                                user={this.props.user}
                                                                permission = {this.props.permission_classification}
                                                            />
                                                        )
                                                    }}
                                                />

                                                <br/>
                                                <FaBuilding size="2rem"/>
                                                <h3 >Institution Histories</h3>
                                                <ReactTable
                                                    data={this.props.PublishedInstitutionHistories}
                                                    columns={this.props.deletePermission?OrganizationColumns:OrganizationColumnsNoDelete}
                                                    showPagination={true}
                                                    filterable={true}
                                                    defaultPageSize={5}
                                                    getTrProps={(state, rowInfo, column, instance) => {
                                                        return {
                                                            onClick: (e) => {
                                                                this.setState({
                                                                    currentHistory : rowInfo.original
                                                                });
                                                            }
                                                        }
                                                    }}
                                                    SubComponent = {row =>{
                                                        return(
                                                            <MiniInstitutionHistory
                                                                history={row.original}
                                                                user={this.props.user}
                                                                permission = {this.props.permission_classification}
                                                            />
                                                        )
                                                    }}
                                                />
                                            </div>
                                            <div className="tab-pane " id="inreview" role="tabpanel">
                                                <AiOutlineUsergroupDelete size="2rem"/>
                                                <h3>Individual Histories</h3>
                                                <ReactTable
                                                    data={this.props.InReviewIndividualHistories}
                                                    columns={this.props.deletePermission?IndividualColumns:IndividualColumnsNoDelete}
                                                    showPagination={true}
                                                    filterable={true}
                                                    defaultPageSize={5}
                                                    getTrProps={(state, rowInfo, column, instance) => {
                                                        return {
                                                            onClick: (e) => {
                                                                this.setState({
                                                                    currentHistory : rowInfo.original
                                                                });
                                                            }
                                                        }
                                                    }}
                                                    SubComponent = {row =>{
                                                        return(
                                                            <MiniIndividualHistory
                                                                history={row.original}
                                                                user={this.props.user}
                                                                permission = {this.props.permission_classification}
                                                            />
                                                        )
                                                    }}
                                                />

                                                <br/>
                                                <FaBuilding size="2rem"/>
                                                <h3 >Institution Histories</h3>
                                                <ReactTable
                                                    data={this.props.InReviewInstitutionHistories}
                                                    columns={this.props.deletePermission?OrganizationColumns:OrganizationColumnsNoDelete}
                                                    showPagination={true}
                                                    filterable={true}
                                                    defaultPageSize={5}
                                                    getTrProps={(state, rowInfo, column, instance) => {
                                                        return {
                                                            onClick: (e) => {
                                                                this.setState({
                                                                    currentHistory : rowInfo.original
                                                                });
                                                            }
                                                        }
                                                    }}
                                                    SubComponent = {row =>{
                                                        return(
                                                            <MiniInstitutionHistory
                                                                history={row.original}
                                                                user={this.props.user}
                                                                permission = {this.props.permission_classification}
                                                            />
                                                        )
                                                    }}
                                                />
                                            </div>
                                            <div className="tab-pane " id="rejected" role="tabpanel">
                                                <AiOutlineUsergroupDelete size="2rem"/>
                                                <h3>Individual Histories</h3>
                                                <ReactTable
                                                    data={this.props.RejectedIndividualHistories}
                                                    columns={this.props.deletePermission?IndividualColumns:IndividualColumnsNoDelete}
                                                    showPagination={true}
                                                    filterable={true}
                                                    defaultPageSize={5}
                                                    getTrProps={(state, rowInfo, column, instance) => {
                                                        return {
                                                            onClick: (e) => {
                                                                this.setState({
                                                                    currentHistory : rowInfo.original
                                                                });
                                                            }
                                                        }
                                                    }}
                                                    SubComponent = {row =>{
                                                        return(
                                                            <MiniIndividualHistory
                                                                history={row.original}
                                                                user={this.props.user}
                                                                permission = {this.props.permission_classification}
                                                            />
                                                        )
                                                    }}
                                                />

                                                <br/>
                                                <FaBuilding size="2rem"/>
                                                <h3 >Institution Histories</h3>
                                                <ReactTable
                                                    data={this.props.RejectedInstitutionHistories}
                                                    columns={this.props.deletePermission?OrganizationColumns:OrganizationColumnsNoDelete}
                                                    showPagination={true}
                                                    filterable={true}
                                                    defaultPageSize={5}
                                                    getTrProps={(state, rowInfo, column, instance) => {
                                                        return {
                                                            onClick: (e) => {
                                                                this.setState({
                                                                    currentHistory : rowInfo.original
                                                                });
                                                            }
                                                        }
                                                    }}
                                                    SubComponent = {row =>{
                                                        return(
                                                            <MiniInstitutionHistory
                                                                history={row.original}
                                                                user={this.props.user}
                                                                permission = {this.props.permission_classification}
                                                            />
                                                        )
                                                    }}
                                                />
                                            </div>
                                            <div className="tab-pane " id="deleted" role="tabpanel">
                                                <AiOutlineUsergroupDelete size="2rem"/>
                                                <h3>Individual Histories</h3>
                                                <ReactTable
                                                    data={this.props.DeletedIndividualHistories}
                                                    columns={this.props.deletePermission?IndividualColumns:IndividualColumnsNoDelete}
                                                    showPagination={true}
                                                    filterable={true}
                                                    defaultPageSize={5}
                                                    getTrProps={(state, rowInfo, column, instance) => {
                                                        return {
                                                            onClick: (e) => {
                                                                this.setState({
                                                                    currentHistory : rowInfo.original
                                                                });
                                                            }
                                                        }
                                                    }}
                                                    SubComponent = {row =>{
                                                        return(
                                                            <MiniIndividualHistory
                                                                history={row.original}
                                                                user={this.props.user}
                                                                permission = {this.props.permission_classification}
                                                            />
                                                        )
                                                    }}
                                                />

                                                <br/>
                                                <FaBuilding size="2rem"/>
                                                <h3 >Institution Histories</h3>
                                                <ReactTable
                                                    data={this.props.DeletedInstitutionHistories}
                                                    columns={this.props.deletePermission?OrganizationColumns:OrganizationColumnsNoDelete}
                                                    showPagination={true}
                                                    filterable={true}
                                                    defaultPageSize={5}
                                                    getTrProps={(state, rowInfo, column, instance) => {
                                                        return {
                                                            onClick: (e) => {
                                                                this.setState({
                                                                    currentHistory : rowInfo.original
                                                                });
                                                            }
                                                        }
                                                    }}
                                                    SubComponent = {row =>{
                                                        return(
                                                            <MiniInstitutionHistory
                                                                history={row.original}
                                                                user={this.props.user}
                                                                permission = {this.props.permission_classification}
                                                            />
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

}

export default HistoriesList;