import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import NotFound from "../../../../components/404/404";
import axios from "axios";
import {trackPromise} from "react-promise-tracker";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import RecordsList from "./RecordsList";
import MainNavBar from "../../../../components/Navbars/MainNavBar";

class RecordsListValidator extends Component{
    constructor(props) {
        super(props);
        this.state = {
            PublishedIndividualProfile : [],
            PublishedInstitutionProfile : [],
            DeletedIndividualProfile : [],
            DeletedInstitutionProfile : [],
            loading:true,
            authorized : false
        }
    }
    componentDidMount() {
        trackPromise(
            axios.all([
                axios.get('/api-users/getUserPermissions/' + this.props.auth.user.id),
                axios.get('/api-counts/allProfileList')
            ]).then(axios.spread((res1,res2) =>{
                this.setState({
                    authorized : res1.data.permissions_list.ViewData.includes("Profile"),
                    PublishedIndividualProfile : res2.data.PublishedIndividualProfile,
                    PublishedInstitutionProfile : res2.data.PublishedInstitutionProfile,
                    DeletedIndividualProfile : res2.data.DeletedIndividualProfile,
                    DeletedInstitutionProfile : res2.data.DeletedInstitutionProfile,
                    loading: false
                })
            }))
        ).catch( () =>{
            this.setState({
                authorized : false,
                loading: false
            })
        })

    }


    render() {
        return(
            <div>
                <MainNavBar SignIn={false} SignUp={false} dashboard={true} socialMedia={false}/>
                {this.state.loading? <LoadingScreen/> : this.state.authorized ?
                    <RecordsList
                        user={this.props.auth.user.id}
                        PublishedIndividualProfile = {this.state.PublishedIndividualProfile}
                        PublishedInstitutionProfile = {this.state.PublishedInstitutionProfile}
                        DeletedIndividualProfile = {this.state.DeletedIndividualProfile}
                        DeletedInstitutionProfile = {this.state.DeletedInstitutionProfile}
                    />
                    :
                    <NotFound/>}
            </div>

        )
    }
}
RecordsListValidator.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(RecordsListValidator));