import React, {Component} from "react";
import SearchField from "react-search-field";
import axios from "axios";
import {MdCallMerge, MdPageview,FaSearch} from "react-icons/all";
import ReactTable from "react-table";

class MergeWithExistingInstitutionProfile extends Component {
    constructor() {
        super();
        this.state = {
            searchDone: false,
            searchKey: '',
            institutionData: []
        }
    }

    onChange = (value, event) => {
        this.setState({
            searchKey: value
        })
    };

    onSearch = (value, event) => {
        axios.get('/api-institutionProfile/search/' + value+'/' +this.props.id ).then( response =>{
            //console.log(response);
            this.setState({
                institutionData : response.data.docs,
                searchDone: true
            })
        }).catch(err =>{
            console.log(err);
        });

    };

    render() {
        //console.log(this.props.history);
        const InstitutionColumns = [
            {
                Header: 'Action',
                columns: [
                    {
                        Header: "View Profile",
                        accessor: '',
                        Cell: props => (
                            <div>
                                <a href={"/institution/" + props.value._id + "/" + new Date()}
                                   target="_blank"><MdPageview/> View Profile </a>
                            </div>
                        )
                    },
                    {
                        Header: "Merge",
                        accessor: '',
                        Cell: props => (
                            <div>
                                <button
                                    className="btn-info"
                                    onClick={() => {
                                        //console.log(currentIndividual);
                                        axios.post('/api-institutionProfile/mergeProfileWithHistory/' + props.value._id + '/' + this.props.history._id)
                                            .then(res => {
                                                window.location.href = '/institution/' + props.value._id + '/' + new Date();
                                            }).catch(err => {
                                            console.log(err);
                                        })
                                    }}
                                >
                                    <MdCallMerge/> Merge
                                </button>

                            </div>
                        )
                    }
                ],

            },
            {
                Header: 'Registered Number',
                accessor: 'register_number'
            }
            ,
            {
                Header: 'Name',
                accessor: 'name',
                Cell: row => (
                    <div style={{color: '#1abc9c'}}> {row.value}</div>
                )
            },
            {
                Header: 'Tax Identification Number',
                accessor: 'tax_identification_number'
            }
        ];
        return (
            <div>
                <div>
                    <h3 className="font-weight-light">Search Institution Profile To Merge</h3>
                    <br/>
                    <SearchField
                        placeholder="Type anything..."
                        onChange={this.onChange}
                        onEnter={this.onSearch}
                        onSearchClick={this.onSearch}
                        classNames="test-class"

                    />
                    <p
                        className="font-italic"
                        style={{padding: 30, fontSize: 14}}
                    >Type anything related to institution profile such as name, contact, sector...</p>
                </div>
                {this.state.searchDone?<div>
                    <h4 className="font-weight-light text-success"><FaSearch/> Search Result</h4>
                    <br/>
                    <ReactTable
                        columns={InstitutionColumns}
                        data={this.state.institutionData}
                        defaultPageSize={5}
                        filterable
                    />
                </div>:<span/>}

            </div>
        );
    }
}

export default MergeWithExistingInstitutionProfile;