import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import InsertRecords from "./InsertRecords";
import NotFound from "../../../../components/404/404";
import axios from "axios";
import {trackPromise} from "react-promise-tracker";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import MainNavBar from "../../../../components/Navbars/MainNavBar";

class InsertRecordsValidator extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            authorized : false
        }
    }
    componentDidMount() {
        trackPromise(
            axios.get('/api-users/getUserPermissions/' + this.props.auth.user.id).then( res =>{
                this.setState({
                    authorized : res.data.permissions_list.InsertData.includes("Profile"),
                    loading: false
                })
            }).catch( () =>{
                this.setState({
                    authorized : false,
                    loading: false
                })
            })
        );

    }
    render() {
        return(
            <div>
                <MainNavBar SignIn ={false} SignUp={false} dashboard = {true} socialMedia={false}/>
                {this.state.loading? <LoadingScreen/> : this.state.authorized ? <InsertRecords id={this.props.auth.user.id}/> : <NotFound/>}
            </div>

        )
    }
}

InsertRecordsValidator.propTypes = {
    auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(
    mapStateToProps,
    {}
)(withRouter(InsertRecordsValidator));