import React, {Component} from "react";
import ReactTable from 'react-table';
import Moment from "react-moment";
import {Badge} from "reactstrap";
import {MdPageview} from "react-icons/md";
import {
    AiOutlineUsergroupDelete, AiTwotoneDelete,
    FaBuilding,
    FaFileAlt,
    FaFileCode, FaSearch
} from "react-icons/all";

class RecordsList extends Component {


    render() {
        const Organizationcolumns = [
            {
                Header: 'Action',
                accessor: '',
                Cell: props => (
                    <div>
                        <a href={"/institution/" + props.value._id + "/" + new Date()}
                           target="_blank"
                        ><MdPageview/> View Profile </a>
                    </div>
                )
            },
            {
                Header: 'Registered Number',
                accessor: 'register_number'
            }

            ,
            {
                Header: 'Name',
                accessor: 'name'
            },
            {
                Header: 'Name In Khmer',
                accessor: 'name_in_khmer'
            },
            {
                Header: 'Institution Type',
                accessor: 'institution_type'
            },
            {
                Header: 'Representative',
                accessor: 'representative'
            },
            {
                Header: 'Status',
                accessor: 'deleted',
                Cell: props => (
                    <span>
            <span style={{
                color: props.value === true ? '#e74c3c'
                    : props.value === false ? '#57d500'
                        : '#2c3e50',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                        props.value === true ? 'Deleted'
                            : props.value === false ? `Published`
                            : 'Invalid'
                    }
          </span>
                )
            },
            {
                Header: 'Date Created',
                accessor: 'dateCreated',
                Cell: row => (
                    <Moment className={row.value.deleted ? "deleted" : "text-info"} format="DD/MM/YYYY">{row.value}</Moment>
                )

            }
        ];
        const Individualcolumns = [
            {
                Header: 'Action',
                accessor: '_id',
                Cell: props => (
                    <div>
                        <a
                            href={"/individualEmployee/" + props.value + "/" + new Date()}
                            target="_blank"
                        ><MdPageview/> View Profile </a>
                    </div>
                )
            },
            {
                Header: 'ID',
                accessor: 'identification_number'
            }

            ,
            {
                Header: 'Name',
                accessor: 'name'
            },
            {
                Header: 'Name In Khmer',
                accessor: 'name_in_khmer'
            },
            {
                Header: 'Date of Birth',
                accessor: 'date_of_birth',
                Cell: row => (
                    <Moment format="DD/MM/YYYY">{row.value}</Moment>
                )
            },
            {
                Header: 'Nationality',
                accessor: 'nationality'
            },
            {
                Header: 'Status',
                accessor: 'deleted',
                Cell: props => (
                    <span>
            <span style={{
                color: props.value === true ? '#e74c3c'
                    : props.value === false ? '#57d500'
                        : '#2c3e50',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                        props.value === true ? 'Deleted'
                            : props.value === false ? `Published`
                            : 'Invalid'
                    }
          </span>
                )
            },
            {
                Header: 'Date Created',
                accessor: 'dateCreated',
                Cell: row => (
                    <Moment className={row.value.deleted ? "deleted " : "text-info"} format="DD/MM/YYYY">{row.value}</Moment>
                )

            }
        ];
        return (
            <React.Fragment>
                <div className="section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 ml-auto mr-auto text-center">
                                <div className="historyListTitle">
                                     <h2><FaSearch size="3rem"/> Inklusivity : Reference Check</h2>
                                </div>
                                <h3 className="title"><FaFileCode/> Profiles List</h3>
                                <div className="card" >
                                    <div className="card-header">
                                        <ul className="nav nav-tabs justify-content-center" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#published"
                                                   role="tab">
                                                    <FaFileAlt/><br/>
                                                    Published<br/>
                                                    <Badge
                                                        color="success">
                                                        {
                                                            this.props.PublishedIndividualProfile.length +
                                                            this.props.PublishedInstitutionProfile.length}
                                                    </Badge>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#deleted"
                                                   role="tab">
                                                    <AiTwotoneDelete/><br/>
                                                    Deleted<br/>
                                                    <Badge
                                                        color="danger">
                                                        {this.props.DeletedIndividualProfile.length +
                                                        this.props.DeletedInstitutionProfile.length
                                                        }</Badge>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content text-center">
                                            <div className="tab-pane active " id="published" role="tabpanel">
                                                <AiOutlineUsergroupDelete size="2rem"/>
                                                <h3>Individual Profiles</h3>
                                                <ReactTable
                                                    data={this.props.PublishedIndividualProfile}
                                                    columns={Individualcolumns}
                                                    defaultPageSize={5}
                                                    showPagination={true}
                                                    filterable={true}
                                                />
                                                <br/>
                                                <FaBuilding size="2rem"/>
                                                <h3 >Institution Profiles</h3>
                                                <ReactTable
                                                    //manual
                                                    data={this.props.PublishedInstitutionProfile}
                                                    columns={Organizationcolumns}
                                                    //pages={organizationTotalPages}
                                                    defaultPageSize={5}
                                                    //loading={loadingOrganization}
                                                    //onFetchData={this.fetchOrganization} // Request new data when things change
                                                    showPagination={true}
                                                    filterable={true}
                                                    className="-striped -highlight"
                                                />
                                            </div>
                                            <div className="tab-pane " id="deleted" role="tabpanel">
                                                <AiOutlineUsergroupDelete size="2rem"/>
                                                <h3>Individual Profiles</h3>
                                                <ReactTable
                                                    //manual
                                                    data={this.props.DeletedIndividualProfile}
                                                    //pages={individualTotalPages}
                                                    columns={Individualcolumns}
                                                    defaultPageSize={5}
                                                    showPagination={true}
                                                    filterable={true}
                                                    //loading={loadingIndividual}
                                                    //onFetchData={this.fetchIndividualData} // Request new data when things change
                                                    className="-striped -highlight"
                                                />
                                                <br/>
                                                <FaBuilding size="2rem"/>
                                                <h3 >Institution Profiles</h3>
                                                <ReactTable
                                                    //manual
                                                    data={this.props.DeletedInstitutionProfile}
                                                    columns={Organizationcolumns}
                                                    //pages={organizationTotalPages}
                                                    defaultPageSize={5}
                                                    //loading={loadingOrganization}
                                                    //onFetchData={this.fetchOrganization} // Request new data when things change
                                                    showPagination={true}
                                                    filterable={true}
                                                    className="-striped -highlight"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default RecordsList;