import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Alert from "react-s-alert";
import axios from 'axios';
import {trackPromise} from 'react-promise-tracker';
import {Badge, Breadcrumb, BreadcrumbItem, Col} from "reactstrap";
import PropTypes from "prop-types";
import {
    FaColumns,
    FaDatabase,
    FaFileCode,
    FaFileImport,
    FaQuestionCircle,
    FaRegFileArchive, FaSearch,
    FaUserCog,
    FaUserFriends, FiFileText,
    GoSearch,
    IoIosAddCircleOutline,
    IoIosEye,
    MdBrokenImage
} from "react-icons/all";

import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {FilePicker} from 'react-file-picker'
import {Modal} from 'react-bootstrap';
import NewRecordsPopUp from "../Admin/RecordsList/NewRecordsPopUp";
import HistoriesPopUp from "../Admin/HistoriesList/HistoriesPopUp";
import './adminPageStyle.css';


const iconSize = "1.5rem";

class AdminDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSummery: {},
            loading: true,
            permissions_list: {
                InsertData: [],
                ViewData: [],
                EditData: [],
                DeleteData: [],
                MergeData: []
            },
            permission_classification: '',
            alreadyExistIndividualProfile: [],
            newIndividualProfile: [],
            alreadyExistInstitutionProfile: [],
            newInstitutionProfile: [],
            showSuccessImportProfileModal: false,
            showFailedImportProfileModal: false,
            successfullyMergedIndividualProfiles: [],
            successfullyMergedInstitutionProfiles: [],
            conflictedIndividualHistories: [],
            conflictedInstitutionHistories: [],
            notFoundIndividualHistories: [],
            notFoundInstitutionHistories: [],
            showSuccessImportHistoriesModal: false,
            showFailedImportHistoriesModal: false,
            errorsDate: ''
        };
    }

    toggleSuccessProfileImport = e => {
        this.setState({
            showSuccessImportProfileModal: !this.state.showSuccessImportProfileModal
        });
        window.location.reload();
    };
    toggleFailedProfileImport = e => {
        this.setState({
            showFailedImportProfileModal: !this.state.showFailedImportProfileModal
        })

    };

    toggleSuccessHistoriesImport = e => {
        this.setState({
            showSuccessImportHistoriesModal: !this.state.showSuccessImportHistoriesModal
        });
        window.location.reload();
    };

    toggleFailedHistoriesImport = e => {
        this.setState({
            showFailedImportHistoriesModal: !this.state.showFailedImportHistoriesModal
        })

    };

    componentDidMount() {
        trackPromise(
            axios.all([
                axios.get('/api-counts/getDataSummary'),
                axios.get('/api-users/getUserPermissions/' + this.props.auth.user.id)
            ]).then(axios.spread((res1, res2) => {
                this.setState({
                    dataSummery: res1.data,
                    permissions_list: res2.data.permissions_list,
                    permission_classification: res2.data.permission_classification,
                    loading: false
                })
            }))
        ).catch(error => console.log(error.response))

    }



    onProfilesUpload = object => {
        const upload = new FormData();
        upload.append("myFile", object);
        upload.append("data_contributor", this.props.auth.user.id);
        axios.post('/api-counts/importProfiles', upload).then(result => {
            this.setState({
                alreadyExistIndividualProfile: result.data.alreadyExistIndividualProfile,
                newIndividualProfile: result.data.newIndividualProfile,
                alreadyExistInstitutionProfile: result.data.alreadyExistInstitutionProfile,
                newInstitutionProfile: result.data.newInstitutionProfile,
                showSuccessImportProfileModal: true
            })

        }).catch(err => {
            this.setState({
                showFailedImportProfileModal: !this.state.showFailedImportProfileModal,
                errorsDate: err.response.data.err
            })
        })

    };
    onProfilesUploadError = errMsg => {
        this.setState({
            showFailedImportProfileModal: !this.state.showFailedImportProfileModal,
            errorsDate: errMsg
        })

    };
    onHistoriesUpload = object => {
        const upload = new FormData();
        upload.append("myFile", object);
        upload.append("data_contributor", this.props.auth.user.id);
        axios.post('/api-counts/importHistories', upload).then(result => {
            this.setState({
                successfullyMergedIndividualProfiles: result.data.successfullyMergedIndividualProfiles,
                successfullyMergedInstitutionProfiles: result.data.successfullyMergedInstitutionProfiles,
                conflictedIndividualHistories: result.data.conflictedIndividualHistories,
                conflictedInstitutionHistories: result.data.conflictedInstitutionHistories,
                notFoundIndividualHistories: result.data.notFoundIndividualHistories,
                notFoundInstitutionHistories: result.data.notFoundInstitutionHistories,
                showSuccessImportHistoriesModal: true
            });
        }).catch(err => {
            this.setState({
                showFailedImportProfileModal: !this.state.showFailedImportProfileModal,
                errorsDate: err.response.data.err
            })
        })
    };
    onHistoriesUploadError = e => {

    };
    handleClose = e =>{
        this.setState({
            showSearchedHistories : !this.state.showSearchedHistories
        })
    };

    render() {
        const importSucess =
            <Modal
                show={this.state.showSuccessImportProfileModal}
                onHide={this.toggleSuccessProfileImport}
                size="xl"
            >
                <Modal.Header closeButton>
                    <h3 className="font-weight-bold text-info"><FaFileImport/>Import Succeed!</h3>
                </Modal.Header>
                <Modal.Body>
                    <NewRecordsPopUp
                        alreadyExistIndividualProfile={this.state.alreadyExistIndividualProfile}
                        newIndividualProfile={this.state.newIndividualProfile}
                        alreadyExistInstitutionProfile={this.state.alreadyExistInstitutionProfile}
                        newInstitutionProfile={this.state.newInstitutionProfile}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button className="site-btn text-success text-center"
                            onClick={() => {
                                window.location.href = '/recordsList';
                            }}>Go to Records List
                    </button>
                </Modal.Footer>
            </Modal>;



        const importHistoriesSucess =
            <Modal
                show={this.state.showSuccessImportHistoriesModal}
                onHide={this.toggleSuccessHistoriesImport}
                size="xl"
            >
                <Modal.Header closeButton>
                    <h3 className="font-weight-bold text-info"><FaFileImport/>Import Succeed!</h3>
                </Modal.Header>
                <Modal.Body>
                    <HistoriesPopUp
                        successfullyMergedIndividualProfiles={this.state.successfullyMergedIndividualProfiles}
                        successfullyMergedInstitutionProfiles={this.state.successfullyMergedInstitutionProfiles}
                        conflictedIndividualHistories={this.state.conflictedIndividualHistories}
                        conflictedInstitutionHistories={this.state.conflictedInstitutionHistories}
                        notFoundIndividualHistories={this.state.notFoundIndividualHistories}
                        notFoundInstitutionHistories={this.state.notFoundInstitutionHistories}
                        user={this.props.auth.user.id}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button className="site-btn text-success text-center"
                            onClick={() => {
                                window.location.href = '/mergeData';
                            }}>Go to Merge
                    </button>
                </Modal.Footer>
            </Modal>;
        const failImportProfile =
            <Modal
                show={this.state.showFailedImportProfileModal}
                onHide={this.toggleFailedProfileImport}
                size="l"
                centered
            >
                <Modal.Header closeButton>
                    <h3 className="font-weight-bold text-danger"><MdBrokenImage/>Import Failed!</h3>
                </Modal.Header>
                <Modal.Body>
                    Either your file is corrupted or in incorrect format.<br/>
                    {this.state.errorsDate}
                </Modal.Body>
                <Modal.Footer>
                    <button className="site-btn text-success text-center"
                            onClick={this.toggleFailedProfileImport}>Okay
                    </button>
                </Modal.Footer>

            </Modal>;
        //console.log(this.state.permissions_list);
        const {email} = this.props.auth.user;
        let noPermission = <div className="text-center">
            <h6 className="font-weight-normal text-danger">You have no permission to perform any action on this
                function.</h6>
        </div>;
        let MergeData;
        let AdminList;
        let Miscellaneous;
        let Records;
        let Users;
        let Histories;
        let SearchActivities;
        //console.log(this.props.auth.user);
        const displayMergeOrNot = this.state.permissions_list.MergeData;
        const viewMembersOrNot = this.state.permissions_list.ViewData.includes("Member");
        const viewAdminsOrNot = this.state.permission_classification === "System Admin";
        //const addAdminsOrNot = this.props.auth.user.permission === "System Admin";
        const addMembersOrNot = this.state.permissions_list.InsertData.includes("Member");
        const addMiscellaneousOrNot = this.state.permissions_list.InsertData.includes("Miscellaneous");
        const viewProfileOrNot = this.state.permissions_list.ViewData.includes("Profile");
        const addProfileOrNot = this.state.permissions_list.InsertData.includes("Profile");
        const viewHistoriesOrNot = this.state.permissions_list.ViewData.includes("Histories");
        const addHistoriesOrNot = this.state.permissions_list.InsertData.includes("Histories");
        const viewMemberActivitiesOrNot = this.state.permissions_list.ViewData.includes("MembersActivities");
        const importFileOrNot = this.state.permissions_list.ViewData.includes("ImportFile");
        const viewSearchOrNot = this.state.permissions_list.ViewData.includes("Search");
        MergeData = <Col className="ml-auto mr-auto" md="3">
            <div className="card" width="20rem">
                <div className="card-body">
                    <div className="text-center">
                        <FaQuestionCircle size={"3em"}/>
                        <h4 className="card-title">Undecided Histories</h4>
                        {displayMergeOrNot ?
                            <h4
                                className="card-subtitle mb-2 text-muted text-success"
                            >{this.state.dataSummery.notFound
                            + this.state.dataSummery.conflicted
                                // + this.state.dataSummery.deletedUndecided
                            }</h4>
                            : <span/>
                        }
                    </div>
                    {displayMergeOrNot ? <div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic">Conflicted Histories{this.state.dataSummery.conflicted > 0 ?
                                    <Badge color="info">New!</Badge> : <span/>}</h6>

                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold ">{this.state.dataSummery.conflicted}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic">Profile Not Found {this.state.dataSummery.notFound > 0 ?
                                    <Badge color='warning'>New!</Badge> : <span/>}</h6>

                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold ">{this.state.dataSummery.notFound}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic text-danger">Deleted Undecided Histories</h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right text-danger">
                                <h6 className="font-weight-bold ">{this.state.dataSummery.deletedUndecided}</h6>
                            </div>
                        </div>


                        <a
                            href="/mergeData"
                            className="btn-success nav-link text-center">
                            <IoIosAddCircleOutline size={iconSize}/> Merge Data</a>
                    </div> : noPermission}


                </div>
            </div>
        </Col>;
        AdminList = <Col className="ml-auto mr-auto" md="3">
            <div className="card " width="20rem">
                <div className="card-body">
                    <div className="text-center">
                        {/*{viewSearchOrNot ? <SearchField*/}
                        {/*    placeholder="Type anything..."*/}
                        {/*    onEnter={this.onSearchHistory}*/}
                        {/*    onSearchClick={this.onSearchHistory}*/}
                        {/*    classNames="searchBox"*/}
                        {/*/> : <span/>}*/}
                        <FaUserCog size={"3em"}/>
                        <h4 className="card-title">Total Admins</h4>
                        {viewAdminsOrNot ?
                            <h4
                                className="card-subtitle mb-2 text-muted text-success">{
                                this.state.dataSummery.admins
                                + this.state.dataSummery.dataEntry
                                + this.state.dataSummery.ordinary
                                + this.state.dataSummery.special
                                // + this.state.dataSummery.deactivatedAdmin
                            }</h4>
                            : <span/>}

                    </div>
                    {!viewAdminsOrNot ? noPermission : <span/>}
                    {viewAdminsOrNot ? <div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic">Data Entry </h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold ">{this.state.dataSummery.dataEntry}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic">Ordinary Member</h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold ">{this.state.dataSummery.ordinary}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic">Special Member</h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold ">{this.state.dataSummery.special}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic">System Admin</h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold ">{this.state.dataSummery.admins}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic text-danger">Deactivated Member</h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold text-danger">{this.state.dataSummery.deactivatedAdmin}</h6>
                            </div>
                        </div>

                        <a
                            href="/adminsList"
                            className="btn-info nav-link text-center">
                            <IoIosEye size={iconSize}/> View All Admins</a>
                    </div> : <span/>}
                    <br/>
                    {viewAdminsOrNot ? <a
                        href="/insertUsers"
                        className="btn-success nav-link text-center">
                        <IoIosAddCircleOutline size={iconSize}/>Insert Admin</a> : <span/>}
                </div>
            </div>
        </Col>;
        Miscellaneous = <Col className="ml-auto mr-auto " md="3">
            <div className="card " width="20rem">
                <div className="card-body">
                    <div className="text-center">
                        <FaDatabase size={"3em"}/>
                        <h4 className="card-title">Miscellaneous</h4>
                    </div>
                    {addMiscellaneousOrNot ? <div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic">Institution Type</h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold ">{this.state.dataSummery.institutionTypeCount}</h6>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic">Institution Sector</h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold ">{this.state.dataSummery.institutionSectorCount}</h6>
                            </div>
                        </div>
                        <a
                            href="/miscellaneous"
                            className="btn-success nav-link text-center">
                            <IoIosAddCircleOutline size={iconSize}/> Insert Data</a>
                    </div> : noPermission}
                </div>
            </div>
        </Col>;
        Records = <Col className="ml-auto mr-auto" md="3">
            <div className="card" width="20rem">
                <div className="card-body">
                    <div className="text-center">
                        {/*{viewSearchOrNot ? <SearchField*/}
                        {/*    placeholder="Type anything..."*/}
                        {/*    onEnter={this.onSearchHistory}*/}
                        {/*    onSearchClick={this.onSearchHistory}*/}
                        {/*    classNames="searchBox"*/}
                        {/*/> : <span/>}*/}
                        <FaFileCode size={"3em"}/>
                        <h4 className="card-title">Total Records</h4>
                        {viewProfileOrNot ?
                            <h4
                                className="card-subtitle mb-2 text-muted text-success">
                                {this.state.dataSummery.individualProfile
                                + this.state.dataSummery.instituteProfile
                                }</h4> : <span/>}

                    </div>
                    {!(viewProfileOrNot || addProfileOrNot) ? noPermission : <span/>}
                    {viewProfileOrNot ? <div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic">Individual Records</h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold ">{this.state.dataSummery.individualProfile}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic">Institution Records</h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold ">{this.state.dataSummery.instituteProfile}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic text-danger">Deleted Records</h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold text-danger">{this.state.dataSummery.deletedProfile}</h6>
                            </div>
                        </div>


                        <a
                            href="/recordsList"
                            className="btn-info nav-link text-center">
                            <IoIosEye size={iconSize}/> View All Records</a>
                    </div> : <span/>}
                    <br/>
                    {addProfileOrNot ? <a
                        href="/insertRecords"
                        className="btn-success nav-link  text-center">
                        <IoIosAddCircleOutline size={iconSize}/> Insert Record</a> : <span/>}
                    <br/>
                    {importFileOrNot ?
                        <FilePicker
                            extensions={['xlsx']}
                            onChange={this.onProfilesUpload}
                            onError={this.onProfilesUploadError}
                        >
                            <a
                                href="#"
                                className="btn-dark  nav-link  text-center">
                                <FaFileImport size={iconSize}/> Import Profiles</a>
                        </FilePicker> : <span/>}
                </div>
            </div>
        </Col>;
        Users = <Col className="ml-auto mr-auto" md="3">
            <div className="card" width="20rem">
                <div className="card-body">
                    <div className="text-center">
                        {/*{viewSearchOrNot ? <SearchField*/}
                        {/*    placeholder="Type anything..."*/}
                        {/*    onEnter={this.onSearchHistory}*/}
                        {/*    onSearchClick={this.onSearchHistory}*/}
                        {/*    classNames="searchBox"*/}
                        {/*/> : <span/>}*/}
                        <FaUserFriends size={"3em"}/>
                        <h4 className="card-title">Total Users</h4>
                        {viewMembersOrNot ? <h4
                            className="card-subtitle mb-2 text-muted text-success">
                            {this.state.dataSummery.individual + this.state.dataSummery.organization + this.state.dataSummery.deactivatedUser}
                        </h4> : <span/>}
                    </div>
                    {!(viewMembersOrNot || addMembersOrNot) ? noPermission : <span/>}
                    {viewMembersOrNot ? <div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic">Individual Users</h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold ">{this.state.dataSummery.individual}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic">Organization Users</h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold ">{this.state.dataSummery.organization}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic text-danger">Deactivated Users</h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold text-danger">{this.state.dataSummery.deactivatedUser}</h6>
                            </div>
                        </div>
                        <a
                            href="/usersList"
                            className="btn-info nav-link text-center">
                            <IoIosEye size={iconSize}/> View All Users</a>
                        <br/>
                    </div> : <span/>}
                    {addMembersOrNot ? <a
                        href="/insertUsers"
                        className="btn-success nav-link  text-center">
                        <IoIosAddCircleOutline size={iconSize}/> Insert Users</a> : <span/>}
                </div>
            </div>
        </Col>;
        Histories = <Col className="ml-auto mr-auto" md="3">

            <div className="card" width="20rem">
                <div className="card-body">
                    <div className="text-center">
                        {/*{viewSearchOrNot ? <SearchField*/}
                        {/*    placeholder="Type anything..."*/}
                        {/*    onEnter={this.onSearchHistory}*/}
                        {/*    onSearchClick={this.onSearchHistory}*/}
                        {/*    classNames="searchBox"*/}
                        {/*/> : <span/>}*/}
                        <FaRegFileArchive size={"3em"}/>
                        <h4 className="card-title">Total Histories</h4>
                        {viewHistoriesOrNot ? <h4
                            className="card-subtitle mb-2 text-muted text-success"
                        >{this.state.dataSummery.individualHistories
                        + this.state.dataSummery.institutionHistories
                        }
                        </h4> : <span/>}

                    </div>
                    {!(viewHistoriesOrNot || addHistoriesOrNot) ? noPermission : <span/>}
                    {viewHistoriesOrNot ? <div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic">Published</h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold">{this.state.dataSummery.publishedHistories}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic">in Review{this.state.dataSummery.inReviewHistories > 0 ?
                                    <Badge color='warning'>New!</Badge> : <span/>}</h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold">{this.state.dataSummery.inReviewHistories}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic text-warning">Rejected</h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold">{this.state.dataSummery.rejectedHistories}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic">Individual Histories</h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold">{this.state.dataSummery.individualHistories}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic">Institution Histories</h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold">{this.state.dataSummery.institutionHistories}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic text-danger">Deleted Histories</h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold text-danger">{this.state.dataSummery.deletedHistories}</h6>
                            </div>
                        </div>
                        <a
                            href="/historiesList"
                            className="btn-info nav-link text-center">
                            <IoIosEye size={iconSize}/> View All Histories</a>
                    </div> : <span/>}
                    <br/>
                    {addHistoriesOrNot ? <a
                        href="/insertHistories"
                        className="btn-success nav-link text-center">
                        <IoIosAddCircleOutline size={iconSize}/> Insert History</a> : <span/>}
                    <br/>
                    {importFileOrNot ?
                        <FilePicker
                            extensions={['xlsx']}
                            onChange={this.onHistoriesUpload}
                            onError={this.onProfilesUploadError}
                        >
                            <a
                                href="#"
                                className="btn-dark nav-link  text-center">
                                <FaFileImport size={iconSize}/> Import Histories</a>
                        </FilePicker> : <span/>}

                </div>
            </div>
        </Col>;

        SearchActivities = <Col className="ml-auto mr-auto" md="3">
            <div className="card" width="20rem">
                <div className="card-body">
                    <div className="text-center">
                        {viewMemberActivitiesOrNot ? <h1
                            className="card-subtitle mb-2 text-muted text-info font-weight-bold">
                            {this.state.dataSummery.failedSearch + this.state.dataSummery.successSearch}
                        </h1> : <span/>}
                        <h4 className="card-title "><sup><GoSearch size={"1em"}/></sup><FiFileText size={"2em"}/> Total Reference Check Count</h4>

                    </div>
                    {!(viewMemberActivitiesOrNot) ? noPermission : <span/>}
                    {viewMemberActivitiesOrNot ? <div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic">Successful Check</h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold ">{this.state.dataSummery.successSearch}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mr-auto ml-auto">
                                <h6 className="font-italic text-warning">Failed Check</h6>
                            </div>
                            <div className="col mr-auto ml-auto text-right">
                                <h6 className="font-weight-bold text-warning">{this.state.dataSummery.failedSearch}</h6>
                            </div>
                        </div>
                        <br/>
                    </div> : <span/>}
                </div>
            </div>
        </Col>;


        return (
            <React.Fragment>
                <Alert stack={{limit: 3}}/>
                {importSucess}
                {importHistoriesSucess}
                {failImportProfile}



                {this.state.loading ? <LoadingScreen/> : <div className="section">
                    <div className="container-fluid">
                        <div className="row">
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <div className="historyListTitle">
                                    <h2><FaSearch size="3rem"/>Inklusivity : Reference Check</h2>
                                </div>
                                <h3 className="historyListTitle">You're sign in as <br/><i>{email}</i></h3>
                            </Col>
                        </div>
                        <div className="row">
                            {SearchActivities}
                        </div>
                        <div className="row">
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h3 className="title"><FaColumns size="2rem"/> Data Summary</h3>
                            </Col>
                        </div>
                        <div className="row">
                            {Users}
                            {Records}
                            {Histories}
                        </div>
                        <div className="row">
                            {AdminList}
                            {MergeData}
                            {Miscellaneous}
                        </div>
                        {/*<div className="row">*/}
                        {/*    <Col className="ml-auto mr-auto text-center" md="8">*/}
                        {/*        <h3 className="title">System Report</h3>*/}
                        {/*    </Col>*/}
                        {/*</div>*/}


                        {/*<div className="row" >*/}
                        {/*    <Col className="ml-auto mr-auto text-center" md="2">*/}
                        {/*        <UserCard*/}
                        {/*            float*/}
                        {/*            href={'/user'}*/}
                        {/*            header='https://i.imgur.com/vRAtM3i.jpg'*/}
                        {/*            name={this.state.admin.name}*/}
                        {/*            positionName={this.state.admin.permission_classification}*/}
                        {/*        />*/}
                        {/*    </Col>*/}
                        {/*    <Col className="ml-auto mr-auto text-center" md="2">*/}
                        {/*        <UserCard*/}
                        {/*            float*/}
                        {/*            href='/insertUsers'*/}
                        {/*            header='https://cdn.pixabay.com/photo/2017/12/13/18/05/networks-3017398_960_720.jpg'*/}
                        {/*            name='Insert Users'*/}
                        {/*            positionName='Admin, Individual, Organization User'*/}
                        {/*        />*/}
                        {/*    </Col>*/}
                        {/*    <Col className="ml-auto mr-auto text-center" md="2">*/}
                        {/*        <UserCard*/}
                        {/*            float*/}
                        {/*            href='/insertRecords'*/}
                        {/*            header='https://cdn.pixabay.com/photo/2017/10/17/14/10/financial-2860753_960_720.jpg'*/}
                        {/*            name='Insert Records'*/}
                        {/*            positionName='Individual, Institute Records'*/}
                        {/*        />*/}
                        {/*    </Col>*/}
                        {/*    <Col className="ml-auto mr-auto text-center" md="2">*/}
                        {/*        <UserCard*/}
                        {/*            float*/}
                        {/*            href='/insertHistories'*/}
                        {/*            header='https://cdn.pixabay.com/photo/2015/02/03/23/41/paper-623167_960_720.jpg'*/}
                        {/*            name='Insert Histories'*/}
                        {/*            positionName='Individual Employment, Institution History'*/}
                        {/*        />*/}
                        {/*    </Col>*/}
                        {/*</div>*/}
                    </div>
                </div>}

            </React.Fragment>
        )
    }

}

AdminDashboard.propTypes = {
    auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps
)(withRouter(AdminDashboard));