import React, {Component} from "react";
import {trackPromise} from "react-promise-tracker";
import axios from "axios";
import InstitutionHistory from "./InstitutionHistory";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {Element, Events, scroller} from 'react-scroll'
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import EditOrganization from "../Admin/RecordsList/EditOrganization";
import InsertInstitutionHistory from "../Admin/InsertHistories/InsertInstitutionHistory";
import ShowAlert from "../../../utils/ShowAlert";

import {FaEdit, FaFileDownload, FaHistory, FaRegBuilding, FaSearch, FaUserAltSlash,FaTrashRestoreAlt} from "react-icons/fa";
import "./style.css";
import Moment from "react-moment";
import MainNavBar from "../../../components/Navbars/MainNavBar";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import NotFound from "../../../components/404/404";
import ReactToPrint from "react-to-print";
import {FaInfo, FaPhone, FaUsers} from "react-icons/all";

const iconSize = "1.0rem";

class InstitutionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            institution: {
                data_contributor: []
            },
            dataRevealPercentage :{},
            histories: [],
            loading: true,
            fraudLoading: false,
            permissions_list : {
                InsertData: [],
                ViewData: [],
                EditData: [],
                DeleteData: [],
                MergeData: false
            },
        }
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    componentDidMount() {
        Events.scrollEvent.register('begin', function () {
            console.log("begin", arguments);
        });

        Events.scrollEvent.register('end', function () {
            console.log("end", arguments);
        });
        trackPromise(
            axios.get('/api-users/verifyAuthenticity/'
                + this.props.match.params.id + '/'
                + this.props.auth.user.id + '/'
                + 'InstitutionProfile/' + this.props.match.params.date).then( () => {
                if (this.props.auth.user.type === "AdminUser") {
                    axios.get('/api-institutionProfile/history_list_system_admin/' +
                        this.props.match.params.id +
                        '/' + this.props.auth.user.id
                    ).then(res => {
                        this.setState({
                            histories: res.data.histories,
                            permissions_list : res.data.permissions_list
                        })
                    }).catch(err => {
                        ShowAlert(err);
                    })
                } else {
                    axios.get('/api-institutionProfile/history_list_public/'
                        + this.props.match.params.id + '/'
                        + this.props.auth.user.id + '/'
                        + this.props.match.params.date
                    ).then(res => {
                        this.setState({
                            histories: res.data
                        })
                    }).catch(err => {
                        ShowAlert(err);
                    })
                }
                trackPromise(
                    axios.get('/api-institutionProfile/' + this.props.match.params.id +'/' + this.props.auth.user.id)
                        .then(res => {
                            this.setState({
                                institution: res.data,
                                dataRevealPercentage : res.data.dataRevealPercentage,
                                loading : false
                            })
                        })
                ).catch(error => console.log(error));

            })
        ).catch(err => {
            this.setState({
                loading : false,
                fraudLoading : true
            })
        })


    }

    onDeleteProfile = e => {
        e.preventDefault();
        axios.delete('/api-counts/'
            + this.props.match.params.id +'/' + this.props.auth.user.id
        ).then( () => {
            window.location.reload();
        }).catch(err => {
            console.log(err.response);
        })
    };

    onRestoreProfile = e => {
        e.preventDefault();
        axios.post('/api-counts/restore/'
            + this.props.match.params.id +'/' + this.props.auth.user.id
        ).then( () => {
            window.location.reload();
        }).catch(err => {
            console.log(err.response);
        })
    };

    scrollTo() {
        scroller.scrollTo('scroll-to-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    render() {
        const histories = this.state.histories;
        let HistoryItem;
        if (histories.length <= 0) {
            HistoryItem = "No History Recorded";
        } else {
            const editStatusOrNot = this.state.permissions_list.EditData.includes("Histories");
            HistoryItem = histories.map((history, k) =>
                    <InstitutionHistory
                        history={history}
                        key={k}
                        status={editStatusOrNot}
                        permissions_list = {this.state.permissions_list}
                        user={this.props.auth.user.id}
                        userType = {this.props.auth.user.type}
                        permission={this.props.auth.user.permission}
                    />
                );
            // if (this.props.auth.user.type === "AdminUser") {
            //     HistoryItem = histories.map((history, k) =>
            //         <InstitutionHistory
            //             history={history}
            //             key={k}
            //             editable={true}
            //             user={this.props.auth.user.id}
            //             deletable={true}
            //         />
            //     );
            // } else {
            //     HistoryItem = histories.map((history, k) =>
            //         <InstitutionHistory history={history} key={k} editable={false}/>
            //     );
            // }
        }
        let EditorNot;
        let EditPanel;
        let DeleteorNot;
        let InsertHistory;
        let InsertPanel;
        let DeleteConfirm;
        let downloadButton;
        let additionalInfo;
        if (this.props.auth.user.type === "AdminUser") {
            if (this.props.auth.user.permission === "System Admin") {

                InsertPanel = <div className="collapse" id="insertHistory">
                    <div className="card text-center">
                        <div className="card-body">
                            <InsertInstitutionHistory
                                user={this.props.auth.user.id}
                                profile={this.props.match.params.id}
                                status={false}/>
                        </div>

                    </div>
                </div>
                additionalInfo = <div>
                    <h2><FaUsers/> Data Contributors</h2>
                    {
                        this.state.institution.data_contributor.map((data, k) =>
                            <>

                                <div className="row">
                                    <div className="col">
                                        <p className="font-weight-bold">Name: </p>
                                    </div>
                                    <div className="col">
                                        <a href={"/adminUser/" + data._id}><p>{data.name}</p></a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <p className="font-weight-bold">Email: </p>
                                    </div>
                                    <div className="col">
                                        <p>{data.email}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <p className="font-weight-bold">Permission: </p>
                                    </div>
                                    <div className="col">
                                        <p>{data.permission_classification}</p>
                                    </div>
                                </div>
                                <div className="separator-line"/>

                            </>
                        )}
                </div>;
            }
            if(this.state.permissions_list.EditData.includes("Profile")){
                EditorNot =
                    <li className="nav-item"><a href="#editProfile"
                                                className="nav-link btn-outline-warning editInfo border-dark button-nav"
                                                data-toggle="collapse"
                                                aria-expanded="false"
                                                aria-controls="editProfile"
                                                to="editInfo"
                                                onClick={() => {
                                                    this.scrollTo()
                                                }}

                    >
                        <FaEdit size={iconSize}/>
                        Edit
                    </a></li>;
                let formatReportOrNot = false;
                if(this.state.permissions_list.EditData.includes("FormattingReports")){
                    formatReportOrNot = true;
                }

                EditPanel = <div className="collapse" id="editProfile">
                    <div className="card ">
                        <div className="card-body">
                            <div className="tab-content text-center">
                                <EditOrganization
                                    user={this.state.institution}
                                    member={this.props.auth.user.id}
                                    dataRevealPercentage = {this.state.dataRevealPercentage}
                                    formatReportOrNot = {formatReportOrNot}
                                />
                            </div>

                        </div>

                    </div>
                </div>;
            }
            if(this.state.permissions_list.InsertData.includes("Histories")){
                InsertHistory = <li className="nav-item">
                    <a href="#insertHistory"
                       className="btn-outline-success nav-link border-dark button-nav"
                       data-toggle="collapse"
                       aria-expanded="false"
                       aria-controls="insertHistory">
                        <FaHistory size={iconSize}/>
                        Insert History</a></li>;
                InsertPanel = <div className="collapse" id="insertHistory">
                    <div className="card text-center">
                        <div className="card-body">
                            <InsertInstitutionHistory
                                user={this.props.auth.user.id}
                                profile={this.props.match.params.id}
                                status={false}
                            />
                        </div>

                    </div>
                </div>;
            }

            if(this.state.permissions_list.DeleteData.includes("Profile")){
                if(!this.state.institution.deleted){
                    DeleteorNot =<li className="nav-item"> <a
                        href="#"
                        data-toggle="modal"
                        data-target="#deleteprofile"
                        className="btn-outline-danger nav-link border-dark button-nav"
                    >
                        <FaUserAltSlash size={iconSize}/>
                        Delete</a></li>;
                    DeleteConfirm = <div className="modal fade" id="deleteprofile" tabIndex="-1" role="dialog"
                                         aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Confirm Delete Profile</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    Are you sure that you wish to delete this profile?
                                    Your action cannot be reverted!
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="site-btn text-black" data-dismiss="modal">Close</button>
                                    <button type="button" className="site-btn text-danger" data-dismiss="modal" onClick={this.onDeleteProfile}>Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>;
                }else{
                    DeleteorNot =<li className="nav-item"> <a
                        href="#"
                        data-toggle="modal"
                        data-target="#deleteprofile"
                        className="btn-outline-success nav-link border-dark button-nav"
                    >
                        <FaTrashRestoreAlt size={iconSize}/>
                        Restore</a></li>;
                    DeleteConfirm = <div className="modal fade" id="deleteprofile" tabIndex="-1" role="dialog"
                                         aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Confirm Restore Profile</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    Are you sure that you wish to restore this profile?
                                    Your action cannot be reverted!
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="site-btn text-black" data-dismiss="modal">Close</button>
                                    <button type="button" className="site-btn text-success" data-dismiss="modal" onClick={this.onRestoreProfile}>Restore</button>
                                </div>
                            </div>
                        </div>
                    </div>;
                }

            }
            if(this.state.permissions_list.ViewData.includes("ExportPDF")){
                downloadButton =  <li className="nav-item">
                    <ReactToPrint
                        trigger={() => <button
                            className="btn-outline-info  border-dark button-nav"
                        ><FaFileDownload size={iconSize}/>Export PDF
                        </button>}
                        content={() => this.componentRef}
                    />

                </li>;
            }




        }else{
            downloadButton =  <li className="nav-item">
                <ReactToPrint
                    trigger={() => <button
                        className="btn-outline-info  border-dark button-nav"
                    ><FaFileDownload size={iconSize}/>Export PDF
                    </button>}
                    content={() => this.componentRef}
                />

            </li>;
        }
        // const referenceCheck = <li className="nav-item">
        //     <a
        //         href="/welcome"
        //         className="btn-outline-dark nav-link border-dark button-nav"
        //     ><FaSearch size={iconSize}/>Do another check</a>
        // </li>;

        let recordsListOrNot;
        if(this.props.auth.user.type === "AdminUser"){
            recordsListOrNot = <BreadcrumbItem><a href="/recordsList">Records List</a></BreadcrumbItem>;
        }
        return (
            <div >
                <MainNavBar SignIn={false} SignUp={false} dashboard={false} socialMedia={false}/>
                {this.state.loading ? <LoadingScreen/> : this.state.fraudLoading ? <NotFound/> : <React.Fragment>
                    {DeleteConfirm}
                    <div className="home-three-style">
                        <header className="header-section">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4">

                                        <Breadcrumb>
                                            <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
                                            <BreadcrumbItem><a href="/dashboard">Dashboard</a></BreadcrumbItem>
                                            {recordsListOrNot}
                                            <BreadcrumbItem active>Institution Record</BreadcrumbItem>
                                        </Breadcrumb>
                                    </div>
                                    <div className="col-md-8 text-md-right header-buttons">
                                        <ul className="nav justify-content-end">
                                            {EditorNot}
                                            {DeleteorNot}
                                            {InsertHistory}
                                            {downloadButton}
                                            {/*{referenceCheck}*/}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </header>


                        <div
                            className="container-fluid p-0"
                        >
                            <div className="row m-0" ref={el => (this.componentRef = el)}
                                 style={{'padding-left':30}}>

                                <div className="col-xl-5 col-lg-5 p-0">
                                    <div className="main-left-area h-100">


                                        <section className="intro-section ">
                                            <div className="historyListTitle">
                                                <FaSearch size="3rem"/> <h2>Inklusivity : Reference Check</h2>
                                            </div>
                                            <div className="icon-nav justify-content-start">
                                                <FaRegBuilding size="5rem"/>
                                            </div>
                                            <div className="hero-text">
                                                <h3 className={this.state.institution.deleted?"display-4 deleted":"display-4"}>{this.state.institution.name_in_khmer}</h3>
                                                <h3 className={this.state.institution.deleted?"display-4 deleted":"display-4"}>{this.state.institution.name}</h3>
                                            </div>
                                            <div className="row">
                                                <div className="col mr-auto ml-auto">
                                                    <p className="font-weight-bold">You visited this profile on:</p>
                                                </div>
                                                <div className="col mr-auto ml-auto">
                                                    <p className="font-weight-bold text-danger"><em><Moment
                                                        format="LLLL">{this.props.match.params.date}</Moment></em>
                                                    </p>
                                                </div>
                                            </div>

                                            <Element name="scroll-to-element" className="element">
                                                {EditPanel}
                                            </Element>
                                            <div className="hero-info pt-5">
                                                <h2> <FaInfo size="2rem"/> General Info</h2>
                                                <div className="row">
                                                    <div className="col">
                                                        <p className="font-weight-bold">Registered Number:</p>
                                                    </div>
                                                    <div className="col">
                                                        <p>{this.state.institution.register_number}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <p className="font-weight-bold">Tax Identification Number</p>
                                                    </div>
                                                    <div className="col">
                                                        <p>{this.state.institution.tax_identification_number}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <p className="font-weight-bold">Type</p>
                                                    </div>
                                                    <div className="col">
                                                        <p>{this.state.institution.institution_type}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <p className="font-weight-bold">Sector </p>
                                                    </div>
                                                    <div className="col">
                                                        <p>{this.state.institution.sector}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <p className="font-weight-bold">Representative </p>
                                                    </div>
                                                    <div className="col">
                                                        <p>{this.state.institution.representative}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <p className="font-weight-bold">Address </p>
                                                    </div>
                                                    <div className="col">
                                                        <p>{this.state.institution.current_address}</p>
                                                    </div>
                                                </div>
                                                <h2><FaPhone size="2rem"/> Contact Info</h2>
                                                <div className="row">
                                                    <div className="col">
                                                        <p className="font-weight-bold">Contact </p>
                                                    </div>
                                                    <div className="col">
                                                        <p>{this.state.institution.contact}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <p className="font-weight-bold">Fax </p>
                                                    </div>
                                                    <div className="col">
                                                        <p>{this.state.institution.fax}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <p className="font-weight-bold">Email </p>
                                                    </div>
                                                    <div className="col">
                                                        <p>{this.state.institution.email}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {additionalInfo}
                                        </section>


                                    </div>
                                </div>

                                <div className="col-xl-6 col-lg-7">

                                    <div className="main-right-area">

                                        <section className="resume-section spad pt-0">
                                            <div className="historyListTitle">
                                                <h1>Institution History</h1>
                                            </div>
                                            {InsertPanel}
                                            <ul className="resume-list">
                                                {HistoryItem}
                                            </ul>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>}
            </div>

        )
    }
}

InstitutionPage.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(InstitutionPage));