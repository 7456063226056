import React, {Component} from "react";
import DateTime from "react-datetime";
import ImageUploader from "react-images-upload";
import { Button, ButtonGroup } from 'reactstrap';
import axios from 'axios';
import Alert from "react-s-alert";


class InsertIndividualHistoryAdmin extends Component{
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            date_of_birth:'',
            identification_number :'',
            fromYear:'',
            toYear:'',
            fromMonth:'',
            toMonth:'',
            fromDay:'',
            toDay:'',
            organization:'',
            position:'',
            reference:'',
            summary:' ',
            positive: true,
            negative: false,
            access_classification : '',
            status :'',
            evidence_document:[]
        }
    }
    onChange = e => {
        e.preventDefault();
        this.setState({[e.target.id]: e.target.value});
    };
    handleChange = date => {
        this.setState({
            date_of_birth: date
        });
    };
    onSubmit = e =>{
        e.preventDefault();
        const Data = new FormData();
        Data.append('myFile',this.state.evidence_document[0]);
        Data.append('name',this.state.name);
        Data.append('date_of_birth',this.state.date_of_birth);
        Data.append('identification_number',this.state.identification_number);
        Data.append('fromYear',this.state.fromYear);
        Data.append('toYear',this.state.toYear);
        Data.append('organization',this.state.organization);
        Data.append('work_summary',this.state.summary);
        Data.append('position',this.state.position);
        Data.append('reference',this.state.reference);
        Data.append('info_type',!!this.state.positive);
        Data.append('status', this.state.status);
        Data.append('access_classification', this.state.access_classification);
        Data.append('data_contributor', this.props.user);
        axios.post('/api-individualProfile/insert_history',Data).then( res =>{
            for (let i in res.data) {
                Alert.info(res.data[i], {
                    position: 'bottom-right',
                    effect: 'jelly'
                });
            }
            window.location.reload();
        }).catch( err =>{
            for(let i in err.response.data){
                Alert.error(err.response.data[i], {
                    position: 'bottom-right',
                    effect: 'jelly'
                });
            }
        })

    };
    onDropEvidence = file => {
        this.setState({
            evidence_document: this.state.evidence_document.concat(file)
        });
    };
    render() {
        console.log(this.props);
        let StatusOrNot;
        if(this.props.status){
            StatusOrNot =  <div className="row">
                <div className="col-sm-4 ml-auto mr-auto">
                    <p className="font-weight-bold">History Status: </p>
                </div>
                <div className="col-sm-4 ml-auto mr-auto">
                    <select
                        id="status"
                        className="form-control"
                        onChange={this.onChange}
                        value={this.state.status}
                    >
                        <option >Select One...</option>
                        <option >In Review</option>
                        <option >Published</option>
                        <option >Rejected</option>
                    </select>
                </div>
            </div>;
        }
        return(

            <form className="form " onSubmit={this.onSubmit}
                  encType="multipart/form-data" method="POST"
            >

                <div className="text-left">
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto">
                            <p className="font-weight-bold">Name: </p>
                        </div>
                        <div className="col-sm-4 ml-auto mr-auto">
                            <input
                                onChange={this.onChange}
                                className="form-control"
                                value={this.state.name}
                                id="name"
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto">
                            <p className="font-weight-bold">Date of Birth: </p>
                        </div>
                        <div className="col-sm-4 ml-auto mr-auto">
                            <DateTime
                                onChange={this.handleChange}
                                selected = {this.state.date_of_birth}
                                timeFormat={false}
                                inputProps={{ placeholder: "10/01/2019" }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto">
                            <p className="font-weight-bold">Identification Number: </p>
                        </div>
                        <div className="col-sm-4 ml-auto mr-auto">
                            <input
                                onChange={this.onChange}
                                className="form-control"
                                value={this.state.identification_number}
                                id="identification_number"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto text-center">
                            <h4 className="title">Employment History</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto">
                            <p className="font-weight-bold">From Year/ To Year: </p>
                        </div>
                        <div className="col-sm-2 ml-auto ">
                            <input
                                onChange={this.onChange}
                                className="form-control"
                                value={this.state.fromYear}
                                id="fromYear"
                                type="Number"
                                placeholder="ex. 2015"
                                required
                            />

                        </div>
                        <div className="col-sm-2 mr-auto">
                            <input
                                onChange={this.onChange}
                                className="form-control"
                                value={this.state.toYear}
                                type="Number"
                                id="toYear"
                                placeholder="ex. 2019"
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto">
                            <p className="font-weight-bold">From Month/ To Month: </p>
                        </div>
                        <div className="col-sm-2 ml-auto">
                            <select
                                onChange={this.onChange}
                                className="form-control"
                                value={this.state.fromMonth}
                                id="fromMonth"
                                //type="Number"
                                //placeholder="ex. 2015"
                            >
                                <option >...</option>
                                <option value="January">January</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>

                            </select>

                        </div>
                        <div className="col-sm-2 mr-auto">
                            <select
                                onChange={this.onChange}
                                className="form-control"
                                defaultValue={this.state.toMonth}
                                id="toMonth"
                                //type="Number"
                                //placeholder="ex. 2015"
                            >
                                <option >...</option>
                                <option value="January">January</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>

                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto">
                            <p className="font-weight-bold">From Day/ To Day: </p>
                        </div>
                        <div className="col-sm-2 ml-auto ">
                            <input
                                onChange={this.onChange}
                                className="form-control"
                                defaultValue={this.state.fromDay}
                                id="fromDay"
                                type="Number"
                                //placeholder="ex. 2015"
                                //required
                            />

                        </div>
                        <div className="col-sm-2 mr-auto">
                            <input
                                onChange={this.onChange}
                                className="form-control"
                                defaultValue={this.state.toDay}
                                type="Number"
                                id="toDay"
                                //placeholder="ex. 2019"
                                //required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto">
                            <p className="font-weight-bold">Organization: </p>
                        </div>
                        <div className="col-sm-4 ml-auto mr-auto">
                            <input
                                onChange={this.onChange}
                                className="form-control"
                                value={this.state.organization}
                                id="organization"
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto">
                            <p className="font-weight-bold">Position: </p>
                        </div>
                        <div className="col-sm-4 ml-auto mr-auto">
                            <input
                                onChange={this.onChange}
                                className="form-control"
                                value={this.state.position}
                                id="position"
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto">
                            <p className="font-weight-bold">Summary: </p>
                        </div>
                        <div className="col-sm-4 ml-auto mr-auto">
                            <textarea
                                onChange={this.onChange}
                                className="form-control"
                                value={this.state.summary}
                                id="summary"
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto">
                            <p className="font-weight-bold">Reference: </p>
                        </div>
                        <div className="col-sm-4 ml-auto mr-auto">
                            <textarea
                                onChange={this.onChange}
                                className="form-control"
                                value={this.state.reference}
                                id="reference"
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto">
                            <p className="font-weight-bold">Access Classification: </p>
                        </div>
                        <div className="col-sm-4 ml-auto mr-auto">
                            <select
                                id="access_classification"
                                className="form-control"
                                onChange={this.onChange}
                                value={this.state.access_classification}
                            >
                                <option selected>Select One...</option>
                                <option >Public</option>
                                <option >Confidential</option>
                                <option >Strictly-Confidential</option>
                                <option >Not-Accessible</option>
                            </select>
                        </div>
                    </div>
                    {StatusOrNot}
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto">
                            <p className="font-weight-bold">Information Type: </p>
                        </div>
                        <div className="col-sm-4 ml-auto mr-auto text-center">
                            <ButtonGroup>
                                <Button color="success" onClick={() =>
                                    this.setState({
                                        positive : true,
                                        negative : false
                                    })} active={this.state.positive === true}>Positive</Button>
                                <Button color="danger" onClick={() => {
                                    this.setState({
                                        negative : true,
                                        positive : false
                                    })
                                }} active={this.state.negative === true}>Negative</Button>
                            </ButtonGroup>
                        </div>
                    </div>
                    <div className="row">
                        <ImageUploader
                            singleImage={true}
                            withPreview={true}
                            withIcon={true}
                            buttonText='Upload Evidence Document'
                            onChange={this.onDropEvidence}
                            imgExtension={['.jpg', '.gif', '.png', '.gif']}
                            maxFileSize={5242880}
                        />
                    </div>
                </div>
                <button type="submit" className="site-btn text-success">Insert Individual history
            </button>
            </form>
        )
    }
}
export default InsertIndividualHistoryAdmin;