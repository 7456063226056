import React, {Component} from "react";
import axios from 'axios';
import ReactTable from 'react-table';
import {trackPromise} from "react-promise-tracker";
import ShowAlert from "../../../../utils/ShowAlert";
import Moment from "react-moment";

class ActivityList extends Component{
    constructor(props) {
        super(props);
        this.state = {
            activities: '',
            activitiesDocs: [],
            activitiesTotalPages: null,
            loadingActivities: true,
        };
        this.fletchData = this.fletchData.bind(this);
    }

    fletchData(state, instance) {
        this.setState({loadingActivities: true});
        const page = state.page + 1;
        axios.get('/api-adminMember/getActivities/' +this.props.user+ '/' + page +'/' + state.pageSize  ).then(res => {
            this.setState({
                activities: res.data,
                activitiesDocs: res.data.docs,
                activitiesTotalPages: res.data.totalPages,
                loadingActivities: false
            })
        }).then(err => {
            ShowAlert(err);
        })
    }

    render() {
        console.log(this.state.activitiesDocs);
        const ActivitiesColumn = [
            {
                Header: 'Date and Time',
                accessor: 'activityDate',
                Cell: row => (
                    <Moment format="DD/MM/YYYY hh:mm A" className="text-info">{row.value}</Moment>
                )

            },

            {
                Header: 'Activity Type',
                accessor: 'type'
            },

            {
                Header: 'Profile Name',
                accessor: 'profile.name'
            },

            {
                Header: 'Profile Name in Khmer',
                accessor: 'profile.name_in_khmer'
            }

            ,
            {
                Header: 'Action',
                accessor: '',
                Cell : props =>(
                    <div>
                        {props.value.profile.kind === "IndividualProfile"?
                            <a href={"/individualEmployee/" + props.value.profile._id + '/' + new Date()}> View Profile </a>:
                            <a href={"/institution/" + props.value.profile._id + '/' + new Date()}> View Profile </a>}
                    </div>
                )

            }
        ];
        const {activitiesDocs, activitiesTotalPages, loadingActivities} = this.state;
        return(
            <React.Fragment>
                <ReactTable
                    manual
                    data={activitiesDocs}
                    pages={activitiesTotalPages}
                    columns={ActivitiesColumn}
                    defaultPageSize={10}
                    loading={loadingActivities}
                    onFetchData={this.fletchData} // Request new data when things change
                />
            </React.Fragment>
        )
    }
}

export default ActivityList;