import React, {Component} from "react";
import MainNavBar from "../../../../components/Navbars/MainNavBar";
import ReactTable from 'react-table'
import {
    AiOutlineUsergroupDelete,
    FaBuilding,
    FaSearch, FaUserAltSlash,
    FaUserClock,
    FaUsers, MdPageview
} from "react-icons/all";
import {Badge} from "reactstrap";

class UsersList extends Component {
    constructor(props) {
        super(props);

    }
    render() {

        const Organizationcolumns = [
            {
                Header: 'User',
                accessor: '_id',
                Cell : props =>(
                    <div>
                        <a href={"/organizationUser/" + props.value} target="_blank"><MdPageview/> View User</a>
                    </div>
                )
            },
            {
                Header: 'Registered Number',
                accessor: 'register_number'

            },
            {
                Header: 'Name',
                accessor: 'name'
            },
            {
                Header: 'Name In Khmer',
                accessor: 'name_in_khmer'
            },
            {
                Header: 'Representative',
                accessor: 'representative'
            },
            {
                Header: 'Status',
                accessor: '',
                Cell: row => (
                    <span>
            <span style={{
                color: row.value.isDeactivated === true ?  '#e74c3c'
                    : row.value.isVerified === false ? '#ffbf00'
                        :  row.value.isVerified === true ? '#2ecc71' : '#e74c3c',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                        row.value.isDeactivated === true ? 'Deactivated'
                            : row.value.isVerified === false ? `Not Verified`
                            : row.value.isVerified === true ? 'Verified' : 'Invalid'
                    }
          </span>
                )
            }
        ];

        const Individualcolumns = [
            {
                Header: 'User',
                accessor: '_id',
                Cell : props =>(
                    <div>
                        <a href={"/individualUser/" + props.value} target="_blank"><MdPageview/> View User</a>
                    </div>
                )
            },
            {
                Header: 'ID',
                accessor: 'identification_number'
            }

            ,
            {
                Header: 'Name',
                accessor: 'name'

            },
            {
                Header: 'Name In Khmer',
                accessor: 'name_in_khmer'
            },
            {
                Header: 'Organization',
                accessor: 'organization'
            },
            {
                Header: 'Status',
                accessor: '',
                Cell: row => (
                    <span>
            <span style={{
                color: row.value.isDeactivated === true ?  '#e74c3c'
                    : row.value.isVerified === false ? '#ffbf00'
                        :  row.value.isVerified === true ? '#2ecc71' : '#e74c3c',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                        row.value.isDeactivated === true ? 'Deactivated'
                            : row.value.isVerified === false ? `Not Verified`
                            : row.value.isVerified === true ? 'Verified' : 'Invalid'
                    }
          </span>
                )
            }
        ];

        return (
            <React.Fragment>
                <MainNavBar SignIn={false} SignUp={false} dashboard={true} socialMedia={false}/>
                <div className="section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 ml-auto mr-auto text-center">
                                <div className="historyListTitle">
                                    <h2><FaSearch size="3rem"/> Inklusivity : Reference Check</h2>
                                </div>
                                <h3 className="title"><FaUsers/>Users List</h3>
                                <div className="card" >
                                    <div className="card-header">
                                        <ul className="nav nav-tabs justify-content-center" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#activated"
                                                   role="tab">
                                                    <FaUserClock/><br/>
                                                    Active<br/>
                                                    <Badge
                                                        color="success">
                                                        {
                                                            this.props.activatedIndividualUsers.length +
                                                            this.props.activatedOrganizationUsers.length}
                                                    </Badge>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#deavtivated"
                                                   role="tab">
                                                    <FaUserAltSlash/><br/>
                                                    Deactivated<br/>
                                                    <Badge
                                                        color="danger">
                                                        {this.props.deactivatedIndividualUsers.length +
                                                        this.props.deactivatedOrganizationUsers.length
                                                        }</Badge>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content text-center">
                                            <div className="tab-pane active" id="activated" role="tabpanel">
                                                <AiOutlineUsergroupDelete size="2rem"/>
                                                <h3 className="title">Individual Users</h3>
                                                <ReactTable

                                                    filterable
                                                    columns={Individualcolumns}
                                                    showPagination={true}
                                                    data={this.props.activatedIndividualUsers}
                                                    defaultPageSize={5}

                                                />
                                                <br/>
                                                <FaBuilding size="2rem"/>
                                                <h3 className="title">Organization Users</h3>
                                                <ReactTable

                                                    filterable
                                                    columns={Organizationcolumns}
                                                    showPagination={true}
                                                    data={this.props.activatedOrganizationUsers}

                                                    defaultPageSize={5}

                                                />
                                            </div>
                                            <div className="tab-pane  " id="deavtivated" role="tabpanel">
                                                <AiOutlineUsergroupDelete size="2rem"/>
                                                <h3 className="title">Individual Users</h3>
                                                <ReactTable

                                                    data={this.props.deactivatedIndividualUsers}
                                                    columns={Individualcolumns}
                                                    defaultPageSize={5}
                                                    filterable
                                                    showPagination={true}
                                                />
                                                <br/>
                                                <FaBuilding size="2rem"/>
                                                <h3 className="title">Organization Users</h3>
                                                <ReactTable
                                                    filterable
                                                    data={this.props.deactivatedOrganizationUsers}
                                                    columns={Organizationcolumns}
                                                    defaultPageSize={5}
                                                    showPagination={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default UsersList;