import React, {Component} from "react";
import axios from 'axios';
import Alert from "react-s-alert";

class UpdateOrganization extends Component{
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            name_in_khmer: '',
            register_number : '',
            tax_identification_number : '',
            representative : '',
            position : '',
            current_address: '',
            number : '',
            trustLevel : ''
        }
    }

    onChange = e => {
        e.preventDefault();
        this.setState({[e.target.id]: e.target.value});

    };
    onSubmit = e => {
        e.preventDefault();
        const update = {
            name: this.state.name ?this.state.name : this.props.organization.name,
            name_in_khmer: this.state.name_in_khmer ? this.state.name_in_khmer :this.props.organization.name_in_khmer,
            register_number : this.state.register_number ?  this.state.register_number :this.props.organization.register_number ,
            tax_identification_number : this.state.tax_identification_number ? this.state.tax_identification_number : this.props.organization.tax_identification_number,
            representative : this.state.representative ? this.state.representative : this.props.organization.representative,
            position : this.state.position ? this.state.position : this.props.organization.position,
            current_address: this.state.current_address ? this.state.current_address : this.props.organization.current_address,
            reference_check_number : this.state.number ? this.state.number : this.props.organization.reference_check_number,
            trustLevel: this.state.trustLevel?this.state.trustLevel:this.props.organization.trustLevel
        };
        axios.patch('/api-organizationMember/update/' + this.props.organization._id ,update).then(res => {
            window.location.reload();
        }).catch(err => {
            window.location.reload();
        })
    };
    render() {
        let referenceCheck;
        if(this.props.referenceNum === true){
            referenceCheck = <div className="row">
                <div className="col-sm-4 ml-auto mr-auto">
                    <p className="font-weight-bold">Reference Check Number: </p>
                </div>
                <div className="col-sm-4 ml-auto mr-auto">
                    <input
                        onChange={this.onChange}
                        className="form-control"
                        defaultValue={this.props.organization.reference_check_number}
                        id="number"
                        required
                    />
                </div>
            </div>;
        }else{
            referenceCheck = <span/>
        }
        let trustLevel;
        if(this.props.trust){
            trustLevel= <div className="row">
                <div className="col-sm-4 ml-auto mr-auto">
                    <p className="font-weight-bold">Trust Level: </p>
                </div>
                <div className="col-sm-4 ml-auto mr-auto">
                    <select
                        onChange={this.onChange}
                        className="form-control"

                        id="trustLevel"
                        required
                    >
                        <option selected={this.props.organization.trustLevel === 0} value={0}>0%</option>
                        <option selected={this.props.organization.trustLevel === 30} value={30}>30%</option>
                        <option selected={this.props.organization.trustLevel === 50} value={50}>50%</option>
                        <option selected={this.props.organization.trustLevel === 75} value={75}>75%</option>
                        <option selected={this.props.organization.trustLevel === 100} value={100}>100%</option>

                    </select>
                </div>
            </div>
        }
        return(
            <React.Fragment>
                <Alert stack={{limit: 3}}/>
                <form className="form " onSubmit={this.onSubmit}
                      encType="multipart/form-data" method="POST"
                >
                    <div className="text-left">
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Name: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.organization.name}
                                    id="name"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Name In Khmer: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.organization.name_in_khmer}
                                    id="name_in_khmer"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Email: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">{this.props.organization.email}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Registered Number: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.organization.register_number}
                                    id="register_number"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Tax Identification Number: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.organization.tax_identification_number}
                                    id="tax_identification_number"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Representative: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.organization.representative}
                                    id="representative"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Position: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.organization.position}
                                    id="position"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Current Address: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.organization.current_address}
                                    id="current_address"
                                    required
                                />
                            </div>
                        </div>
                        {trustLevel}
                        {referenceCheck}
                    </div>
                    <button type="submit" className="site-btn text-success">Update Info</button>
                </form>
            </React.Fragment>

        )
    }
}
export default UpdateOrganization;