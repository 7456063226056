import React, {Component} from "react";

// reactstrap components
import {Button, Container} from "reactstrap";

// core components
const backgroundImage =
    'https://images.unsplash.com/photo-1496902526517-c0f2cb8fdb6a?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80';

class LandingPageHeader extends Component {

  constructor(){
    super();
    this.myRef = React.createRef();
  }
    // componentDidMount() {
    //     let pageHeader = this.myRef;
    //     if (window.innerWidth > 991) {
    //         const updateScroll = () => {
    //             let windowScrollTop = window.pageYOffset / 3;
    //             pageHeader.current.style.transform =
    //                 "translate3d(0," + windowScrollTop + "px,0)";
    //         };
    //         window.addEventListener("scroll", updateScroll);
    //         return function cleanup() {
    //             window.removeEventListener("scroll", updateScroll);
    //         };
    //     }
    // }

    // componentDidUpdate() {
    //     let pageHeader = this.myRef;
    //     if (window.innerWidth > 991) {
    //         const updateScroll = () => {
    //             let windowScrollTop = window.pageYOffset / 3;
    //             pageHeader.current.style.transform =
    //                 "translate3d(0," + windowScrollTop + "px,0)";
    //         };
    //         window.addEventListener("scroll", updateScroll);
    //         return function cleanup() {
    //             window.removeEventListener("scroll", updateScroll);
    //         };
    //     }
    // }

    render() {
        return (
            <React.Fragment>
                <div className="page-header page-header-small">
                    <div
                        className="page-header-image"
                        style={{
                            backgroundImage: `url(${backgroundImage})`
                        }}
                        ref={this.myRef}
                    />
                    <div className="content-center">
                        <Container>
                            <h1 className="title">Easy Employee Reference Checking</h1>
                            <div className="text-center">

                                <Button
                                    className="btn-round"
                                    color="info"
                                    href='/sign-up'
                                >
                                    Get Started
                                </Button>


                            </div>
                        </Container>
                    </div>
                </div>
            </React.Fragment>
        );
    }


}

export default LandingPageHeader;
