import React, {Component} from "react";
import axios from 'axios';
import Moment from "react-moment";
import ReactTable from 'react-table';
import ShowAlert from "../../../utils/ShowAlert";

class RecentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            individuals: '',
            individualDocs: [],
            individualTotalPages: null,
            loadingIndividual: true,
            organizations: '',
            organizationDocs: [],
            organizationTotalPages: null,
            loadingOrganization: true
        };

        this.fetchIndividualData = this.fetchIndividualData.bind(this);
        this.fetchInstitutionData = this.fetchInstitutionData.bind(this);
    }

    fetchIndividualData(state, instance) {
        this.setState({loading: true});
        const page = state.page + 1;
        axios.get('/api-counts/getIndividualLogs/' + this.props.user +'/'+ page +'/' + state.pageSize  ).then(res => {

            this.setState({
                individuals: res.data,
                individualDocs: res.data.docs,
                individualTotalPages: res.data.totalPages,
                loadingIndividual: false
            })
        }).then(err => {
            ShowAlert(err);
        })

    };
    fetchInstitutionData(state, instance) {
        this.setState({loading: true});
        const page = state.page + 1;
        axios.get('/api-counts/getInstitutionLogs/' + this.props.user +'/'+ page +'/' + state.pageSize  ).then(res => {
            this.setState({
                organizations: res.data,
                organizationDocs: res.data.docs,
                organizationTotalPages: res.data.totalPages,
                loadingOrganization: false
            })
        }).then(err => {
            ShowAlert(err);
        })

    };


    render() {
        //console.log(this.state.individuals);
        const Organizationcolumns = [
            {
                Header: 'Action',
                accessor: '',
                Cell : props =>(
                    <div>
                        <a href={"/institution/" + props.value.profile._id + '/' + props.value.activityDate}> View Profile </a>
                    </div>
                )
            },
            {
                Header: 'Registered Number',
                accessor: 'profile.register_number'
            }
            ,
            {
                Header: 'Name',
                accessor: 'profile.name'
            },
            {
                Header: 'Name In Khmer',
                accessor: 'profile.name_in_khmer'
            },
            {
                Header: 'Institution Type',
                accessor: 'profile.institution_type'
            },
            {
                Header: 'Date Check',
                accessor: 'activityDate',
                Cell: row => (
                    <Moment format="DD/MM/YYYY hh:mm A" className="text-info">{row.value}</Moment>
                )
            }
        ];

        const Individualcolumns = [

            {
                Header: 'Action',
                accessor: '',
                Cell : props =>(
                    <div>
                        <a href={"/individualEmployee/" + props.value.profile._id +"/" + props.value.activityDate }> View Profile </a>
                    </div>
                )
            },
            {
                Header: 'ID',
                accessor: 'profile.identification_number'
            }
            ,
            {
                Header: 'Name',
                accessor: 'profile.name'
            },
            {
                Header: 'Date of Birth',
                accessor: 'profile.date_of_birth',
                Cell: row => (
                    <Moment format="DD/MM/YYYY">{row.value}</Moment>
                )
            },
            {
                Header: 'Nationality',
                accessor: 'profile.nationality'
            },
            {
                Header: 'Date Check',
                accessor: 'activityDate',
                Cell: row => (
                    <Moment format="DD/MM/YYYY hh:mm A" className="text-info">{row.value}</Moment>
                )
            }
        ];
        const {individualDocs, individualTotalPages, loadingIndividual, organizationDocs,
            organizationTotalPages, loadingOrganization} = this.state;

        if(true){
            return (
                <React.Fragment>
                    <div className="card-header">
                        <h4 className="title">Your recent reference check</h4>
                        <ul className="nav nav-tabs justify-content-center" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#individuallog"
                                   role="tab">
                                    <i className="now-ui-icons users_single-02"></i>
                                    Individual Profile
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#institutionlog" role="tab">
                                    <i className="now-ui-icons business_bank"></i>
                                    Institution Profile
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="card-body">
                        <div className="tab-content">
                            <div className="tab-pane active" id="individuallog" role="tabpanel">

                                <ReactTable
                                    //manual
                                    data={this.props.IndividualProfiles}
                                    //pages={individualTotalPages}
                                    columns={Individualcolumns}
                                    defaultPageSize={5}
                                    filterable
                                    //loading={loadingIndividual}
                                    //onFetchData={this.fetchIndividualData} // Request new data when things change
                                    className="-striped -highlight"
                                />
                            </div>
                            <div className="tab-pane " id="institutionlog" role="tabpanel">
                                <ReactTable
                                    //manual
                                    filterable
                                    data={this.props.InstitutionProfiles}
                                    columns={Organizationcolumns}
                                    //pages={organizationTotalPages}
                                    defaultPageSize={5}
                                    //loading={loadingOrganization}
                                    //onFetchData={this.fetchInstitutionData} // Request new data when things change
                                    className="-striped -highlight"
                                />
                            </div>
                        </div>




                    </div>
                </React.Fragment>
            )
        }else{
            return(
                <div className="card-body">
                    <div className="tab-content">
                        <h5>You don't have any recent check...</h5>
                    </div>
                </div>

                )
        }

    }

}

export default RecentList;