import React, {Component} from "react";
import ImageUploader from "react-images-upload";
import { Button, ButtonGroup } from 'reactstrap';
import axios from 'axios';
import ShowAlert from "../../../../utils/ShowAlert";


class EditIndividualHistory extends Component{
    constructor(props) {
        super(props);
        this.state = {
            fromYear:'',
            toYear:'',
            fromMonth:'',
            toMonth:'',
            fromDay:'',
            toDay:'',
            organization:'',
            position:'',
            reference:'',
            work_summary:'',
            positive: '',
            negative: '',
            evidence_document:[],
            access_classification :'',
            status :''
        }
    }
    onChange = e => {
        e.preventDefault();
        this.setState({[e.target.id]: e.target.value});
    };
    onSubmit = e =>{
        e.preventDefault();
        const Data = new FormData();
        Data.append('myFile',this.state.evidence_document[0]);
        Data.append('fromYear',this.state.fromYear?this.state.fromYear:this.props.history.fromYear);
        Data.append('toYear',this.state.toYear?this.state.toYear:this.props.history.toYear);
        Data.append('fromMonth',this.state.fromMonth?this.state.fromMonth:this.props.history.fromMonth);
        Data.append('toMonth',this.state.toMonth?this.state.toMonth:this.props.history.toMonth);
        Data.append('fromDay',this.state.fromDay?this.state.fromDay:this.props.history.fromDay);
        Data.append('toDay',this.state.toDay?this.state.toDay:this.props.history.toDay);
        Data.append('organization',this.state.organization?this.state.organization:this.props.history.organization);
        Data.append('position',this.state.position?this.state.position:this.props.history.position);
        Data.append('reference',this.state.reference ? this.state.reference : this.props.history.reference );
        Data.append('work_summary',this.state.work_summary? this.state.work_summary : this.props.history.work_summary );
        Data.append('status',this.state.status?this.state.status:this.props.history.status);
        Data.append('info_type',this.state.positive?true:this.state.negative?false:this.props.history.info_type);
        Data.append('data_contributor', this.props.user);
        Data.append('access_classification', this.state.access_classification?this.state.access_classification:this.props.history.access_classification);
        axios.put('/api-individualProfile/update_history/' + this.props.history._id ,Data).then( res =>{
            window.location.reload();
        }).catch( err =>{
            ShowAlert(err.response.data);
        })

    };
    onDropEvidence = file => {
        this.setState({
            evidence_document: this.state.evidence_document.concat(file)
        });
    };
    render() {
        let StatusOrNot;
        if(this.props.status){
            StatusOrNot = <div className="row">
                <div className="col-sm-4 ml-auto mr-auto">
                    <p className="font-weight-bold">History Status: </p>
                </div>
                <div className="col-sm-4 ml-auto mr-auto">
                    <select
                        id="status"
                        className="form-control"
                        onChange={this.onChange}
                        defaultValue={this.props.history.status}
                    >
                        <option >In Review</option>
                        <option >Published</option>
                        <option >Rejected</option>
                    </select>
                </div>
            </div>
        }
        return(

            <form className="form editPanel" onSubmit={this.onSubmit}
                  encType="multipart/form-data" method="POST"

            >

                <div className="text-left">
                    <div className="row">
                        <div className="col-sm-8 ml-auto mr-auto text-center">
                            <h3 className="font-weight-bold">Update Employment History</h3>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto">
                            <p className="font-weight-bold">From Year/ To Year: </p>
                        </div>
                        <div className="col-sm-2 ml-auto ">
                            <input
                                onChange={this.onChange}
                                className="form-control"
                                defaultValue={this.props.history.fromYear}
                                id="fromYear"
                                type="Number"
                                placeholder="ex. 2015"
                                required
                            />

                        </div>
                        <div className="col-sm-2 mr-auto">
                            <input
                                onChange={this.onChange}
                                className="form-control"
                                defaultValue={this.props.history.toYear}
                                type="Number"
                                id="toYear"
                                placeholder="ex. 2019"
                                required
                            />
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto">
                            <p className="font-weight-bold">From Month/ To Month: </p>
                        </div>
                        <div className="col-sm-2 ml-auto">
                            <select
                                onChange={this.onChange}
                                className="form-control"
                                defaultValue={this.props.history.fromMonth}
                                id="fromMonth"
                                //type="Number"
                                //placeholder="ex. 2015"
                            >
                                <option >...</option>
                                <option value="January">January</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>

                            </select>

                        </div>
                        <div className="col-sm-2 mr-auto">
                            <select
                                onChange={this.onChange}
                                className="form-control"
                                defaultValue={this.props.history.toMonth}
                                id="toMonth"
                                //type="Number"
                                //placeholder="ex. 2015"
                            >
                                <option >...</option>
                                <option value="January">January</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>

                            </select>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto">
                            <p className="font-weight-bold">From Day/ To Day: </p>
                        </div>
                        <div className="col-sm-2 ml-auto ">
                            <input
                                onChange={this.onChange}
                                className="form-control"
                                defaultValue={this.props.history.fromDay}
                                id="fromDay"
                                type="Number"
                                //placeholder="ex. 2015"
                                //required
                            />

                        </div>
                        <div className="col-sm-2 mr-auto">
                            <input
                                onChange={this.onChange}
                                className="form-control"
                                defaultValue={this.props.history.toDay}
                                type="Number"
                                id="toDay"
                                //placeholder="ex. 2019"
                                //required
                            />
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto">
                            <p className="font-weight-bold">Organization: </p>
                        </div>
                        <div className="col-sm-4 ml-auto mr-auto">
                            <input
                                onChange={this.onChange}
                                className="form-control"
                                defaultValue={this.props.history.organization}
                                id="organization"
                                required
                            />
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto">
                            <p className="font-weight-bold">Position: </p>
                        </div>
                        <div className="col-sm-4 ml-auto mr-auto">
                            <input
                                onChange={this.onChange}
                                className="form-control"
                                defaultValue={this.props.history.position}
                                id="position"
                                required
                            />
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto">
                            <p className="font-weight-bold">Summary: </p>
                        </div>
                        <div className="col-sm-4 ml-auto mr-auto">
                            <textarea
                                onChange={this.onChange}
                                className="form-control"
                                defaultValue={this.props.history.work_summary}
                                id="work_summary"
                                required
                            />
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto">
                            <p className="font-weight-bold">Reference: </p>
                        </div>
                        <div className="col-sm-4 ml-auto mr-auto">
                            <textarea
                                onChange={this.onChange}
                                className="form-control"
                                defaultValue={this.props.history.reference}
                                id="reference"
                                required
                            />
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto">
                            <p className="font-weight-bold">Access Classification: </p>
                        </div>
                        <div className="col-sm-4 ml-auto mr-auto">
                            <select
                                id="access_classification"
                                className="form-control"
                                onChange={this.onChange}
                                defaultValue={this.props.history.access_classification}
                            >
                                <option >Public</option>
                                <option >Confidential</option>
                                <option >Strictly-Confidential</option>
                                <option >Not-Accessible</option>
                            </select>
                        </div>
                    </div>
                    <br/>
                    {StatusOrNot}
                    <div className="row">
                        <div className="col-sm-4 ml-auto mr-auto">
                            <p className="font-weight-bold">Information Type: </p>
                        </div>
                        <div className="col-sm-4 ml-auto mr-auto text-center">
                            <ButtonGroup>
                                <Button color="success" onClick={() =>
                                    this.setState({
                                        positive : true,
                                        negative : false
                                    })} active={this.props.history.info_type === true}>Positive</Button>
                                <Button color="danger" onClick={() => {
                                    this.setState({
                                        negative : true,
                                        positive : false
                                    })
                                }} active={this.props.history.info_type === true}>Negative</Button>
                            </ButtonGroup>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <ImageUploader
                            singleImage={true}
                            withPreview={true}
                            withIcon={true}
                            buttonText='Upload Evidence Document'
                            onChange={this.onDropEvidence}
                            imgExtension={['.jpg', '.gif', '.png', '.gif']}
                            maxFileSize={5242880}
                        />
                    </div>
                </div>
                <button type="submit" className="site-btn text-warning">Update Individual Record
                </button>
            </form>
        )
    }
}
export default EditIndividualHistory;