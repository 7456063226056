import React, {Component} from "react";
import {FaBuilding, FaDatabase, FaSearch, IoIosAddCircleOutline} from "react-icons/all";
import ReactTable from "react-table";
import {institutionSectorColumns,institutionTypeColumns} from './Utilities';
import {Badge} from "reactstrap";
import axios from 'axios';
class Miscellaneous extends Component{
    constructor(props) {
        super(props);
        this.state = {
            type : '',
            sector :''
        }
    }

    onSubmitInstituteType = e =>{
        e.preventDefault();
        axios.post('/api-counts/addInstitutionType/' + this.state.type).then( res =>{
            window.location.reload();
        }).catch(err =>{
            console.log(err);
        });
    };
    onSubmitInstituteSector = e =>{
        e.preventDefault();
        axios.post('/api-counts/addInstitutionSector/' + this.state.sector).then( res =>{
            window.location.reload();
        }).catch(err =>{
            console.log(err);
        });
    };
    onChange = e => {
        e.preventDefault();
        this.setState({[e.target.id]: e.target.value});

    };

    render() {
        //console.log(this.state);
        return(
            <React.Fragment>
                <div className="section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 ml-auto mr-auto text-center">
                                <div className="historyListTitle">
                                    <h2><FaSearch size="3rem"/> Inklusivity : Reference Check</h2>
                                </div>
                                <h3 className="title"> <FaDatabase/> Miscellaneous</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 ml-auto mr-auto text-center">
                                <h4 className="title"><FaBuilding/> Institution Sector List</h4>
                                <Badge color="success">{this.props.institutionSector.length}</Badge>
                                <ReactTable
                                    filterable
                                    data={this.props.institutionSector}
                                    columns={institutionSectorColumns}
                                    defaultPageSize={5}
                                    showPagination
                                />
                            </div>
                            <div className="col-md-4 ml-auto mr-auto text-center">
                                <h4 className="title"><FaBuilding/> Institution Type List</h4>
                                <Badge color="success">{this.props.institutionType.length}</Badge>
                                <ReactTable
                                    filterable
                                    data={this.props.institutionType}
                                    columns={institutionTypeColumns}
                                    defaultPageSize={5}
                                    showPagination
                                />
                            </div>
                            <div className="col-md-4 ml-auto mr-auto text-center">
                                <h4 className="title"><IoIosAddCircleOutline/>Insert Institution Type</h4>
                                <form

                                    className="form"
                                    encType="multipart/form-data"
                                    method="POST"
                                    onSubmit={this.onSubmitInstituteType}
                                >
                                    <input
                                        type="text"
                                        id="type"
                                        onChange={this.onChange}
                                    />
                                    <button className="btn-success" type="submit">Insert</button>
                                </form>
                                <h4 className="title"><IoIosAddCircleOutline/>Insert Institution Sector</h4>
                                <form
                                    className="form"
                                    encType="multipart/form-data"
                                    method="POST"
                                    onSubmit={this.onSubmitInstituteSector}
                                >
                                    <input
                                        type="text"
                                        id="sector"
                                        onChange={this.onChange}
                                    />
                                    <button className="btn-success" type="submit">Insert</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Miscellaneous;