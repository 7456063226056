import React, {Component} from "react";
import axios from 'axios';
import Alert from "react-s-alert";

class OrganizationInfo extends Component{
    constructor(props) {
        super(props);
        this.state = {
            id: props.organization._id,
            name: props.organization.name,
            name_in_khmer: props.organization.name_in_khmer,
            email: props.organization.email,
            register_number : props.organization.register_number,
            tax_identification_number : props.organization.tax_identification_number,
            representative : props.organization.representative,
            position : props.organization.position,
            current_address: props.organization.current_address
        }
    }

    onChange = e => {
        e.preventDefault();
        this.setState({[e.target.id]: e.target.value});

    };
    onSubmit = e => {
        e.preventDefault();
        const update = {
            name: this.state.name,
            name_in_khmer: this.state.name_in_khmer,
            email: this.state.email,
            register_number : this.state.register_number,
            tax_identification_number : this.state.tax_identification_number,
            representative : this.state.representative,
            position : this.state.position,
            current_address: this.state.current_address
        };
        axios.patch('/api-organizationMember/update/' + this.state.id,update).then(res => {
            for (let i in res.data) {
                Alert.info(res.data[i], {
                    position: 'bottom-right',
                    effect: 'jelly'
                });
            }
            window.location.reload();
        }).catch(err => {
            for (let i in err.response.data) {
                Alert.error(err.response.data[i], {
                    position: 'bottom-right',
                    effect: 'jelly'
                });
            }
            window.location.reload();
        })
    };
    render() {
        return(
            <React.Fragment>
                <Alert stack={{limit: 3}}/>
                <form className="form " onSubmit={this.onSubmit}
                      encType="multipart/form-data" method="POST"
                >
                    <div className="text-left">
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Name: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    value={this.state.name}
                                    id="name"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Name In Khmer: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    value={this.state.name_in_khmer}
                                    id="name_in_khmer"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Email: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">{this.state.email}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Registered Number: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    value={this.state.register_number}
                                    id="register_number"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Tax Identification Number: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    value={this.state.tax_identification_number}
                                    id="tax_identification_number"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Representative: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    value={this.state.representative}
                                    id="representative"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Position: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    value={this.state.position}
                                    id="position"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Current Address: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    value={this.state.current_address}
                                    id="current_address"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-lg btn-outline-success btn-round">Update Info
                    </button>
                </form>
            </React.Fragment>

        )
    }
}

export default OrganizationInfo;