import React, {Component} from "react";
import Alert from "react-s-alert";
import axios from 'axios';
import ReactTable from 'react-table';
import Moment from "react-moment";
import { Badge } from 'reactstrap';
import {MdPageview} from "react-icons/md";

class NewRecordsPopUp extends Component{
    render() {
        const Organizationcolumns = [
            {
                Header: 'Action',
                accessor: '',
                Cell: props => (
                    <div>
                        <a href={"/institution/" + props.value._id + "/" + new Date()}
                           target="_blank"
                        ><MdPageview/> View Profile </a>
                    </div>
                )
            },
            {
                Header: 'Registered Number',
                accessor: '',
                Cell: props => (

                    <div className={props.value.deleted ? "deleted" : ""}>{props.value.register_number}</div>

                )
            }

            ,
            {
                Header: 'Name',
                accessor: '',
                Cell: props => (

                    <div
                        className={props.value.deleted ? "font-weight-normal deleted" : "font-weight-normal"}>{props.value.name}</div>

                )
            },
            {
                Header: 'Name In Khmer',
                accessor: '',
                Cell: props => (

                    <div className={props.value.deleted ? "deleted" : ""}>{props.value.name_in_khmer}</div>

                )
            },
            {
                Header: 'Institution Type',
                accessor: '',
                Cell: props => (

                    <div className={props.value.deleted ? "deleted" : ""}>{props.value.institution_type}</div>

                )
            },
            {
                Header: 'Representative',
                accessor: '',
                Cell: props => (

                    <div className={props.value.deleted ? "deleted" : ""}>{props.value.representative}</div>

                )
            },
            {
                Header: 'Status',
                accessor: 'deleted',
                Cell: props => (
                    <span>
            <span style={{
                color: props.value === true ? '#e74c3c'
                    : props.value === false ? '#57d500'
                        : '#2c3e50',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                        props.value === true ? 'Deleted'
                            : props.value === false ? `Published`
                            : 'Invalid'
                    }
          </span>
                )
            },
            {
                Header: 'Date Created',
                accessor: 'dateCreated',
                Cell: row => (
                    <Moment className={row.value.deleted ? "deleted" : "text-info"} format="DD/MM/YYYY">{row.value}</Moment>
                )

            }
        ];
        const Individualcolumns = [
            {
                Header: 'Action',
                accessor: '',
                Cell: props => (
                    <div>
                        <a
                            href={"/individualEmployee/" + props.value._id + "/" + new Date()}
                            target="_blank"
                        ><MdPageview/> View Profile </a>
                    </div>
                )
            },
            {
                Header: 'ID',
                accessor: '',
                Cell: props => (

                    <div
                        className={props.value.deleted ? "font-weight-normal deleted" : "font-weight-normal"}>{props.value.identification_number}</div>

                )
            }

            ,
            {
                Header: 'Name',
                accessor: '',
                Cell: props => (

                    <div
                        className={props.value.deleted ? "font-weight-normal deleted" : "font-weight-normal"}>{props.value.name}</div>

                )
            },
            {
                Header: 'Name In Khmer',
                accessor: '',
                Cell: props => (

                    <div className={props.value.deleted ? "deleted" : ""}>{props.value.name_in_khmer}</div>

                )
            },
            {
                Header: 'Date of Birth',
                accessor: 'date_of_birth',
                Cell: row => (
                    <Moment className={row.value.deleted ? "deleted" : ""} format="DD/MM/YYYY">{row.value}</Moment>
                )
            },
            {
                Header: 'Nationality',
                accessor: '',
                Cell: props => (

                    <div className={props.value.deleted ? "deleted" : ""}>{props.value.nationality}</div>

                )
            },
            {
                Header: 'Status',
                accessor: 'deleted',
                Cell: props => (
                    <span>
            <span style={{
                color: props.value === true ? '#e74c3c'
                    : props.value === false ? '#57d500'
                        : '#2c3e50',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                        props.value === true ? 'Deleted'
                            : props.value === false ? `Published`
                            : 'Invalid'
                    }
          </span>
                )
            },
            {
                Header: 'Date Created',
                accessor: 'dateCreated',
                Cell: row => (
                    <Moment className={row.value.deleted ? "deleted " : "text-info"} format="DD/MM/YYYY">{row.value}</Moment>
                )

            }
        ];
        return (
            <div className="card">
                <div className="card-header">

                    <ul className="nav nav-tabs justify-content-center" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" data-toggle="tab" href="#newrecord"
                               role="tab">
                                <i className="now-ui-icons users_single-02"></i>
                                New Records <Badge color="success">{this.props.newIndividualProfile.length + this.props.newInstitutionProfile.length}</Badge>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#existing"
                               role="tab">
                                <i className="now-ui-icons business_bank"></i>
                                Matched with Existing Records <Badge color="danger">{this.props.alreadyExistIndividualProfile.length + this.props.alreadyExistInstitutionProfile.length}</Badge>
                            </a>
                        </li>

                    </ul>
                </div>
                <div className="card-body">
                    <div className="tab-content text-center">
                        <div className="tab-pane active " id="newrecord" role="tabpanel">
                            <h3 className="font-weight-bold">Individual Records</h3>
                            <ReactTable
                                data={this.props.newIndividualProfile}
                                columns={Individualcolumns}
                                showFilters={false}
                                showPagination={true}
                                defaultPageSize={5}
                            />
                            <br/>
                            <h3 className="font-weight-bold">Institution Records</h3>
                            <ReactTable
                                data={this.props.newInstitutionProfile}
                                columns={Organizationcolumns}
                                showFilters={false}
                                showPagination={true}
                                defaultPageSize={5}
                            />
                        </div>
                        <div className="tab-pane " id="existing" role="tabpanel">
                            <h3 className="font-weight-bold">Individual Records</h3>
                            <ReactTable
                                data={this.props.alreadyExistIndividualProfile}
                                columns={Individualcolumns}
                                showFilters={false}
                                showPagination={true}
                                defaultPageSize={5}
                            />
                            <br/>
                            <h3 className="font-weight-bold">Institution Records</h3>
                            <ReactTable
                                data={this.props.alreadyExistInstitutionProfile}
                                columns={Organizationcolumns}
                                showFilters={false}
                                showPagination={true}
                                defaultPageSize={5}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NewRecordsPopUp;