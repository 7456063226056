import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import NotFound from "../../../../components/404/404";
import axios from "axios";
import {trackPromise} from "react-promise-tracker";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import MainNavBar from "../../../../components/Navbars/MainNavBar";
import Miscellaneous from "./Miscellaneous";

class MiscellaneousValidator extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            institutionType :[],
            institutionSector : [],
            authorized : false,

        }
    }
    componentDidMount() {
        trackPromise(
            axios.all([
                axios.get('/api-users/getUserPermissions/' + this.props.auth.user.id),
                axios.get('/api-counts/allMiscellaneous')
            ]).then( axios.spread((res1,res2)=>{
                this.setState({
                    authorized : res1.data.permissions_list.ViewData.includes('Miscellaneous'),
                    institutionType : res2.data.institutionType,
                    institutionSector : res2.data.institutionSector,
                    loading: false
                })
            }))
        ).catch( () =>{
            this.setState({
                authorized : false,
                loading: false
            })
        })

    }


    render() {
        return(
            <div>
                <MainNavBar SignIn={false} SignUp={false} dashboard={true} socialMedia={false}/>
                {this.state.loading? <LoadingScreen/> : this.state.authorized ?
                    <Miscellaneous
                        institutionType = {this.state.institutionType}
                        institutionSector = {this.state.institutionSector}
                    />
                    : <NotFound/>}
            </div>

        )
    }
}
MiscellaneousValidator.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(MiscellaneousValidator));