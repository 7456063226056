import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import NotFound from "../../../../components/404/404";
import axios from "axios";
import {trackPromise} from "react-promise-tracker";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import MainNavBar from "../../../../components/Navbars/MainNavBar";
import HistoriesList from "./HistoriesList";

class HistoriesListValidator extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            authorized: false,
            deletePermission:'',
            permission_classification: '',
            InReviewIndividualHistories: [],
            InReviewInstitutionHistories: [],
            PublishedIndividualHistories: [],
            PublishedInstitutionHistories: [],
            RejectedIndividualHistories: [],
            RejectedInstitutionHistories: [],
            DeletedIndividualHistories: [],
            DeletedInstitutionHistories: []
        }
    }
    componentDidMount() {
        trackPromise(
            axios.all([
                axios.get('/api-users/getUserPermissions/' + this.props.auth.user.id),
                axios.get('api-counts/allHistoriesList/' + this.props.auth.user.id)
            ]).then(axios.spread((res1, res2) => {
                this.setState({
                    authorized: res1.data.permissions_list.ViewData.includes("Histories"),
                    permission_classification: res1.data.permission_classification,
                    deletePermission : res1.data.permissions_list.DeleteData.includes("Histories"),
                    InReviewIndividualHistories: res2.data.InReviewIndividualHistories,
                    InReviewInstitutionHistories: res2.data.InReviewInstitutionHistories,
                    PublishedIndividualHistories: res2.data.PublishedIndividualHistories,
                    PublishedInstitutionHistories: res2.data.PublishedInstitutionHistories,
                    RejectedIndividualHistories: res2.data.RejectedIndividualHistories,
                    RejectedInstitutionHistories: res2.data.RejectedInstitutionHistories,
                    DeletedIndividualHistories: res2.data.DeletedIndividualHistories,
                    DeletedInstitutionHistories: res2.data.DeletedInstitutionHistories,
                    loading: false
                })
            }))
        ).catch(() => {
            this.setState({
                authorized: false,
                loading: false
            })
        })
    }


    render() {
        return(
            <div>
                <MainNavBar SignIn={false} SignUp={false} dashboard={true} socialMedia={false}/>
                {this.state.loading ? <LoadingScreen/> : this.state.authorized ?
                    <HistoriesList
                        title="Histories List"
                        user={this.props.auth.user.id}
                        deletePermission = {this.state.deletePermission}
                        permission_classification={this.state.permission_classification}
                        InReviewIndividualHistories={this.state.InReviewIndividualHistories}
                        InReviewInstitutionHistories={this.state.InReviewInstitutionHistories}
                        PublishedIndividualHistories={this.state.PublishedIndividualHistories}
                        PublishedInstitutionHistories={this.state.PublishedInstitutionHistories}
                        RejectedIndividualHistories={this.state.RejectedIndividualHistories}
                        RejectedInstitutionHistories={this.state.RejectedInstitutionHistories}
                        DeletedIndividualHistories={this.state.DeletedIndividualHistories}
                        DeletedInstitutionHistories={this.state.DeletedInstitutionHistories}
                    />
                    : <NotFound/>}
            </div>

        )
    }
}
HistoriesListValidator.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(HistoriesListValidator));