import React, {Component} from "react";
import axios from "axios";
import ShowAlert from "../../../../utils/ShowAlert";


class InsertInstitution extends Component{
    constructor(props) {
        super(props);
        this.state = {
            organizationName: "",
            organizationNameKhmer: "",
            organizationType: "",
            organizationSector: "",
            organizationRegisteredNumber: "",
            organizationTIN: "",
            organizationContact: "",
            organizationRepresentative: "",
            organizationAddress: ""
        };
    }
    onOrganizationSubmit = e => {
        e.preventDefault();
        const newOrganization = {
            name_in_khmer : this.state.organizationNameKhmer,
            register_number : this.state.organizationRegisteredNumber,
            tax_identification_number : this.state.organizationTIN,
            representative : this.state.organizationRepresentative,
            sector : this.state.organizationSector,
            current_address : this.state.organizationAddress,
            name : this.state.organizationName,
            institution_type : this.state.organizationType,
            contact : this.state.organizationContact,
            data_contributor : this.props.id
        };
        axios.post('/api-institutionProfile/add',newOrganization).then( result =>{
            if(result){
                window.location.href = `/institution/${result.data.id}/${new Date()}`;
            }
        }).catch( err =>{
            ShowAlert(err);
        })

    };
    onChange = e => {
        e.preventDefault();
        this.setState({[e.target.id]: e.target.value});

    };
    render() {
        return (
            <form className="form " onSubmit={this.onOrganizationSubmit}>
                <div className="text-left">
                    <div className="form-group">
                        <label htmlFor="inputAddress">Institution Name</label>
                        <input
                            required={true}
                            onChange={this.onChange}
                            value={this.state.organizationName}
                            type="text"
                            className="form-control"
                            id="organizationName"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputAddress">Institution Name in
                            Khmer</label>
                        <input
                            required={true}
                            onChange={this.onChange}
                            value={this.state.organizationNameKhmer}
                            type="text"
                            className="form-control"
                            id="organizationNameKhmer"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputEmail">Registered Number</label>
                        <input
                            required={true}
                            onChange={this.onChange}
                            value={this.state.organizationRegisteredNumber}
                            type="text"
                            className="form-control"
                            id="organizationRegisteredNumber"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputPassword">Tax Identification Number</label>
                        <input
                            required={true}
                            onChange={this.onChange}
                            value={this.state.organizationTIN}
                            type="text"
                            className="form-control"
                            id="organizationTIN"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputAddress">Institution Type</label>
                        <input
                            required={true}
                            onChange={this.onChange}
                            value={this.state.organizationType}
                            type="text"
                            className="form-control"
                            id="organizationType"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputAddress">Sector</label>
                        <input
                            required={true}
                            onChange={this.onChange}
                            value={this.state.organizationSector}
                            type="text"
                            className="form-control"
                            id="organizationSector"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputAddress">Contact Number</label>
                        <input
                            required={true}
                            onChange={this.onChange}
                            value={this.state.organizationContact}
                            type="text"
                            className="form-control"
                            id="organizationContact"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputAddress">Representative</label>
                        <input
                            required={true}
                            onChange={this.onChange}
                            value={this.state.organizationRepresentative}
                            type="text"
                            className="form-control"
                            id="organizationRepresentative"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputAddress">Current Address</label>
                        <input
                            required={true}
                            onChange={this.onChange}
                            value={this.state.organizationAddress}
                            type="text"
                            className="form-control"
                            id="organizationAddress"
                        />
                    </div>
                </div>

                <button type="submit" className="site-btn text-success">Insert
                </button>
            </form>
        );
    }
}

export default InsertInstitution;