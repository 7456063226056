import React, {Component} from "react";
import MainNavBar from "../../../../components/Navbars/MainNavBar";
import axios from 'axios';
import ReactTable from 'react-table';
import ShowAlert from "../../../../utils/ShowAlert";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {FaSearch, FaUserCog} from "react-icons/all";

class AdminsList extends Component{
    constructor(props) {
        super(props);
        this.state = {
            admins: '',
            adminsDocs: [],
            adminsTotalPages: null,
            loadingAdmins: true,
        };

        this.fetchAdminData = this.fetchAdminData.bind(this);
    }
    fetchAdminData(state, instance) {
        this.setState({loadingAdmins: true});
        const page = state.page + 1;
        let field;
        let sortValue;

        if(!state.sorted){
            field = "name";
            sortValue = 1;
        }

        for(let i in state.sorted){
            field = state.sorted[i].id;
            if(state.sorted[i].desc){
                sortValue =  1;
            }else{
                sortValue = -1;
            }
        }
        axios.get('/api-adminMember/get/' + page +'/' + state.pageSize +'/' + field + '/' + sortValue ).then(res => {
            this.setState({
                admins: res.data,
                adminsDocs: res.data.docs,
                adminsTotalPages: res.data.totalPages,
                loadingAdmins: false
            })
        }).then(err => {
            ShowAlert(err);
        })

    };
    render() {


        const Admincolumns = [
            {
                Header: 'User',
                accessor: '_id',
                Cell : props =>(
                    <div>
                        <a href={"/adminUser/" + props.value}> View/Edit Profile </a>
                    </div>
                )
            },
            {
                Header: 'ID',
                accessor: '',
                Cell : props => (
                    <div className={props.value.isDeactivated ? "deleted" : ""}>{props.value.identification_number}</div>
                )
            },




            {
                Header: 'Name',
                accessor: '',
                Cell : props => (
                    <div className={props.value.isDeactivated ? "deleted" : ""}>{props.value.name}</div>
                )
            },
            {
                Header: 'Email',
                accessor: '',
                Cell : props => (
                    <div className={props.value.isDeactivated ? "deleted" : ""}>{props.value.email}</div>
                )
            },
            {
                Header: 'Permission',
                accessor: '',
                Cell : props => (
                    <div className={props.value.isDeactivated ? "deleted" : ""}>{props.value.permission_classification}</div>
                )
            },
            {
                Header: 'Status',
                accessor: '',
                Cell: row => (
                    <span>
            <span style={{
                color: row.value.isDeactivated === true ?  '#e74c3c'
                    : row.value.isVerified === false ? '#ffbf00'
                        :  row.value.isVerified === true ? '#2ecc71' : '#e74c3c',
                transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {
                        row.value.isDeactivated === true ? 'Deactivated'
                            : row.value.isVerified === false ? `Not Verified`
                            : row.value.isVerified === true ? 'Verified' : 'Invalid'
                    }
          </span>
                )
            }
        ];
        const {adminsDocs, adminsTotalPages, loadingAdmins} = this.state;
        return(
            <React.Fragment>

                <div className="section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-9 ml-auto mr-auto text-center">
                                <div className="historyListTitle">
                                    <h2><FaSearch size="3rem"/> Inklusivity : Reference Check</h2>
                                </div>
                                <h3 className="title"><FaUserCog/> Admin Users</h3>
                                <ReactTable
                                    manual
                                    data={adminsDocs}
                                    pages={adminsTotalPages}
                                    columns={Admincolumns}
                                    defaultPageSize={10}
                                    loading={loadingAdmins}
                                    onFetchData={this.fetchAdminData} // Request new data when things change
                                    //filterable
                                    className="-striped -highlight"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps
)(withRouter(AdminsList));
