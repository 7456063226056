import Alert from 'react-s-alert';

const ShowAlert = props => {
    for (let i in props) {
        Alert.error(props[i], {
            position: 'top',
            effect: 'scale',
            offset: 60
        });
    }
};
export default ShowAlert;