const institutionTypeColumns = [
    {
        header : 'Institution Type',
        accessor : ''
    }
];

const institutionSectorColumns = [
    {
        header : 'Institution Sector',
        accessor : ''
    }
];

export {
    institutionTypeColumns,
    institutionSectorColumns
}