import React, {Component} from "react";
import DateTime from "react-datetime";
import ImageUploader from "react-images-upload";
import axios from "axios";
import ShowAlert from "../../../../utils/ShowAlert";

class CreateNewIndividualAndMerge extends Component{
    constructor(props) {
        super(props);
        this.state = {
            individualFirstName: '',
            individualLastName: '',
            individualFirstInKhmer: "",
            individualLastInKhmer: "",
            individualDateOfBirth: "",
            individualNationality: "",
            individualIdentificationNumber: "",
            individualPassport: [],
            individualPhoto: [],
            individualAddress: ""
        };
    }
    onChange = e => {
        e.preventDefault();
        this.setState({[e.target.id]: e.target.value});

    };
    onDropPassport = file => {
        this.setState({
            individualPassport: this.state.individualPassport.concat(file)
        });
    };

    onDropPhoto = file => {
        this.setState({
            individualPhoto: this.state.individualPhoto.concat(file)
        });
    };
    handleChange = date => {
        this.setState({
            individualDateOfBirth: date
        });
    };
    onCreateIndividualAndMerge = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('myFiles', this.state.individualPassport[0]);
        formData.append('myFiles', this.state.individualPhoto[0]);
        formData.append('name', this.state.individualFirstName + this.state.individualLastName);
        formData.append('name_in_khmer', this.state.individualFirstInKhmer + this.state.individualLastInKhmer);
        formData.append('identification_number', this.state.individualIdentificationNumber);
        formData.append('nationality', this.state.individualNationality);
        formData.append('date_of_birth', this.state.individualDateOfBirth);
        formData.append('current_address', this.state.individualAddress);
        formData.append('data_contributor',this.props.id);
        formData.append('history',this.props.history._id);
        axios.post('/api-IndividualProfile/createAndMergeWithHistory',formData).then( result =>{

            window.location.href = `/individualEmployee/${result.data.id}/${new Date()}`;

        }).catch( err =>{
            ShowAlert(err.response.data);
        })


    };
    render() {
        //console.log(this.props);
        return (
            <form className="form "  onSubmit={this.onCreateIndividualAndMerge}
                  encType="multipart/form-data" method="POST"
            >
                <div className="text-left">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label htmlFor="inputEmail4">First Name</label>
                            <input
                                required={true}
                                onChange={this.onChange}
                                value={this.state.individualFirstName}
                                //defaultValue={this.props.history.additionalProfileInfo.name.split(" ")[0]}
                                type="text"
                                className="form-control"
                                id="individualFirstName"
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="individualLastName">Last Name</label>
                            <input
                                required={true}
                                onChange={this.onChange}
                                value={this.state.individualLastName}
                                //defaultValue={this.props.history.additionalProfileInfo.name.split(" ")[1]}
                                type="text"
                                className="form-control"
                                id="individualLastName"
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label htmlFor="individualFirstInKhmer">Family
                                Name(Khmer)</label>
                            <input
                                required={true}
                                onChange={this.onChange}
                                value={this.state.individualFirstInKhmer}
                                type="text"
                                className="form-control"
                                id="individualFirstInKhmer"
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="individualLastInKhmer">Sure
                                Name(Khmer)</label>
                            <input
                                required={true}
                                onChange={this.onChange}
                                value={this.state.individualLastInKhmer}
                                type="text"
                                className="form-control"
                                id="individualLastInKhmer"
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputEmail">Nationality</label>
                        <input
                            required={true}
                            onChange={this.onChange}
                            value={this.state.individualNationality}
                            type="text"
                            className="form-control"
                            id="individualNationality"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputEmail">Date of Birth</label>
                        <DateTime
                            onChange={this.handleChange}
                            selected = {this.state.individualDateOfBirth}
                            timeFormat={false}
                            inputProps={{ placeholder: "10/01/2019" }}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputAddress">Identification Number</label>
                        <input
                            required={true}
                            onChange={this.onChange}
                            value={this.state.individualIdentificationNumber}
                            type="text"
                            className="form-control"
                            id="individualIdentificationNumber"
                        />
                    </div>
                    <div className="form-group">
                        <ImageUploader
                            singleImage={true}
                            withPreview={true}
                            withIcon={true}
                            buttonText='Choose Passport'
                            onChange={this.onDropPassport}
                            imgExtension={['.jpg', '.gif', '.png', '.gif']}
                            maxFileSize={5242880}
                        />

                    </div>
                    <div className="form-group">
                        <ImageUploader
                            singleImage={true}
                            withPreview={true}
                            withIcon={true}
                            buttonText='Choose Photo'
                            onChange={this.onDropPhoto}
                            imgExtension={['.jpg', '.gif', '.png', '.gif']}
                            maxFileSize={5242880}
                        />
                        {/*<i> {photo}</i>*/}

                    </div>
                    <div className="form-group">
                        <label htmlFor="inputAddress">Current Address</label>
                        <input
                            required={true}
                            onChange={this.onChange}
                            value={this.state.individualAddress}
                            type="text"
                            className="form-control"
                            id="individualAddress"
                        />
                    </div>
                </div>

                <button type="submit" className="site-btn text-success">Create and Merge
                </button>
            </form>
        );
    }
}
export default CreateNewIndividualAndMerge;