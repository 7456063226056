import React from 'react';
import ReactDOM from 'react-dom';


import App from './app';
import * as serviceWorker from './serviceWorker';
import './index.css';
import './assets/css/cvstyle.css';
import 'react-table/react-table.css'
import 'react-viewer/dist/index.css';
import 'moment-timezone';



ReactDOM.render(
    <div>
        <App/>
    </div>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();