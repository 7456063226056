import React, {Component} from "react";
import axios from 'axios';
import Alert from "react-s-alert";
import ShowAlert from "../../../utils/ShowAlert";

class UpdateIndividualInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.user._id,
            name: props.user.name,
            name_in_khmer: props.user.name_in_khmer,
            email: props.user.email,
            organization: props.user.organization,
            position: props.user.position,
            identification_number: props.user.identification_number,
            current_address: props.user.current_address
        }
    }

    onSubmit = e => {
        e.preventDefault();
        const update = {
            name: this.state.name,
            name_in_khmer: this.state.name_in_khmer,
            organization: this.state.organization,
            position: this.state.position,
            identification_number: this.state.identification_number,
            current_address: this.state.current_address,
            reference_check_number : this.props.user.reference_check_number
        };
        axios.put('/api-individualMember/update/' + this.state.id,update).then(res => {
            ShowAlert(res.data);
            window.location.reload();
        }).catch(err => {
            ShowAlert(err.response.data);
            window.location.reload();
        })
    };
    onChange = e => {
        e.preventDefault();
        this.setState({[e.target.id]: e.target.value});

    };

    render() {
        console.log(this.state);
        return (
            <React.Fragment>
                <Alert stack={{limit: 3}}/>
                <form className="form " onSubmit={this.onSubmit}
                      encType="multipart/form-data" method="POST"
                >
                    <div className="text-left">
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Name </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    value={this.state.name}
                                    id="name"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Name In Khmer </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    value={this.state.name_in_khmer}
                                    id="name_in_khmer"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Email</p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">{this.state.email}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Organization: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    value={this.state.organization}
                                    id="organization"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Position: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    value={this.state.position}
                                    id="position"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Identification Number: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    value={this.state.identification_number}
                                    id="identification_number"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Current Address: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    value={this.state.current_address}
                                    id="current_address"
                                    required
                                />
                            </div>
                        </div>
                    </div>


                    <button type="submit" className="btn btn-lg btn-outline-success btn-round">Update Info
                    </button>
                </form>
            </React.Fragment>
        )
    }
}

export default UpdateIndividualInfo;