import React, {Component} from "react";
import DateTime from 'react-datetime';
import axios from "axios";


import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Row} from "reactstrap";
import RecentList from "./recentList";

import { Modal } from 'react-bootstrap';

import { FaSearch} from "react-icons/fa";
import ShowAlert from "../../../utils/ShowAlert";
import {MdFindInPage} from "react-icons/all";


const iconSize = "0.8rem";
function SearchNotFound(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                   <MdFindInPage/> Search Result Not Found
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Your input doesn't match any of our records
                </p>

            </Modal.Body>
            <Modal.Footer>
                <button className="site-btn text-danger" onClick={props.onHide}>Close</button>
            </Modal.Footer>
        </Modal>
    );
}


class WelcomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            individualName: "",
            individualIdentificationNumber: "",
            individualNationality: "",
            individualDateOfBirth: "",
            institutionName: "",
            institutionTaxIdentificationNumber: "",
            institutionType: "",
            institutionSector: "",
            institutionRegisteredNumber: "",
            errors: {},
            admin: '',
            show:false
        };
    }

    handleChange = date => {
        this.setState({
            individualDateOfBirth: date
        });
    };


    onChange = e => {
        this.setState({[e.target.id]: e.target.value});

    };

    onIndividualSubmit = e => {
        e.preventDefault();

        const data = {
            name: this.state.individualName,
            identification_number: this.state.individualIdentificationNumber,
            nationality: this.state.individualNationality,
            date_of_birth: this.state.individualDateOfBirth,
            checker: this.props.auth.user.id
        };

        axios.post("/api-individualProfile/check", data).then(res => {
            //console.log(res.data);
            this.props.history.push(`/individualEmployee/${res.data.individual._id}/` + res.data.date_check);

        }).catch(err => {
            //console.log(err.response);
            if(err.response.status === 400){
                ShowAlert(err.response.data);
            }else{
                window.open('http://google.com/search?q='+data.name);
                this.setState({
                    show : true
                });
            }

            //this.props.history.push("/searchNotFound");
        });


    };
    onInstitutionSubmit = e => {
        e.preventDefault();
        const data = {
            name: this.state.institutionName,
            institution_type: this.state.institutionType,
            sector: this.state.institutionSector,
            register_number: this.state.institutionRegisteredNumber,
            tax_identification_number: this.state.institutionTaxIdentificationNumber,
            checker: this.props.auth.user.id
        };
        axios.post("/api-institutionProfile/check/", data).then(res => {
            this.props.history.push(`/institution/${res.data.institution._id}/` + res.data.date_check);
        }).catch(err => {
            if(err.response.status === 400){
                ShowAlert(err.response.data);
            }else{
                window.open('http://google.com/search?q='+data.name);
                this.setState({
                    show : true
                });
            }
        });


    };
    handleClose = () =>{
        this.setState({
            show : false
        });
    };

    render() {


        const {errors} = this.state;
        //console.log(this.props);
        let notification;
        if (this.props.count === 0) {
            notification = <span className="badge badge-danger">{this.props.count}</span>;
        } else {
            notification = <span className="badge badge-info">{this.props.count}</span>;
        }

        if (this.props.auth.user.type === "AdminUser") {
            notification = <>
                <span className="badge badge-info"> Unlimited! </span>
                <span className="badge badge-danger">  Only For Admin User</span>
            </>

        }

        console.log(this.state);
        return (
            <div>
                <SearchNotFound show={this.state.show} onHide={this.handleClose}/>
                <div className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 ml-auto mr-auto text-center">
                                <Row className="justify-content-md-center">
                                    <div className="historyListTitle">
                                        <h2><FaSearch size="3rem"/> Inklusivity : Reference Check</h2>
                                    </div>
                                    <h5 className="description">
                                        Reference check let you inquire employment records from certain individual or
                                        institution.
                                    </h5>
                                </Row>
                                <h3 className="title">Please insert relevant information</h3>
                                <h4>Your check counts left: {notification} </h4>
                                <div className="card">
                                    <div className="card-header">
                                        <ul className="nav nav-tabs justify-content-center" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#individual"
                                                   role="tab">
                                                    <i className="now-ui-icons users_single-02"></i>
                                                    Individual Profile
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#institution"
                                                   role="tab">
                                                    <i className="now-ui-icons business_bank"></i>
                                                    Institution Profile
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="card-body">

                                        <div className="tab-content text-center">
                                            <div className="tab-pane active" id="individual" role="tabpanel">
                                                <form className="form" onSubmit={this.onIndividualSubmit}>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputEmail4">Name</label>
                                                            <input
                                                                required={true}
                                                                onChange={this.onChange}
                                                                value={this.state.individualName}
                                                                error={errors.individualName}
                                                                type="text"
                                                                className="form-control"
                                                                id="individualName"
                                                                placeholder="First Name... Last Name"/>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="individualIdentificationNumber">Identification
                                                                Number</label>
                                                            <input
                                                                required={true}
                                                                onChange={this.onChange}
                                                                value={this.state.individualIdentificationNumber}
                                                                error={errors.individualIdentificationNumber}
                                                                type="text"
                                                                className="form-control"
                                                                id="individualIdentificationNumber"
                                                                placeholder="Identification Number..."/>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="individualNationality">Nationality</label>
                                                            <input
                                                                required={true}
                                                                onChange={this.onChange}
                                                                value={this.state.individualNationality}
                                                                error={errors.individualNationality}
                                                                type="text"
                                                                className="form-control"
                                                                id="individualNationality"
                                                                placeholder="Nationality..."/>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputPassword4">Date of Birth</label>
                                                            <DateTime
                                                                required={true}
                                                                onChange={this.handleChange}
                                                                selected={this.state.individualDateOfBirth}
                                                                error={errors.individualDateOfBirth}
                                                                timeFormat={false}
                                                                inputProps={{placeholder: "10/01/2019"}}
                                                            />
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="btn btn-info"><FaSearch size={iconSize}/> Check</button>
                                                </form>
                                            </div>
                                            <div className="tab-pane" id="institution" role="tabpanel">
                                                <form className="form" onSubmit={this.onInstitutionSubmit}>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputEmail4">Name</label>
                                                            <input
                                                                required={true}
                                                                onChange={this.onChange}
                                                                value={this.state.institutionName}
                                                                error={errors.institutionName}
                                                                type="text"
                                                                className="form-control"
                                                                id="institutionName"
                                                                placeholder="Name..."/>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputPassword4">Institution Type</label>
                                                            <select
                                                                onChange={this.onChange}
                                                                required={true}
                                                                //error={errors.institutionType}
                                                                id="institutionType"
                                                                className="form-control"
                                                                value={this.state.institutionType}
                                                                //type="text"
                                                                //placeholder="Public...?"
                                                            >
                                                                <option selected>Choose one</option>
                                                                {this.props.institutionType.map((data,k)=>
                                                                    <option value={data}>{data}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputEmail4">Sector</label>
                                                            <select
                                                                onChange={this.onChange}
                                                                required={true}
                                                                //error={errors.institutionSector}
                                                                value={this.state.institutionSector}
                                                                id="institutionSector"
                                                                //type="text"
                                                                className="form-control"
                                                                //placeholder="Education...?"
                                                            >
                                                                <option selected>Choose one</option>
                                                                {this.props.institutionSector.map((data,k)=>
                                                                    <option value={data}>{data}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputPassword4">Registered Number</label>
                                                            <input
                                                                required={true}
                                                                onChange={this.onChange}
                                                                value={this.state.institutionRegisteredNumber}
                                                                error={errors.institutionRegisteredNumber}
                                                                type="text"
                                                                className="form-control"
                                                                id="institutionRegisteredNumber"
                                                                placeholder="Registered Number..."/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="inputAddress">Tax Identification Number</label>
                                                        <input
                                                            required={true}
                                                            onChange={this.onChange}
                                                            value={this.state.institutionTaxIdentificationNumber}
                                                            error={errors.institutionTaxIdentificationNumber}
                                                            type="text"
                                                            className="form-control"
                                                            id="institutionTaxIdentificationNumber"
                                                            placeholder="l123-123456789"/>
                                                    </div>
                                                    <button type="submit" className="btn btn-info"><FaSearch size={iconSize}/>Check</button>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 ml-auto mr-auto text-center">
                                <div className="card">
                                    <RecentList
                                        user={this.props.auth.user.id}
                                        IndividualProfiles = {this.props.IndividualProfiles}
                                        InstitutionProfiles = {this.props.InstitutionProfiles}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

WelcomePage.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(WelcomePage));