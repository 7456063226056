import React from "react";
import {Modal} from 'react-bootstrap';
import {MdBrokenImage} from "react-icons/all";

function  ErrorPopUp (props) {
    return (
        <Modal
            size="lg" centered
            {...props}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h4 className="font-weight-bold text-danger"><MdBrokenImage/> {props.title}</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{props.errorMsg}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <button className="site-btn text-danger" onClick={props.onHide}>Close</button>
            </Modal.Footer>
        </Modal>
    )

}
export default ErrorPopUp;