import React, {Component} from "react";
import axios from 'axios';
import ShowAlert from "../../../../utils/ShowAlert";
import Moment from "react-moment";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import MainNavBar from "../../../../components/Navbars/MainNavBar";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import PropTypes from "prop-types";

import {FaEdit,FaRegStopCircle,FaStreetView,FaTrashRestoreAlt} from "react-icons/fa";
import UpdateAdminInfo from "./UpdateAdminInfo";
import ActivityList from "./ActivityList";
import  './style.css';
import {trackPromise} from "react-promise-tracker";
const iconSize = "1.0rem";

class AdminView extends Component{
    constructor(props) {
        super(props);
        this.state = {
            admin : {},
            photo :null,
            passport:null,
            visible : false,
            loading : true
        };
    }
    componentDidMount() {
        if(this.props.match.params.id === this.props.auth.user.id){
            window.location.href = "/user";
        }
        if(this.props.auth.user.permission !== "System Admin"){
            window.location.href = "/user";
        }
        trackPromise(
            axios.all([
                axios.get('/api-users/getUser/' + this.props.match.params.id),
                axios.get('/api-counts/getPhoto/' + this.props.match.params.id),
                axios.get('/api-counts/getPassport/' + this.props.match.params.id)
            ]).then( axios.spread( (res1,res2,res3) =>{
                this.setState({
                    admin : res1.data,
                    photo : res2.data,
                    passport : res3.data,
                    loading : false
                })
            }))
        ).catch(err =>{
            ShowAlert(err.response.data);
        })

    }
    onChange = e => {
        e.preventDefault();
        this.setState({[e.target.id]: e.target.value});
    };
    onDeactivateAccount = e =>{
        e.preventDefault();
        axios.post("/api-users/deactivateUser/" + this.props.match.params.id ).then( result =>{
            if(result.status === 200){
                window.location.reload();
            }
        }).catch(err =>{
            console.log(err.response);
        })
    };
    onReactivateAccount = e =>{
        e.preventDefault();
        axios.post("/api-users/reactivateUser/" + this.props.match.params.id ).then( result =>{
            if(result.status === 200){
                window.location.reload();
            }
        }).catch(err =>{
            console.log(err.response);
        })
    };



    render() {
        let DeleteorNot;
        let EditOrNot;
        let EditPanel;
        let memberActivities;
        let memberActivitiesPanel;

        let EditPermissionorNot = this.props.auth.user.permission === "System Admin";

        if(this.props.auth.user.permissions_list.DeleteData.includes("Member")){
            if(this.state.admin.isDeactivated){
                DeleteorNot = <
                    a href="#"
                      className="site-btn text-success"
                      onClick={this.onReactivateAccount}
                > <FaTrashRestoreAlt/>Reactivate Account</a>;
            }else{
                DeleteorNot = <
                    a href="#"
                      className="site-btn text-danger"
                      onClick={this.onDeactivateAccount}
                > <FaRegStopCircle/>Deactivate Account</a>;
            }
        }
        if(this.props.auth.user.permissions_list.EditData.includes("Member")){


            EditOrNot = <li className="nav-item"><a href="#editProfile"
                                                    className="nav-link  site-btn text-danger editInfo"
                                                    data-toggle="collapse"
                                                    aria-expanded="false"
                                                    aria-controls="editProfile"
            >
                <FaEdit size={iconSize}/>
                Edit
            </a></li>;

            EditPanel = <div className="collapse" id="editProfile">

                <div className="tab-content text-center">
                    <div
                        className="card"
                    >
                        <div className="card-header">

                                <h3 className="title font-weight-bold">Update Admin Info</h3>


                        </div>

                        <div className="card-body">
                            <UpdateAdminInfo user={this.state.admin} system={EditPermissionorNot} id={this.props.auth.user.id}/>


                        </div>
                    </div>
                </div>

            </div>;
        }
        if(this.props.auth.user.permissions_list.ViewData.includes("MembersActivities")){
            memberActivities = <li className="nav-item"><a href="#viewProfile"
                                                    className="nav-link  site-btn text-info editInfo"
                                                    data-toggle="collapse"
                                                    aria-expanded="false"
                                                    aria-controls="viewProfile"
            >
                <FaStreetView size={iconSize}/>
                View Activity
            </a></li>;
            memberActivitiesPanel = <div className="collapse" id="viewProfile">

                <div className="tab-content text-center">
                    <div className="card ">
                        <div className="card-header">

                            <h3 className="title font-weight-bold">Admin Activities Info</h3>


                        </div>

                        <div className="card-body">
                            <ActivityList user={this.props.match.params.id} />


                        </div>
                    </div>
                </div>

            </div>;
        }

        return(
            <div>
                <MainNavBar SignIn ={false} SignUp={false} dashboard = {false} socialMedia={false}/>
                {this.state.loading? <LoadingScreen/> :<React.Fragment>
                    <header className="header-section">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col text-md-right header-buttons">
                                    <ul className="nav justify-content-end">
                                        {EditOrNot}
                                        {DeleteorNot}
                                        {memberActivities}
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </header>


                    <section className="hero-section spad">
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-xl-10 offset-xl-1">
                                    <Breadcrumb>
                                        <BreadcrumbItem ><a href="/">Home</a></BreadcrumbItem>
                                        <BreadcrumbItem ><a href="/dashboard">Dashboard</a></BreadcrumbItem>
                                        <BreadcrumbItem ><a href="/adminsList">Admins List</a></BreadcrumbItem>
                                        <BreadcrumbItem active>Admin User View</BreadcrumbItem>
                                    </Breadcrumb>
                                    {memberActivitiesPanel}
                                    {EditPanel}
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="hero-text">
                                                <h3 className={this.state.admin.isDeactivated?"display-4 deleted":"display-4"}> {this.state.admin.name}</h3>
                                                <h3 className="title"> {this.state.admin.permission_classification}</h3>
                                            </div>
                                            <div className="hero-info">
                                                <h2>General Info</h2>
                                                <div className="row">
                                                    <div className="col">
                                                        <p className="font-weight-bold">ID Number:</p>
                                                    </div>
                                                    <div className="col">
                                                        <p>{this.state.admin.identification_number}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <p className="font-weight-bold">Email:</p>
                                                    </div>
                                                    <div className="col">
                                                        <p>{this.state.admin.email}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <p className="font-weight-bold">Date Created:</p>
                                                    </div>
                                                    <div className="col">
                                                        <p><Moment format="DD/MM/YYYY">{this.state.admin.createdAt}</Moment></p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <p className="font-weight-bold">Current Address: </p>
                                                    </div>
                                                    <div className="col">
                                                        <p>{this.state.admin.current_address}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <p className="font-weight-bold">Status: </p>
                                                    </div>
                                                    <div className="col">
                                                        <p className={this.state.admin.isDeactivated?"text-danger" :this.state.admin.isVerified?"text-info":"text-warning"}>{this.state.admin.isDeactivated? "Deactivated" :this.state.admin.isVerified? "Email Verified" : "Email Not Verified"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <figure className="hero-image">
                                                <img src={this.state.photo}  width="350" alt="5"/>
                                                <img src={this.state.passport} width="350" alt="5"/>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </React.Fragment> }
            </div>

        )
    }
}
AdminView.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps
)(withRouter(AdminView));