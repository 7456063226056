import React, {Component} from "react";
import MainNavBar from "../../../components/Navbars/MainNavBar";
import {Col, Container, Form, Input, Row} from "reactstrap";
import axios from "axios";

import Alert from 'react-s-alert';



class VerificationPage extends Component{
    constructor(props){
        super(props);
        this.state = {
            token : "",
            email :null

        };
    }
    componentDidMount() {
        axios.get("/api-users/getUser/"+this.props.match.params.id).then( user => {
            if(user.data.isVerified){
                this.props.history.push('/sign-in');
            }
            this.setState({
                email : user.data.email
            })
        }).catch( err => {
            console.log(err);
        })
    }

    onResend = () =>{
        const data = {
            email : this.state.email
        };
        axios.post("/api-users/resend",data).then( res =>{
            Alert.info(res.data, {
                position: 'bottom-left',
                effect: 'bouncyflip',
                timeout: 'none'
            });
        }).catch(err =>{
            console.log(err);
        })
    };
    onVerify = e =>{
        e.preventDefault();
        const data = {
            token : this.state.token,
            email : this.state.email
        };
        axios
            .post("/api-users/verify", data)
            .then(res => {
                console.log(res.data);
                if(res.status === 200){
                    this.props.history.push('/sign-in');
                }
            })
            .catch(err =>
                console.log(err)
            );
    };
    onChange = e => {
        this.setState({[e.target.id]: e.target.value});

    };
    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
            this.alertError();
        }

    }
    render() {
        const {errors} = this.state;
        return(
            <React.Fragment>
                <Alert stack={{limit: 3}}/>
                <MainNavBar SignUp={false} SignIn={false}/>
                <div className="section">
                    <Container className="text-center">
                        <Row className="justify-content-md-center">
                            <Col lg="8" md="12">
                                <h2 className="title">A token was sent to your email : {this.state.email}</h2>
                                <h5 className="description">
                                    The kit comes with three pre-built pages to help you get started
                                    faster. You can change the text and images and you're good to
                                    go. More importantly, looking at them will give you a picture of
                                    what you can built with this powerful Bootstrap 4 ui kit.
                                </h5>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="section">
                    <Row className="justify-content-md-center">
                        <Col lg="5" md="8">
                            <div className="card text-center">
                                <div className="card-header mt-2">
                                    A verification token has been sent to your email
                                </div>
                                <div className="card-body">
                                    {/*<h4 className="card-title">kimangkhenag@gmail.com</h4>*/}
                                    <form className="form" noValidate onSubmit={this.onVerify}>
                                        <div className="form-group">

                                            <input
                                                onChange={this.onChange}
                                                value={this.state.token}

                                                type="text"
                                                className="form-control"
                                                id="token" />
                                        </div>
                                        <button type="submit" className="btn btn-success">Verify</button>
                                    </form>
                                    <button  className="btn btn-danger" onClick={this.onResend}>Resend</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>

        )
    }
}


export default VerificationPage;