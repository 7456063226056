import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Utils
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import { setCurrentUser, logoutUser } from "./actions/authActions";

// styles for this kit
import "assets/css/now-ui-kit.css";
import Alert from 'react-s-alert';
// mandatory
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/scale.css';
import 'moment-timezone';

// pages for this kit
import LoginPage from "./views/layout/LoginPage";
import LandingPage from "./views/layout/LandingPage.js";
import PrivateRoute from "./components/private-route/PrivateRoute.js";
import OnlyForAdminRoute from './components/OnlyForAdminRoute';
import NotFound from "./components/404/404";
import SignUpPage from "./views/layout/SignUpPage";
import VerificationPage from "./views/layout/VerificationPage/VerificationPage";
import ForgotPassword from "./views/layout/ForgotPassword/ForgotPassword";
import ChangePassword from "./views/layout/ForgotPassword/ChangePassword";
import InstitutionPage from "./views/layout/EmployeePage/InstitutionPage";
import DarkFooter from "./components/Footers/DarkFooter";
import InsertUsers from "./views/layout/Admin/InsertUsers/InsertUsers";
import IndividualEmplyoeePage from "./views/layout/EmployeePage/IndividualEmplyoeePage";
import AdminView from "./views/layout/Admin/AdminsList/AdminView";
import User from "./views/layout/Dashboard/User/User";
import DashboardSwitch from "./views/layout/Dashboard/DashboardSwitch";
import InsertRecordsValidator from "./views/layout/Admin/InsertRecords/InsertRecordsValidator";
import RecordsListValidator from "./views/layout/Admin/RecordsList/RecordsListValidator";
import AdminsListValidator from "./views/layout/Admin/AdminsList/AdminsListValidator";
import UsersListValidator from "./views/layout/Admin/UsersList/UsersListValidator";
import MergeDataValidator from "./views/layout/Admin/MergeData/MergeDataValidator";
import SearchSwitch from "./views/layout/WelcomePage/SearchSwitch";
import IndividualViewValidator from "./views/layout/Admin/UsersList/IndividualViewValidator";
import OrganizationUserViewValidator from "./views/layout/Admin/UsersList/OrganizationUserViewValidator";
import InsertHistoriesValidator from "./views/layout/Admin/InsertHistories/InsertHistoriesValidator";
import NotActivated from "./components/404/NotActivated";
import HistoriesListValidator from "./views/layout/Admin/HistoriesList/HistoriesListValidator";
import MiscellaneousValidator from "./views/layout/Admin/Miscellaneous/MiscellaneousValidator";

// Check for token to keep user logged in
if (localStorage.jwtTokenTeams) {
    // Set auth token header auth
    const token = JSON.parse(localStorage.jwtTokenTeams);
    setAuthToken(token);

    // Decode token and get user info and exp
    const decoded = jwt_decode(token);

    // Set user and isAuthenticated
    store.dispatch(setCurrentUser(decoded));

    // Check for expired token
    const currentTime = Date.now() / 1000; // to get in milliseconds
    if (decoded.exp < currentTime) {
        // Logout user
        store.dispatch(logoutUser());

        // Redirect to login
        window.location.href = "/sign-in";
    }
}

class App extends Component{
    componentDidMount() {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }

    componentDidUpdate() {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }
    render() {
        return(
            <Provider store={store}>
                <Router>
                    <div className="App">
                        <Switch>
                            <Route exact  path="/" component ={LandingPage}  />
                            <Route exact  path="/sign-in" component = {LoginPage} />
                            <Route exact  path="/sign-up" component = {SignUpPage} />
                            <Route exact  path="/forgot-password" component = {ForgotPassword} />
                            <Route exact  path="/change-password/:id" component = {ChangePassword} />
                            <Route exact  path= "/verification/:id" component={VerificationPage}/>
                            <Route exact  path= "/deactivated" component={NotActivated}/>

                            <PrivateRoute exact  path= "/individualEmployee/:id/:date" component={IndividualEmplyoeePage}/>
                            <PrivateRoute exact  path= "/institution/:id/:date" component={InstitutionPage}/>
                            <PrivateRoute exact  path="/welcome" component = {SearchSwitch} />
                            <PrivateRoute exact  path="/dashboard" component = {DashboardSwitch} />
                            <PrivateRoute exact  path="/user" component = {User} />

                            <OnlyForAdminRoute exact  path="/individualUser/:id" component = {IndividualViewValidator} />
                            <OnlyForAdminRoute exact  path="/organizationUser/:id" component = {OrganizationUserViewValidator} />
                            <OnlyForAdminRoute exact path="/insertUsers" component ={InsertUsers}/>
                            <OnlyForAdminRoute exact path="/usersList" component ={UsersListValidator}/>
                            <OnlyForAdminRoute exact path="/recordsList" component ={RecordsListValidator}/>
                            <OnlyForAdminRoute exact path="/insertRecords" component ={InsertRecordsValidator}/>
                            <OnlyForAdminRoute exact path="/insertHistories" component ={InsertHistoriesValidator}/>
                            <OnlyForAdminRoute exact path="/historiesList" component ={HistoriesListValidator}/>
                            <OnlyForAdminRoute exact path="/adminUser/:id" component ={AdminView}/>
                            <OnlyForAdminRoute exact path="/adminsList" component ={AdminsListValidator}/>
                            <OnlyForAdminRoute exact path="/mergeData" component ={MergeDataValidator}/>
                            <OnlyForAdminRoute exact path="/miscellaneous" component ={MiscellaneousValidator}/>
                            {/*<OnlyForAdminRoute exact path="/mergeUndecidedIndividual/:id"*/}
                            {/*                  component ={ConflictedIndividualHistory}/>*/}
                            {/*<OnlyForAdminRoute exact path="/mergeUndecidedInstitution/:id"*/}
                            {/*                  component ={ConflictedInstitutionHistory}/>*/}

                            {/*<OnlyForAdminRoute exact path="/mergeNotFoundIndividual/:id"*/}
                            {/*                  component ={NotFoundIndividualHistory}/>*/}
                            {/*<OnlyForAdminRoute exact path="/mergeNotFoundInstitution/:id"*/}
                            {/*                  component ={NotFoundInstitutionHistory}/>*/}
                            <Route
                                component={localStorage.jwtTokenTeams ? SearchSwitch : NotFound}
                            />
                        </Switch>
                        <DarkFooter/>
                    </div>
                    <Alert stack={{limit: 3}}/>
                </Router>
            </Provider>
        );
    }


}

export default App;

