import React, {Component} from "react";
import axios from 'axios';
import Alert from "react-s-alert";
import ImageUploader from "react-images-upload";

class UpdateIndividual extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            name_in_khmer: '',
            email: '',
            organization: '',
            position: '',
            identification_number: '',
            current_address: '',
            number : '',
            photo:[],
            passport:[],
            trustLevel :''
        }
    }

    onSubmit = e => {
        e.preventDefault();
        const update = new FormData();
        let updatePassportOrNot;
        let updatePhotoOrNot;
        if(this.state.photo[0]){
            updatePhotoOrNot = "Update";
        }
        if(this.state.passport[0]){
            updatePassportOrNot = "Update";
        }
        update.append("myFiles",this.state.photo[0]);
        update.append("myFiles",this.state.passport[0]);
        update.append("name",this.state.name ? this.state.name : this.props.user.name);
        update.append("name_in_khmer",this.state.name_in_khmer ? this.state.name_in_khmer : this.props.user.name_in_khmer);
        update.append("date_of_birth",this.state.date_of_birth ? this.state.date_of_birth :this.props.user.date_of_birth);
        update.append("identification_number",this.state.identification_number ? this.state.identification_number : this.props.user.identification_number);
        update.append("current_address",this.state.current_address?this.state.current_address : this.props.user.current_address);
        update.append("organization",this.state.organization?this.state.organization : this.props.user.organization);
        update.append("position",this.state.position?this.state.position : this.props.user.position);
        update.append("reference_check_number",this.state.number?this.state.number : this.props.user.reference_check_number);
        update.append("trustLevel",this.state.trustLevel?this.state.trustLevel:this.props.user.trustLevel);
        update.append("photo",updatePhotoOrNot);
        update.append("passport",updatePassportOrNot);
        axios.put('/api-individualMember/update/' + this.props.user._id,update).then(res => {
            window.location.reload();
        }).catch(err => {
            window.location.reload();
        })
    };
    onChange = e => {
        e.preventDefault();
        this.setState({[e.target.id]: e.target.value});
    };
    onDropPassport = file => {
        this.setState({
            passport: this.state.passport.concat(file)
        });
    };

    onDropPhoto = file => {
        this.setState({
            photo: this.state.photo.concat(file)
        });
    };

    render() {
        let referenceCheck;
        if(this.props.referenceNum === true){
            referenceCheck = <div className="row">
                <div className="col-sm-4 ml-auto mr-auto">
                    <p className="font-weight-bold">Reference Check Number: </p>
                </div>
                <div className="col-sm-4 ml-auto mr-auto">
                    <input
                        onChange={this.onChange}
                        className="form-control"
                        defaultValue={this.props.user.reference_check_number}
                        id="number"
                        required
                    />
                </div>
            </div>;
        }else{
            referenceCheck = <span/>
        }
        let trustLevel;
        if(this.props.trust){
            trustLevel= <div className="row">
                <div className="col-sm-4 ml-auto mr-auto">
                    <p className="font-weight-bold">Trust Level: </p>
                </div>
                <div className="col-sm-4 ml-auto mr-auto">
                    <select
                        onChange={this.onChange}
                        className="form-control"

                        id="trustLevel"
                        required
                    >
                        <option selected={this.props.user.trustLevel === 0} value={0}>0%</option>
                        <option selected={this.props.user.trustLevel === 30} value={30}>30%</option>
                        <option selected={this.props.user.trustLevel === 50} value={50}>50%</option>
                        <option selected={this.props.user.trustLevel === 75} value={75}>75%</option>
                        <option selected={this.props.user.trustLevel === 100} value={100}>100%</option>

                    </select>
                </div>
            </div>
        }
        return (
            <React.Fragment>
                <Alert stack={{limit: 3}}/>
                <form className="form " onSubmit={this.onSubmit}
                      encType="multipart/form-data" method="POST"
                >
                    <div className="text-left">
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Name </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    id="name"
                                    required
                                    defaultValue={this.props.user.name}

                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Name In Khmer </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.user.name_in_khmer}
                                    id="name_in_khmer"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Email</p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">{this.props.user.email}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Organization: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.user.organization}
                                    id="organization"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Position: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.user.position}
                                    id="position"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Identification Number: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.user.identification_number}
                                    id="identification_number"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 ml-auto mr-auto">
                                <p className="font-weight-bold">Current Address: </p>
                            </div>
                            <div className="col-sm-4 ml-auto mr-auto">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.user.current_address}
                                    id="current_address"
                                    required
                                />
                            </div>
                        </div>
                        {trustLevel}
                        {referenceCheck}
                        <ImageUploader
                            singleImage={true}
                            withPreview={true}
                            withIcon={true}
                            buttonText='Update Photo'
                            onChange={this.onDropPhoto}
                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                            maxFileSize={5242880}
                        />
                        <ImageUploader
                            singleImage={true}
                            withPreview={true}
                            withIcon={true}
                            buttonText='Update Passport'
                            onChange={this.onDropPassport}
                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                            maxFileSize={5242880}
                        />
                    </div>


                    <button type="submit" className="site-btn text-success">Update Info</button>
                </form>
            </React.Fragment>
        )
    }
}

export default UpdateIndividual;