import React, {Component} from "react";
import axios from "axios";
import Moment from "react-moment";
import Viewer from "react-viewer";
import {trackPromise} from "react-promise-tracker";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import EditInstitutionHistory from "../RecordsList/EditInstitutionHistory";
import {MdHistory} from "react-icons/all";

class MiniInstitutionHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            doc: null,
            visible: false,
            loading : true
        }
    }


    componentDidMount() {
        trackPromise(
            axios.get("/api-institutionProfile/getEvidence/" + this.props.history._id).then(response =>{
                this.setState({
                    doc : response.data,
                    loading : false
                })
            })
        ).catch(err =>{
            console.log(err);
        })

    }

    render() {

        let status;
        let edit;
        let editPanel;
        let additionalInfo;

        if (this.props.history.info_type === true) {
            status = <span className="badge badge-success">Positive</span>;
        } else {
            status = <span className="badge badge-danger ">Negative</span>;
        }

        let dynamicHref = "editHistory" + this.props.history._id;
        edit = <a href={`#${dynamicHref}`}
                  className="btn-outline-warning border-dark"
                  data-toggle="collapse"
                  aria-expanded="false"
                  aria-controls={dynamicHref}>
            <MdHistory size="1rem"/>
            Edit History
        </a>;



        let color;
        let statusColor;

        statusColor = "badge badge-warning";


        if (this.props.history.access_classification === "Not-Accessible") {
            color = "badge badge-danger";
        }
        if (this.props.history.access_classification === "Confidential" || this.props.history.access_classification === "Strictly-Confidential") {
            color = "badge badge-warning";
        }
        if (this.props.history.access_classification === "Public") {
            color = "badge badge-primary";
        }
        additionalInfo =
            <div className="text-center">
                {status}
                <span className={color}> {this.props.history.access_classification}</span>
                <span className={statusColor}> {this.props.history.status}</span>
                <p>Created At :
                    <Moment format="DD/MM/YYYY">{this.props.history.publishDate}</Moment></p>
            </div>;
        let dataContributorOrNot;
        let statusOrNot;
        if(this.props.permission === "System Admin"){
            statusOrNot = true;
            dataContributorOrNot =  <div>
                <br/>
                <h3>Data Contributors</h3>
                {
                    this.props.history.data_contributor.map((data, k) =>
                        <>

                            <div className="row">
                                <div className="col">
                                    <p className="font-weight-bold">Name: </p>
                                    <a
                                        href={
                                            data.type ==="AdminUser"?"/adminUser/"+ data._id
                                                :data.type ==="IndividualMember"?"/individualUser/"+ data._id:
                                                data.type ==="OrganizationMember"?"/organizationUser/"+ data._id:""}><p>{data.name}</p></a>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col">
                                    <p className="font-weight-bold">Email: </p>
                                    <p>{data.email}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {data.type === "AdminUser"? <div>
                                        <p className="font-weight-bold">Permission: </p>
                                        <p>{data.permission_classification}</p>
                                    </div> : <div>
                                        <p className="font-weight-bold">User Type: </p>
                                        <p>{data.type}</p>
                                    </div>}

                                </div>
                            </div>
                            <br/>

                        </>
                    )}
            </div>;
        }
        editPanel = <div className="collapse" id={dynamicHref}>
            <div className="card">
                <div className="card-body text-center">
                    <EditInstitutionHistory
                        history={this.props.history}
                        user={this.props.user}
                        status={statusOrNot}
                    />
                </div>
            </div>
        </div>;
        //console.log(this.props.history);
        return (

            <React.Fragment>
                {this.state.loading? <LoadingScreen/> :<div className="section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 ml-auto mr-auto">

                                <div className="card">

                                    <div className="card-body">
                                        <ul className="nav justify-content-end">
                                            {edit}
                                        </ul>
                                        <div className="row">
                                            <div className="col text-center">
                                                {(this.props.history.status !== "Undecided")&&(this.props.history.status !== "Profile Not Found")?
                                                    <div>
                                                        <h2 className={this.props.history.status ==="Deleted"?"deleted":"historyListTitle"}>{this.props.history.profile.name_in_khmer}</h2>
                                                        <h3 className={this.props.history.status ==="Deleted"?"deleted":"historyListTitle"}>{this.props.history.profile.name}</h3>
                                                    </div>: <h3 className={this.props.history.status ==="Deleted"?"deleted":"historyListTitle"}>{this.props.history.additionalProfileInfo.name}</h3>
                                                }
                                                <h2 className={this.props.history.status ==="Deleted"?"deleted":"historyListTitle"}>{this.props.history.fromYear}-{this.props.history.toYear}</h2>
                                                <h3 className={this.props.history.status ==="Deleted"?"deleted":"historyListTitle"}>{this.props.history.fromMonth} - {this.props.history.toMonth}</h3>
                                                <h3 className={this.props.history.status ==="Deleted"?"deleted":"historyListTitle"}>{this.props.history.fromDay} - {this.props.history.toDay}</h3>
                                            </div>

                                        </div>


                                        {additionalInfo}
                                        {editPanel}
                                        <p>{this.props.history.summary}</p>
                                        <h4 className="font-weight-bold">References</h4>


                                        <p>{this.props.history.reference} </p>

                                        <h4 className="font-weight-bold">Evidence Document</h4>
                                        <img
                                            alt="Evident Document"
                                            src={this.state.doc}
                                            onClick={() => {
                                                this.setState({visible: !this.state.visible});
                                            }}
                                            width="150"
                                        />
                                        {dataContributorOrNot}
                                        <Viewer
                                            visible={this.state.visible}
                                            images={[
                                                {src: this.state.doc, alt: 'Evidence Document'}]}
                                            onClose={() => {
                                                this.setState({visible: false});
                                            }}

                                        />

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div> }


            </React.Fragment>
        )
    }
}

export default MiniInstitutionHistory;