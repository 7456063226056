import React, {Component} from "react";
import Alert from "react-s-alert";
import axios from 'axios';
import ReactTable from 'react-table';
import {MdCallMerge, MdPageview} from "react-icons/md";
import Moment from "react-moment";
import MiniIndividualHistory from "./MiniIndividualHistory";
import MiniInstitutionHistory from "./MiniInstitutionHistory";
import {Badge} from 'reactstrap';
import {
    OrganizationUndecidedColumns,
    IndividualUndecidedColumns,
    OrganizationNotFoundColumns,
    IndividualNotFoundColumns,
    IndividualNotFoundColumnsNoDelete,
    OrganizationNotFoundColumnsNoDelete,
    OrganizationUndecidedColumnsPopUp,
    IndividualUndecidedColumnsPopUp
} from './MergeUtilities';
import {
    AiOutlineUsergroupDelete,
    FaBuilding,
    FaFileImport,
    FaQuestionCircle, FaSearch,
    FaUsersCog,
    FaUserTimes
} from "react-icons/all";
import CreateNewIndividualAndMerge from "./CreateNewIndividualAndMerge";
import MergeWithExistingIndividualProfile from "./MergeWithExistingIndividualProfile";
import CreateNewInstitutionAndMerge from "./CreateNewInstitutionAndMerge";
import MergeWithExistingInstitutionProfile from "./MergeWithExistingInstitutionProfile";

class MergeData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentHistory : ''
        };
    }

    onDeleteHistory = e => {
        e.preventDefault();
        axios.post('/api-counts/delete_history/' + this.state.currentHistory._id + '/' + this.props.user).then(res => {
            window.location.reload();
        }).catch(err => {
            console.log(err.response);
        })
    };
    onRestoreHistory = e => {
        e.preventDefault();
        axios.post('/api-counts/restore_history/' + this.state.currentHistory._id + '/' + this.props.user).then(res => {
            window.location.reload();
        }).catch(err => {
            console.log(err.response);
        })
    };

    render() {


        //console.log(currentHistory);
        const Individualcolumns = [
            {
                Header: 'Action',
                columns: [
                    {
                        Header: "View Profile",
                        accessor: '',
                        Cell: props => (
                            <div>
                                <a href={"/individualEmployee/" + props.value._id + "/" + new Date()}
                                   target="_blank"><MdPageview/> View Profile </a>
                            </div>
                        )
                    },
                    {
                        Header: "Merge",
                        accessor: '',
                        Cell: props => (
                            <div>
                                <button
                                    className="btn-info"
                                    onClick={() => {
                                        //console.log(currentIndividual);
                                        axios.post('/api-individualProfile/mergeProfileWithHistory/' + props.value._id + '/' + this.state.currentHistory._id)
                                            .then(res => {
                                                window.location.href = '/individualEmployee/' + props.value._id + '/' + new Date();
                                            }).catch(err => {
                                            console.log(err);
                                        })
                                    }}
                                >
                                    <MdCallMerge/> Merge
                                </button>

                            </div>
                        )
                    }
                ],

            },
            {
                Header: 'ID',
                accessor: 'identification_number'
            }
            ,
            {
                Header: 'Name',
                accessor: 'name',
                Cell: row => (
                    <div style={{color: '#1abc9c'}}> {row.value}</div>
                )
            },
            {
                Header: 'Date of Birth',
                accessor: 'date_of_birth',
                Cell: row => (
                    <Moment format="DD/MM/YYYY">{row.value}</Moment>
                )
            },
            {
                Header: 'Nationality',
                accessor: 'nationality'
            }
        ];
        const institutioncolumns = [
            {
                Header: 'Action',
                columns: [
                    {
                        Header: "View Profile",
                        accessor: '',
                        Cell: props => (

                                <a href={"/institution/" + props.value._id + "/" + new Date()}
                                   target="_blank"><MdPageview/> View Profile </a>

                        )
                    },
                    {
                        Header: "Merge",
                        accessor: '',
                        Cell: props => (
                            <div>
                                <button
                                    className="btn-info"
                                    onClick={() => {
                                        //console.log(currentIndividual);
                                        axios.post('/api-institutionProfile/mergeProfileWithHistory/' + props.value._id + '/' + this.state.currentHistory._id)
                                            .then(res => {
                                                window.location.href = '/institution/' + props.value._id + '/' + new Date();
                                            }).catch(err => {
                                            console.log(err);
                                        })
                                    }}
                                >
                                    <MdCallMerge/> Merge
                                </button>

                            </div>
                        )
                    }
                ],

            },
            {
                Header: 'Registered Number',
                accessor: 'register_number'
            }
            ,
            {
                Header: 'Name',
                accessor: 'name',
                Cell: row => (
                    <div style={{color: '#1abc9c'}}> {row.value}</div>
                )
            },
            {
                Header: 'Tax Identification Number',
                accessor: 'tax_identification_number'
            }
        ];

        let DeleteConfirm =
            <div
                className="modal fade "
                id="deletehistory"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"

            >

                <div className="modal-dialog " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirm Delete History</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h3 className="font-weight-bold">Are you sure that you wish to delete this history?</h3>

                            Your action cannot be reverted!
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="site-btn text-black" data-dismiss="modal">Close</button>
                            <button type="button" className="site-btn text-danger" data-dismiss="modal"
                                    onClick={this.onDeleteHistory}>Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>;
        let RestoreConfirm = <div
            className="modal fade"
            id='restorehistory'
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true">

            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Restore Deleted History</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h3 className="font-weight-bold">Are you sure that you wish to restore this history?</h3>

                        Your action cannot be reverted!
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="site-btn text-black" data-dismiss="modal">Close</button>
                        <button type="button" className="site-btn text-success" data-dismiss="modal"
                                onClick={this.onRestoreHistory}>Restore
                        </button>
                    </div>
                </div>
            </div>
        </div>;
        let createNewIndividualAndMerge =
            <div
                className="modal fade "
                id="createnewindiviudal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"

            >

                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-success" id="exampleModalLabel"><FaFileImport/><br/>Create new Individual Profile and Merge</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <CreateNewIndividualAndMerge id={this.props.user} history={this.state.currentHistory}/>
                        </div>
                    </div>
                </div>
            </div>;
        let mergeWithExistingIndividual =
            <div
                className="modal fade "
                id="mergeWithExistingindiviudal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"

            >

                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-info" id="exampleModalLabel"><MdCallMerge/><br/>Merge with existing profile</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <MergeWithExistingIndividualProfile id={this.props.user} history={this.state.currentHistory}/>
                        </div>
                    </div>
                </div>
            </div>;
        let createNewInstitutionAndMerge =
            <div
                className="modal fade "
                id="createnewinstitution"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"

            >

                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-success" id="exampleModalLabel"><FaFileImport/><br/>Create new Individual Profile and Merge</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <CreateNewInstitutionAndMerge id={this.props.user} history={this.state.currentHistory}/>
                        </div>
                    </div>
                </div>
            </div>;
        let mergeWithExistingInstitution =
            <div
                className="modal fade "
                id="mergeWithExistinginstitution"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"

            >

                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-info" id="exampleModalLabel"><MdCallMerge/><br/>Merge with existing profile</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <MergeWithExistingInstitutionProfile id={this.props.user} history={this.state.currentHistory}/>
                        </div>
                    </div>
                </div>
            </div>;
        console.log(this.props);
        return (
            <React.Fragment>
                <Alert stack={{limit: 3}}/>
                {DeleteConfirm}
                {RestoreConfirm}
                {createNewIndividualAndMerge}
                {mergeWithExistingIndividual}
                {createNewInstitutionAndMerge}
                {mergeWithExistingInstitution}
                <div className="section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 ml-auto mr-auto text-center">
                                <div className="historyListTitle">
                                    <h2><FaSearch size="3rem"/> Inklusivity : Reference Check</h2>
                                </div>
                                <h3 className="title"> <FaQuestionCircle/> Undecided Data...</h3>
                                <div className="card">
                                    <div className="card-header">
                                        <ul className="nav nav-tabs justify-content-center" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#multipleuser"
                                                   role="tab">
                                                    <FaUsersCog size="1.5rem"/><br/>
                                                    Conflicted with multiple profiles <br/>
                                                    <Badge
                                                        color="warning">{this.props.IndividualUndecided.length + this.props.InstitutionUndecided.length}</Badge>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#notfound"
                                                   role="tab">
                                                    <FaUserTimes size="1.5rem"/><br/>
                                                    Profile not found in the system <br/>
                                                    <Badge
                                                        color="danger">{this.props.IndividualNotFound.length + this.props.InstitutionNotFound.length}</Badge>
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className="card-body">

                                        <div className="tab-content text-center">
                                            <div className="tab-pane active " id="multipleuser" role="tabpanel">
                                                <AiOutlineUsergroupDelete size="2rem"/>
                                                <h3 className="title">Conflicted Individual History</h3>
                                                <ReactTable
                                                    //manual
                                                    //filterable
                                                    data={this.props.IndividualUndecided}
                                                    //pages={individualTotalPages}
                                                    columns={this.props.deletePermission?IndividualUndecidedColumns:IndividualUndecidedColumnsPopUp}
                                                    defaultPageSize={5}
                                                    //loading={loadingIndividual}
                                                    //onFetchData={this.fetchIndividualUndecidedData} // Request new data when things change
                                                    //filterable
                                                    className="-striped -highlight"
                                                    getTrProps={(state, rowInfo, column, instance) => {
                                                        return {
                                                            onClick: (e) => {
                                                                this.setState({
                                                                    currentHistory : rowInfo.original
                                                                });
                                                                // currentHistory = rowInfo.original._id;
                                                                // currentHistoryData = rowInfo.original;
                                                            }
                                                        }
                                                    }}
                                                    SubComponent={row => {
                                                        return (
                                                            <div>
                                                                <div className="row">
                                                                    <div className="col text-center">
                                                                        <h4 className="font-weight-bold text-dark">Possible
                                                                            Candidates</h4>
                                                                        <ReactTable
                                                                            className="-highlight -striped"
                                                                            columns={Individualcolumns}
                                                                            data={row.original.possibleAssociation}
                                                                            defaultPageSize={3}
                                                                            showPagination={true}
                                                                        />
                                                                    </div>

                                                                </div>

                                                                {/*<div className="col text-center">*/}
                                                                {/*    <h3 className="font-weight-bold">Summary</h3>*/}
                                                                {/*    <h4 className="font-weight-bold">{row.original.fromYear}-{row.original.toYear}</h4>*/}
                                                                {/*</div>*/}
                                                                <MiniIndividualHistory
                                                                    history={row.original}
                                                                    user={this.props.user}
                                                                    permission = {this.props.permission_classification}
                                                                />

                                                                {/*<div>*/}
                                                                {/*    {row.original.work_summary}*/}
                                                                {/*</div>*/}

                                                            </div>
                                                        );
                                                    }}
                                                />
                                                <br/>
                                                <FaBuilding size="2rem"/>
                                                <h3 className="title">Conflicted Institution History</h3>
                                                <ReactTable
                                                    //manual
                                                    data={this.props.InstitutionUndecided}
                                                    columns={this.props.deletePermission?OrganizationUndecidedColumns:OrganizationUndecidedColumnsPopUp}
                                                    //filterable
                                                    //pages={organizationTotalPages}
                                                    defaultPageSize={5}
                                                    //loading={loadingOrganization}
                                                    //onFetchData={this.fetchOrganizationUndecidedData} // Request new data when things change
                                                    //filterable
                                                    className="-striped -highlight"
                                                    getTrProps={(state, rowInfo, column, instance) => {
                                                        return {
                                                            onClick: (e) => {

                                                                // console.log("A Td Element was clicked!");
                                                                // console.log("it produced this event:", e);
                                                                // console.log("It was in this column:", column);
                                                                // console.log("It was in this row:", rowInfo);
                                                                // console.log("It was in this table instance:", instance);
                                                                this.setState({
                                                                    currentHistory : rowInfo.original
                                                                });
                                                                // currentHistory = rowInfo.original._id;
                                                                // currentHistoryData = rowInfo.original;
                                                                //console.log(currentIndividual);

                                                            }
                                                        }
                                                    }}
                                                    SubComponent={row => {
                                                        return (
                                                            <div>
                                                                <div className="row">
                                                                    <div className="col text-center">
                                                                        <h4 className="font-weight-bold text-dark">Possible
                                                                            Candidates</h4>
                                                                        <ReactTable
                                                                            className="-highlight -striped"
                                                                            columns={institutioncolumns}
                                                                            data={row.original.possibleAssociation}
                                                                            defaultPageSize={3}
                                                                            showPagination={true}
                                                                        />
                                                                    </div>

                                                                </div>
                                                                {/*<div className="col text-center">*/}
                                                                {/*    <h3 className="font-weight-bold">Summary</h3>*/}
                                                                {/*    <h4 className="font-weight-bold">{row.original.fromYear}-{row.original.toYear}</h4>*/}
                                                                {/*</div>*/}
                                                                <MiniInstitutionHistory
                                                                    history={row.original}
                                                                    user={this.props.user}
                                                                    permission = {this.props.permission_classification}/>

                                                                {/*<div>*/}
                                                                {/*    {row.original.work_summary}*/}
                                                                {/*</div>*/}

                                                            </div>
                                                        );
                                                    }}
                                                />

                                            </div>
                                            <div className="tab-pane" id="notfound" role="tabpanel">
                                                <AiOutlineUsergroupDelete size="2rem"/>
                                                <h3 className="title">Unmatched Individual History</h3>
                                                <ReactTable
                                                    //manual
                                                    data={this.props.IndividualNotFound}
                                                    //pages={individualTotalPagesNotFound}
                                                    columns={this.props.deletePermission?IndividualNotFoundColumns:IndividualNotFoundColumnsNoDelete}
                                                    defaultPageSize={5}
                                                    //filterable
                                                    //loading={loadingIndividualNotFound}
                                                    //onFetchData={this.fetchIndividualNotFoundData}
                                                    //filterable
                                                    className="-striped -highlight"
                                                    getTrProps={(state, rowInfo, column, instance) => {
                                                        return {
                                                            onClick: (e) => {
                                                                this.setState({
                                                                    currentHistory : rowInfo.original
                                                                });
                                                                // currentHistory = rowInfo.original._id;
                                                                // currentHistoryData = rowInfo.original;
                                                            }
                                                        }
                                                    }}
                                                    SubComponent={row => {
                                                        return (
                                                            <MiniIndividualHistory
                                                                history={row.original}
                                                                user={this.props.user}
                                                                permission = {this.props.permission_classification}
                                                            />
                                                        );
                                                    }}
                                                />

                                                <br/>
                                                <FaBuilding size="2rem"/>
                                                <h3 className="title">Unmatched Institution History</h3>
                                                <ReactTable
                                                    //manual
                                                    data={this.props.InstitutionNotFound}
                                                    columns={this.props.deletePermission?OrganizationNotFoundColumns:OrganizationNotFoundColumnsNoDelete}
                                                    //pages={organizationTotalPagesNotFound}
                                                    defaultPageSize={5}
                                                    //filterable
                                                    //loading={loadingOrganizationNotFound}
                                                    //onFetchData={this.fetchOrganizationNotFoundData} // Request new data when things change
                                                    //filterable
                                                    className="-striped -highlight"
                                                    getTrProps={(state, rowInfo, column, instance) => {
                                                        return {
                                                            onClick: (e) => {
                                                                this.setState({
                                                                    currentHistory : rowInfo.original
                                                                });
                                                                // currentHistory = rowInfo.original._id;
                                                                // currentHistoryData = rowInfo.original;
                                                            }
                                                        }
                                                    }}
                                                    SubComponent={row => {
                                                        return (
                                                            <MiniInstitutionHistory
                                                                history={row.original}
                                                                user={this.props.user}
                                                                permission = {this.props.permission_classification}/>
                                                        );
                                                    }}


                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default MergeData;