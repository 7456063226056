import React, {Component} from "react";
import axios from "axios";
import ShowAlert from "../../../../utils/ShowAlert";
import Moment from "react-moment";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import MainNavBar from "../../../../components/Navbars/MainNavBar";

//Icon
import {FaEdit} from "react-icons/fa";
import UpdateOrganization from "../../Admin/UsersList/UpdateOrganization";
const iconSize = "1.0rem";

class OrganizationUser extends Component{
    constructor(props) {
        super(props);
        this.state = {
            user : {},
            visible : false,
            currentpassword:'',
            newpassword:'',
            newpassword2:''
        };
    }
    onChange = e => {
        e.preventDefault();
        this.setState({[e.target.id]: e.target.value});
    };
    componentDidMount() {

        axios.get('/api-users/getUser/' + this.props.auth.user.id).then( res =>{
            this.setState({
                user : res.data
            })
        }).catch(err =>{
            ShowAlert(err.response.data);
        })
    }
    onChangePassword= e => {
        e.preventDefault();
        if(this.state.newpassword !== this.state.newpassword2){
            ShowAlert("Passwords aren't matched");
        }
        const data = {
            currentPassword : this.state.currentpassword,
            newPassword : this.state.newpassword
        };
        axios.post('/api-users/changePassword/' + this.props.auth.user.id,data).then( res =>{
            ShowAlert(res.data);
            this.setState({
                currentpassword :'',
                newpassword:'',
                newpassword2:''
            })
        }).catch(err =>{
            ShowAlert(err.response);
        })
    };
    render() {
        let EditButton = <li className="nav-item"><a href="#editProfile"
                                                     className="nav-link  site-btn text-danger editInfo"
                                                     data-toggle="collapse"
                                                     aria-expanded="false"
                                                     aria-controls="editProfile"
        >
            <FaEdit size={iconSize}/>
            Edit
        </a></li>;
        let EditPanel = <div className="collapse" id="editProfile">

            <div className="tab-content text-center">
                <div className="card ">
                    <div className="card-header">
                        <ul className="nav nav-tabs justify-content-center" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#updateinfo"
                                   role="tab">
                                    <i className="now-ui-icons users_single-02"></i>
                                    Update Info
                                </a>
                            </li>


                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#changpassword"
                                   role="tab">
                                    <i className="now-ui-icons ui-1_settings-gear-63"></i>
                                    Change Password
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="card-body">

                        <div className="tab-content text-center">
                            <div className="tab-pane active" id="updateinfo" role="tabpanel">
                                <UpdateOrganization organization ={this.state.user} referenceNum={false} trust={false}/>
                            </div>
                            <div className="tab-pane" id="changpassword" role="tabpanel">
                                <form
                                    onSubmit={this.onChangePassword}
                                    method="PUT"
                                    className="form"
                                    encType="multipart/form-data"
                                >
                                    <div className="text-left">
                                        <div className="row">
                                            <div className="col-sm-4 ml-auto mr-auto">
                                                <p className="font-weight-bold">Current Password</p>
                                            </div>
                                            <div className="col-sm-4 ml-auto mr-auto">
                                                <input
                                                    onChange={this.onChange}
                                                    className="form-control"
                                                    value={this.state.currentpassword}
                                                    type="password"
                                                    id="currentpassword"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4 ml-auto mr-auto">
                                                <p className="font-weight-bold">New Password</p>
                                            </div>
                                            <div className="col-sm-4 ml-auto mr-auto">
                                                <input
                                                    onChange={this.onChange}
                                                    className="form-control"
                                                    value={this.state.newpassword}
                                                    type="text"
                                                    id="newpassword"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4 ml-auto mr-auto">
                                                <p className="font-weight-bold">Confirm New Password</p>
                                            </div>
                                            <div className="col-sm-4 ml-auto mr-auto">
                                                <input
                                                    onChange={this.onChange}
                                                    className="form-control"
                                                    value={this.state.newpassword2}
                                                    type="text"
                                                    id="newpassword2"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        type="submit"
                                        className="site-btn text-success"
                                    >Change Password
                                    </button>


                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>;
        return(
            <React.Fragment>
                <MainNavBar SignIn={false} SignUp={false} dashboard={true} socialMedia={false}/>
                <header className="header-section">
                    <div className="container-fluid">
                        <div className="row">

                            <div className="col text-md-right header-buttons">
                                <ul className="nav justify-content-end">
                                    {EditButton}
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>


                <section className="hero-section spad">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-10 offset-xl-1">
                                {EditPanel}
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="hero-text">
                                            <h3 className="display-4">{this.state.user.name_in_khmer}</h3>
                                            <h3 className="display-4"> {this.state.user.name}</h3>

                                        </div>
                                        <div className="hero-info">
                                            <h2>General Info</h2>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="font-weight-bold">Registered Number:</p>
                                                </div>
                                                <div className="col">
                                                    <p>{this.state.user.register_number}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="font-weight-bold">Tax Identification Number:</p>
                                                </div>
                                                <div className="col">
                                                    <p>{this.state.user.tax_identification_number}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="font-weight-bold">Representative:</p>
                                                </div>
                                                <div className="col">
                                                    <p>{this.state.user.representative}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="font-weight-bold">Position:</p>
                                                </div>
                                                <div className="col">
                                                    <p>{this.state.user.position}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="font-weight-bold">Email:</p>
                                                </div>
                                                <div className="col">
                                                    <p>{this.state.user.email}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="font-weight-bold">Date Created:</p>
                                                </div>
                                                <div className="col">
                                                    <p><Moment format="DD/MM/YYYY">{this.state.user.createdAt}</Moment></p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="font-weight-bold">Current Address: </p>
                                                </div>
                                                <div className="col">
                                                    <p>{this.state.user.current_address}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="font-weight-bold">Status: </p>
                                                </div>
                                                <div className="col">
                                                    <p className={this.state.user.isVerified?"text-info":"text-danger"}>{this.state.user.isVerified? "Verified" : "Not Verified"}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="font-weight-bold">Reference Check Number: </p>
                                                </div>
                                                <div className="col">
                                                    <p>{this.state.user.reference_check_number}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
OrganizationUser.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(OrganizationUser));