import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import axios from "axios";
import OrganizationInfo from "./OrganizationInfo";
import UpdateIndividualInfo from "./UpdateIndividualInfo";
import InsertIndividual from "./InsertIndividual";
import InsertInstitution from "./InsertInstitution";
import UpdatePhoto from "./updatePhoto";
import { trackPromise } from 'react-promise-tracker';
import Alert from "react-s-alert";
import MainNavBar from "../../../components/Navbars/MainNavBar";
import {IoIosAddCircle} from "react-icons/all";


class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            photo: null,
            passport: null,
            currentpassword :'',
            newpassword:'',
            newpassword2:''
        }
    }
    componentDidMount() {
        trackPromise(
            axios.get('/api-users/getUser/' + this.props.auth.user.id).then( res =>{
                this.setState({
                    user : res.data
                })
            }).catch( err =>{
                console.log(err.response);
            })
        );

        if(this.props.auth.user.type === "IndividualMember"){
            trackPromise(
                axios.all([
                    axios.get('/api-counts/getPhoto/' +this.props.auth.user.id ),
                    axios.get('/api-counts/getPassport/' +this.props.auth.user.id )
                ]).then( axios.spread( (res1,res2) =>{
                    this.setState({
                        photo : res1.data,
                        passport : res2.data
                    })
                })).catch(err =>{
                console.log(err.response);
            })
            ).catch(err =>{
                console.log(err.response);
            })
        }

    }

    onChangePassword= e => {
        e.preventDefault();
        if(this.state.newpassword !== this.state.newpassword2){
            Alert.error("Passwords aren't matched", {
                position: 'bottom-left',
                effect: 'slide'
            });
            return;
        }
        const data = {
            currentPassword : this.state.currentpassword,
            newPassword : this.state.newpassword
        };
        axios.post('/users/changePassword/' + this.props.auth.user.id,data).then( res =>{
            for (let i in res.data) {
                Alert.info(res.data[i], {
                    position: 'bottom-right',
                    effect: 'jelly'
                });
            }
            this.setState({
                currentpassword :'',
                newpassword:'',
                newpassword2:''
            })
        }).catch(err =>{
            for(let i in err.response.data){
                Alert.error(err.response.data[i], {
                    position: 'bottom-right',
                    effect: 'jelly'
                });
            }
        })
    };
    onChange = e => {
        e.preventDefault();
        this.setState({[e.target.id]: e.target.value});
    };

    render() {
        //console.log(this.props);
        let headingtext;
        let headingdes;
        let updateUser;
        let updatePhoto;



        if (this.state.user.type === "IndividualMember") {
            headingtext = "Personal Information";
            headingdes = "This page contains personal information related to current user where you can update information.";
            updateUser = <UpdateIndividualInfo user={this.state.user} />;
            updatePhoto = <UpdatePhoto photo={this.state.photo} passport={this.state.passport} id={this.props.auth.user.id}/>
        }
        if(this.state.user.type === "OrganizationMember") {
            updateUser = <OrganizationInfo organization={this.state.user} />;
            headingtext = "Organization Information";
            headingdes = "This page contains organization where you registered to use this service, you can change some information related" +
                "to this organization and representative person.";
        }
        return (
            <React.Fragment>
                <Alert stack={{limit: 3}}/>

                <div className="section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 ml-auto mr-auto  ">

                                <h3 className="text-center title"><IoIosAddCircle size="2rem"/><br/>Insert Record</h3>
                                <div className="card">
                                    <div className="card-header">
                                        <ul className="nav nav-tabs justify-content-center" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#individual"
                                                   role="tab">
                                                    <i className="now-ui-icons users_single-02"></i>
                                                    Individual Employee
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#institution"
                                                   role="tab">
                                                    <i className="now-ui-icons ui-1_settings-gear-63"></i>
                                                    Institution
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-body">

                                        <div className="tab-content text-center">
                                            <div className="tab-pane active" id="individual" role="tabpanel">
                                                <InsertIndividual user ={this.props.auth.user.id}/>
                                            </div>
                                            <div className="tab-pane" id="institution" role="tabpanel">
                                                <InsertInstitution user ={this.props.auth.user.id}/>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }

}

Dashboard.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(Dashboard));