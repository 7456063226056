import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from "./types";

// Register User
export const registerIndividual = (userData, history) => dispatch => {
  axios
    .post("/api-individualMember/register", userData)
    .then(res => {
        //console.log(res);
        //history.push(`/verification/${res.data.id}`);
        window.location.href = '/deactivated';
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const registerOrganization = (userData, history) => dispatch => {
    axios
        .post("/api-organizationMember/register", userData)
        .then(res => {
            console.log(res);
            //history.push(`/verification/${res.data.id}`);
            window.location.href = '/deactivated';
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const verifyUser = (userData,history) => dispatch => {
    axios.post("/api-users/verify",userData)
        .then( res => {
            console.log(res.data);
            if(res.status === 200){
                history.push('/');
            }
        }).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};

// Login - get user token
export const loginUser = (userData, history)  => dispatch => {
  axios
    .post("/api-users/login", userData)
    .then(res => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtTokenTeams", JSON.stringify(token));
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch(err =>{
        if(err.response.data.type === "not-verified"){
            history.push(`/verification/${err.response.data.id}`);
        }
        if(err.response.data.type === "not-activated"){
            history.push(`/deactivated`);
        }
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        });
        }
    );
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

// Log user out
export const logoutUser = history => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("jwtTokenTeams");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  window.location.href = "/";
};
