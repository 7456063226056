import React, {Component} from "react";
import axios from "axios";
import ShowAlert from "../../../../utils/ShowAlert";
import Moment from "react-moment";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import UpdateOrganization from "./UpdateOrganization";
import {FaEdit, FaUserAltSlash,FaTrashRestoreAlt} from "react-icons/fa";

class OrganizationUserView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            visible: false,
        };
    }

    componentDidMount() {

        axios.get('/api-users/getUser/' + this.props.id).then(res => {
            this.setState({
                user: res.data
            })
        }).catch(err => {
            ShowAlert(err.response.data);
        })
    }
    onDeactivateAccount = e =>{
        axios.post("/api-users/deactivateUser/" + this.props.id ).then( result =>{
            if(result.status === 200){
                window.location.reload();
            }
        }).catch(err =>{
            console.log(err.response);
        })
    };
    onReactivateAccount = e =>{
        axios.post("/api-users/reactivateUser/" + this.props.id ).then( result =>{
            if(result.status === 200){
                window.location.reload();
            }
        }).catch(err =>{
            console.log(err.response);
        })
    };

    render() {

        let EditOrNot;
        let deleteOrNot;
        let UpdateInfo;
        if(this.props.permissions.EditData.includes("Member")){
            EditOrNot = <a href="#editUser"
                           className="site-btn text-warning"
                           data-toggle="collapse"
                           aria-expanded="false"
                           aria-controls="editUser">Edit</a>;
            UpdateInfo = <UpdateOrganization organization ={this.state.user} referenceNum={true} trust={true}/>;
        }
        if(this.props.permissions.DeleteData.includes("Member")){
            if(this.state.user.isDeactivated){
                deleteOrNot = <
                    a href="#"
                      className="site-btn text-success"
                      onClick={this.onReactivateAccount}
                > <FaTrashRestoreAlt/>Reactivate Account</a>;
            }else{
                deleteOrNot = <
                    a href="#"
                      className="site-btn text-danger"
                      onClick={this.onDeactivateAccount}
                > <FaUserAltSlash/>Deactivate Account</a>;
            }
        }
        return (
            <React.Fragment>
                <header className="header-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col text-md-right header-buttons">
                                {EditOrNot}
                                {deleteOrNot}
                            </div>

                        </div>
                    </div>
                </header>


                <section className="hero-section spad">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-10 offset-xl-1">
                                <Breadcrumb>
                                    <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
                                    <BreadcrumbItem><a href="/adminDashboard">Dashboard</a></BreadcrumbItem>
                                    <BreadcrumbItem><a href="/usersList">Users</a></BreadcrumbItem>
                                    <BreadcrumbItem active>Organization User</BreadcrumbItem>
                                </Breadcrumb>
                                <div className="collapse" id="editUser">
                                    <div className="card ">
                                        <div className="card-header text-center">
                                            <h3 className="title">Update Organization Info</h3>
                                        </div>
                                        <div className="card-body text-center">
                                            {UpdateInfo}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="hero-text">
                                            <h3 className={this.state.user.isDeactivated?"display-4 deleted":"display-4"}>{this.state.user.name_in_khmer}</h3>
                                            <h3 className={this.state.user.isDeactivated?"display-4 deleted":"display-4"}> {this.state.user.name}</h3>
                                        </div>
                                        <div className="hero-info">
                                            <h2>General Info</h2>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="font-weight-bold">Registered Number:</p>
                                                </div>
                                                <div className="col">
                                                    <p>{this.state.user.register_number}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="font-weight-bold">Tax Identification Number:</p>
                                                </div>
                                                <div className="col">
                                                    <p>{this.state.user.tax_identification_number}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="font-weight-bold">Representative:</p>
                                                </div>
                                                <div className="col">
                                                    <p>{this.state.user.representative}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="font-weight-bold">Position:</p>
                                                </div>
                                                <div className="col">
                                                    <p>{this.state.user.position}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="font-weight-bold">Email:</p>
                                                </div>
                                                <div className="col">
                                                    <p>{this.state.user.email}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="font-weight-bold">Date Created:</p>
                                                </div>
                                                <div className="col">
                                                    <p><Moment format="DD/MM/YYYY">{this.state.user.createdAt}</Moment>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="font-weight-bold">Current Address: </p>
                                                </div>
                                                <div className="col">
                                                    <p>{this.state.user.current_address}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="font-weight-bold">Status: </p>
                                                </div>
                                                <div className="col">
                                                    <p className={this.state.user.isDeactivated?"text-danger" :this.state.user.isVerified?"text-info":"text-warning"}>{this.state.user.isDeactivated? "Deactivated" :this.state.user.isVerified? "Email Verified" : "Email Not Verified"}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="font-weight-bold">Trust Level: </p>
                                                </div>
                                                <div className="col">
                                                    <p className="text-success">{this.state.user.trustLevel}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="font-weight-bold">Reference Check Number: </p>
                                                </div>
                                                <div className="col">
                                                    <p className="text-info">{this.state.user.reference_check_number}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default OrganizationUserView;