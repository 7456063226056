import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import IndividualUser from "./IndividualUser";
import OrganizationUser from "./OrganizationUser";
import AdminUser from "./AdminUser";
class User extends Component{

    render() {

        if(this.props.auth.user.type === "AdminUser"){
            return (
                <AdminUser/>
            )
            //window.location.href = "/adminUser/" + this.props.auth.user.id;
        }

        if(this.props.auth.user.type === "IndividualMember"){
           return(
               <IndividualUser/>
           )
        }else {
            return (
                <OrganizationUser/>
            )
        }
    }


}

User.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(User));