import React, {Component} from "react";
import axios from "axios";
import Moment from "react-moment";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import ShowAlert from "../../../../utils/ShowAlert";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import MainNavBar from "../../../../components/Navbars/MainNavBar";
import UpdateAdminInfo from "../../Admin/AdminsList/UpdateAdminInfo";
import {FaEdit,FaStreetView} from "react-icons/fa";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import ActivityList from "../../Admin/AdminsList/ActivityList";

const iconSize = "1.0rem";

class AdminUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            admin: {},
            photo: null,
            passport: null,
            visible: false,
            loading: true,
            currentpassword: '',
            newpassword: '',
            newpassword2: ''
        };
    }

    componentDidMount() {
        axios.all([
            axios.get('/api-users/getUser/' + this.props.auth.user.id),
            axios.get('/api-counts/getPhoto/' + this.props.auth.user.id),
            axios.get('/api-counts/getPassport/' + this.props.auth.user.id)
        ]).then(axios.spread((res1, res2, res3) => {
            this.setState({
                admin: res1.data,
                photo: res2.data,
                passport: res3.data,
                loading: false
            })
        })).catch(err => {
            ShowAlert(err.response.data);
        })
    }
    onChange = e => {
        e.preventDefault();
        this.setState({[e.target.id]: e.target.value});
    };
    onChangePassword = e => {
        e.preventDefault();
        if (this.state.newpassword !== this.state.newpassword2) {
            ShowAlert("Passwords aren't matched");
        }
        const data = {
            currentPassword: this.state.currentpassword,
            newPassword: this.state.newpassword
        };
        axios.post('/api-users/changePassword/' + this.props.auth.user.id, data).then(res => {
            window.location.reload();
            this.setState({
                currentpassword: '',
                newpassword: '',
                newpassword2: ''
            })
        }).catch(err => {
            ShowAlert(err.response);
        })
    };

    render() {
        let memberActivities = <li className="nav-item"><a href="#viewProfile"
                                                       className="nav-link  site-btn text-info editInfo"
                                                       data-toggle="collapse"
                                                       aria-expanded="false"
                                                       aria-controls="viewProfile"
        >
            <FaStreetView size={iconSize}/>
            View Activity
        </a></li>;
        let memberActivitiesPanel = <div className="collapse" id="viewProfile">

            <div className="tab-content text-center">
                <div className="card ">
                    <div className="card-header">

                        <h3 className="title font-weight-bold">Admin Activities Info</h3>


                    </div>

                    <div className="card-body">
                        <ActivityList user={this.props.auth.user.id} />


                    </div>
                </div>
            </div>

        </div>;
        let EditPermissionorNot = this.state.admin.permission_classification === "System Admin";
        let EditOrNot = <li className="nav-item"><a href="#editProfile"
                                                    className="nav-link  site-btn text-danger editInfo"
                                                    data-toggle="collapse"
                                                    aria-expanded="false"
                                                    aria-controls="editProfile"
        >
            <FaEdit size={iconSize}/>
            Edit
        </a></li>;
        let EditPanel = <div className="collapse" id="editProfile">


            <div className="tab-content text-center">
                <div className="card ">
                    <div className="card-header">

                        <ul className="nav nav-tabs justify-content-center" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#updateinfo"
                                   role="tab">
                                    <i className="now-ui-icons users_single-02"></i>
                                    Update Info
                                </a>
                            </li>


                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#changpassword"
                                   role="tab">
                                    <i className="now-ui-icons ui-1_settings-gear-63"></i>
                                    Change Password
                                </a>
                            </li>
                        </ul>


                    </div>

                    <div className="card-body">
                        <div className="tab-content text-center">
                            <div className="tab-pane active" id="updateinfo" role="tabpanel">
                                <UpdateAdminInfo user={this.state.admin} system={EditPermissionorNot}/>
                            </div>
                            <div className="tab-pane" id="changpassword" role="tabpanel">
                                <form
                                    onSubmit={this.onChangePassword}
                                    method="PUT"
                                    className="form"
                                    encType="multipart/form-data"
                                >
                                    <div className="text-left">
                                        <div className="row">
                                            <div className="col-sm-4 ml-auto mr-auto">
                                                <p className="font-weight-bold">Current Password</p>
                                            </div>
                                            <div className="col-sm-4 ml-auto mr-auto">
                                                <input
                                                    onChange={this.onChange}
                                                    className="form-control"
                                                    value={this.state.currentpassword}
                                                    type="password"
                                                    id="currentpassword"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4 ml-auto mr-auto">
                                                <p className="font-weight-bold">New Password</p>
                                            </div>
                                            <div className="col-sm-4 ml-auto mr-auto">
                                                <input
                                                    onChange={this.onChange}
                                                    className="form-control"
                                                    value={this.state.newpassword}
                                                    type="text"
                                                    id="newpassword"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4 ml-auto mr-auto">
                                                <p className="font-weight-bold">Confirm New Password</p>
                                            </div>
                                            <div className="col-sm-4 ml-auto mr-auto">
                                                <input
                                                    onChange={this.onChange}
                                                    className="form-control"
                                                    value={this.state.newpassword2}
                                                    type="text"
                                                    id="newpassword2"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        type="submit"
                                        className="site-btn text-success"
                                    >Change Password
                                    </button>


                                </form>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </div>;
        return (
            <React.Fragment>
                <MainNavBar SignIn={false} SignUp={false} dashboard={false} socialMedia={false}/>
                {this.state.loading ? <LoadingScreen/> :
                    <div>
                        <header className="header-section">
                            <div className="container-fluid">
                                <div className="row">

                                    <div className="col text-md-right header-buttons">
                                        <ul className="nav justify-content-end">
                                            {EditOrNot}
                                            {memberActivities}
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </header>
                        <section className="hero-section spad">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xl-10 offset-xl-1">
                                        <Breadcrumb>
                                            <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
                                            <BreadcrumbItem><a href="/dashboard">Dashboard</a></BreadcrumbItem>
                                            <BreadcrumbItem active>Admin User View</BreadcrumbItem>
                                        </Breadcrumb>
                                        {EditPanel}
                                        {memberActivitiesPanel}
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="hero-text">
                                                    <h3 className="display-4"> {this.state.admin.name}</h3>
                                                    <h3 className="title"> {this.state.admin.permission_classification}</h3>
                                                </div>
                                                <div className="hero-info">
                                                    <h2>General Info</h2>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="font-weight-bold">ID Number:</p>
                                                        </div>
                                                        <div className="col">
                                                            <p>{this.state.admin.identification_number}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="font-weight-bold">Email:</p>
                                                        </div>
                                                        <div className="col">
                                                            <p>{this.state.admin.email}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="font-weight-bold">Date Created:</p>
                                                        </div>
                                                        <div className="col">
                                                            <p><Moment
                                                                format="DD/MM/YYYY">{this.state.admin.createdAt}</Moment>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="font-weight-bold">Current Address: </p>
                                                        </div>
                                                        <div className="col">
                                                            <p>{this.state.admin.current_address}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <figure className="hero-image">
                                                    <img src={this.state.photo} width="350" alt="5"/>
                                                    <img src={this.state.passport} width="350" alt="5"/>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>}

            </React.Fragment>
        )
    }
}

AdminUser.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(AdminUser));