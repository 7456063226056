import React, {Component} from "react";
import Alert from "react-s-alert";
import InsertIndividual from "./InsertIndividual";
import InsertInstitution from "./InsertInstitution";

class InsertRecords extends Component{




    render() {
        return(
            <React.Fragment>
                <Alert stack={{limit: 3}}/>
                <div className="section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 ml-auto mr-auto text-center">
                                <h3 className="title">Record Types to insert...?</h3>
                                <div className="card">
                                    <div className="card-header">
                                        <ul className="nav nav-tabs justify-content-center" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#individual"
                                                   role="tab">
                                                    <i className="now-ui-icons users_single-02"></i>
                                                    Individual Employee
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#institution"
                                                   role="tab">
                                                    <i className="now-ui-icons business_bank"></i>
                                                    Institution Record
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className="card-body">

                                        <div className="tab-content text-center">
                                            <div className="tab-pane active " id="individual" role="tabpanel">
                                                <InsertIndividual id={this.props.id}/>
                                            </div>
                                            <div className="tab-pane" id="institution" role="tabpanel">
                                                <InsertInstitution id={this.props.id}/>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default InsertRecords;