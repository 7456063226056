import React, {Component} from "react";

// reactstrap components
import {
    Button,
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import MainNavBar from "../../components/Navbars/MainNavBar";
import LandingPageHeader from '../../components/Headers/LandingPageHeader';

class LandingPage extends Component {



    render() {
        return (
            <React.Fragment>
                <MainNavBar SignIn ={true} SignUp={true} socialMedia={true}/>
                <div className="wrapper">
                    <LandingPageHeader />
                    <div className="section section-about-us">
                        <Container>
                            <Row>
                                <Col className="ml-auto mr-auto text-center" md="8">
                                    <h2 className="title"> Empower Your Hiring Decisions with Smart Workforce</h2>
                                    <h5 className="description">
                                        In today's competitive market, securing top-tier talent is paramount. The Smart Workforce provides comprehensive background checks, delivering the insights you need to make informed recruitment decisions and build a high-performing team.  Our robust database offers in-depth information on potential employees, empowering you to Mitigate Risk, Accelerate Recruitment and Enhance employers’ profitability.
                                    </h5>
                                </Col>
                            </Row>
                            <div className="separator separator-primary"></div>
                            <div className="section-story-overview">
                                <Row>
                                    <Col md="6">
                                        
                                       
                                    </Col>
                                    <Col md="5">
                                       
                                        <h3>
                                          
                                        </h3>
                                        <p>

                                        </p>
                                        <p>
                                           
                                        </p>
                                        <p>
                                            
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="section">
                        <div className="features-clean">
                            <div className="container">
                                <div className="intro">
                                    <h2 className="text-center">Features</h2>
                                    <p className="text-center">Personal Tracking System provides you with trustworthy features with security
                                    and confidentiality in mind.</p>
                                </div>
                                <div className="row features">
                                    <div className="col-sm-6 col-lg-4 item"><i className="fa fa-map-marker icon"/>
                                        <h3 className="name">Works everywhere</h3>
                                        <p className="description"></p>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 item"><i className="fa fa-list-alt icon"/>
                                        <h3 className="name">Customizable</h3>
                                        <p className="description"></p>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 item"><i className="fa fa-leaf icon"/>
                                        <h3 className="name">Organic</h3>
                                        <p className="description"></p>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 item"><i className="fa fa-plane icon"/>
                                        <h3 className="name">Fast</h3>
                                        <p className="description"></p>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 item"><i className="fa fa-phone icon"/>
                                        <h3 className="name">Mobile-first</h3>
                                        <p className="description"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section section-contact-us text-center">
                        <Container>
                            <h2 className="title">Want to work with us?</h2>
                            <p className="description">We are your Partner in Building a World-Class Workforce.</p>
                            <Row>
                                <Col className="text-center ml-auto mr-auto" lg="6" md="8">

                                    <div className="send-button">
                                        <Button
                                            block
                                            className="btn-round"
                                            color="info"
                                            href="/sign-in"
                                            onClick={
                                                console.log("")
                                            }
                                            size="lg"
                                        >
                                            Login/ Sign Up
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default LandingPage;
