import React, {Component} from "react";
import axios from 'axios';
import ReactToPrint from "react-to-print";
import Viewer from 'react-viewer';
import Moment from 'react-moment';
import IndividualResumeList from "./IndividualResumeList";
import {trackPromise} from "react-promise-tracker";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import {Element, Events, scroller} from 'react-scroll';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import ShowAlert from "../../../utils/ShowAlert";
import EditIndividual from "../Admin/RecordsList/EditIndividual";
import InsertIndividualHistory from "../Admin/InsertHistories/InsertIndividualHistory";
//Icon
import {
    FaInfo,
    FaSearch,
    FaEdit,
    FaFileDownload,
    FaHistory,
    FaUserAltSlash,
    FaTrashRestoreAlt,
    FaPhone, FaUsers
} from "react-icons/all";
import "./style.css";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import NotFound from "../../../components/404/404";
import MainNavBar from "../../../components/Navbars/MainNavBar";


const iconSize = "1.0rem";




class IndividualEmplyoeePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            individual: {
                data_contributor: [],
                dataRevealPercentage :{
                }
            },
            histories: [],
            permissions_list: {
                InsertData: [],
                ViewData: [],
                EditData: [],
                DeleteData: [],
                MergeData: false
            },
            photo: null,
            passport: null,
            visible: false,
            user: '',
            lastCheck: '',
            loading: true,
            fraudLoading: false
        }
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    componentDidMount() {
        Events.scrollEvent.register('begin', function () {
            //console.log("begin", arguments);
        });

        Events.scrollEvent.register('end', function () {
            //console.log("end", arguments);
        });
        trackPromise(
            axios.get('/api-users/verifyAuthenticity/' + this.props.match.params.id + '/' + this.props.auth.user.id + '/'
                + 'IndividualProfile/' + this.props.match.params.date).then(() => {
                if (this.props.auth.user.type === "AdminUser") {
                    trackPromise(
                        axios.get('/api-individualProfile/history_list_system_admin/'
                            + this.props.match.params.id + '/'
                            + this.props.auth.user.id
                        ).then(res => {
                            this.setState({
                                histories: res.data.histories,
                                permissions_list: res.data.permissions_list
                            })
                        })
                    ).catch(err => {
                        ShowAlert(err);
                    })

                } else {
                    trackPromise(
                        axios.get('/api-individualProfile/history_list_public/'
                            + this.props.match.params.id + '/'
                            + this.props.auth.user.id + '/'
                            + this.props.match.params.date
                        ).then(res => {
                            this.setState({
                                histories: res.data
                            })
                        })
                    ).catch(err => {
                        ShowAlert(err);
                    })
                }

                trackPromise(
                    axios.all([
                        axios.get('/api-individualProfile/' + this.props.match.params.id + '/' + this.props.auth.user.id),
                        axios.get('/api-individualProfile/getPhoto/' + this.props.match.params.id),
                        axios.get('/api-individualProfile/getPassport/' + this.props.match.params.id)
                    ]).then(axios.spread((res1, res2, res3) => {

                        this.setState({
                            individual: res1.data,
                            photo: res2.data,
                            passport: res3.data,
                            loading : false
                        })
                    }))
                ).catch(error => console.log(error))

            })
        ).catch(err => {
            this.setState({
                loading: false,
                fraudLoading: true
            })
        })

    }

    onDeleteProfile = e => {
        e.preventDefault();
        axios.delete('/api-counts/' + this.props.match.params.id + '/' + this.props.auth.user.id).then(res => {
            window.location.reload();
        }).catch(err => {
            console.log(err.response);
        })
    };

    onRestoreProfile = e => {
        e.preventDefault();
        axios.post('/api-counts/restore/'
            + this.props.match.params.id +'/' + this.props.auth.user.id
        ).then( () => {
            window.location.reload();
        }).catch(err => {
            console.log(err.response);
        })
    };

    scrollTo() {
        scroller.scrollTo('scroll-to-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    onDownloadClicked = e => {
        e.preventDefault();
    };


    render() {
        //console.log(this.state);
        const histories = this.state.histories;
        let HistoryItem;
        if (histories.length <= 0) {
            HistoryItem = "No Employment History Recorded";
        } else {
            const editStatusOrNot = this.state.permissions_list.EditData.includes("Histories");
            HistoryItem = histories.map((history, k) =>
                <IndividualResumeList
                    history={history}
                    key={k}
                    status={editStatusOrNot}
                    permissions_list={this.state.permissions_list}
                    user={this.props.auth.user.id}
                    userType={this.props.auth.user.type}
                    permission={this.props.auth.user.permission}
                />
            );
        }
        let EditorNot;
        let EditPanel;
        let DeleteorNot;
        let InsertHistory;
        let InsertPanel;
        let DeleteConfirm;
        let downloadButton;
        let recordsListOrNot;
        let additionalInfo;
        if (this.props.auth.user.type === "AdminUser") {
            recordsListOrNot = <BreadcrumbItem><a href="/recordsList">Records List</a></BreadcrumbItem>;
            if (this.props.auth.user.permission === "System Admin") {
                InsertPanel = <div className="collapse" id="insertHistory">
                    <div className="card text-center">
                        <div className="card-body">
                            <InsertIndividualHistory
                                user={this.props.auth.user.id}
                                profile={this.props.match.params.id}
                                status={true}/>
                        </div>

                    </div>
                </div>;
                additionalInfo = <div>
                    <h2><FaUsers/> Data Contributors</h2>
                    {
                        this.state.individual.data_contributor.map((data, k) =>
                            <>

                                <div className="row">
                                    <div className="col">
                                        <p className="font-weight-bold">Name: </p>
                                    </div>
                                    <div className="col">
                                        <a href={"/adminUser/" + data._id}><p>{data.name}</p></a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <p className="font-weight-bold">Email: </p>
                                    </div>
                                    <div className="col">
                                        <p>{data.email}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <p className="font-weight-bold">Permission: </p>
                                    </div>
                                    <div className="col">
                                        <p>{data.permission_classification}</p>
                                    </div>
                                </div>
                                <div className="separator-line"/>

                            </>
                        )}
                </div>;
            }
            if (this.state.permissions_list.EditData.includes("Profile")) {
                EditorNot =
                    <li className="nav-item"><a href="#editProfile"
                                                className="nav-link btn-outline-warning editInfo border-dark button-nav "
                                                data-toggle="collapse"
                                                aria-expanded="false"
                                                aria-controls="editProfile"
                                                to="editInfo"
                                                onClick={() => {
                                                    this.scrollTo()
                                                }}

                    >
                        <FaEdit size={iconSize}/>
                        Edit
                    </a></li>;
                let formatReportOrNot = false;
                if(this.state.permissions_list.EditData.includes("FormattingReports")){
                    formatReportOrNot = true;
                }
                EditPanel = <div className="collapse" id="editProfile">
                    <div className="card ">
                        <div className="card-body">
                            <div className="tab-content text-center">
                                <EditIndividual
                                    user={this.state.individual}
                                    member={this.props.auth.user.id}
                                    dataRevealPercentage={this.state.individual.dataRevealPercentage}
                                    formatReportOrNot = {formatReportOrNot}
                                />
                            </div>

                        </div>

                    </div>
                </div>;
            }
            if (this.state.permissions_list.InsertData.includes("Histories")) {
                InsertHistory = <li className="nav-item">
                    <a href="#insertHistory"
                       className="btn-outline-success nav-link border-dark button-nav"
                       data-toggle="collapse"
                       aria-expanded="false"
                       aria-controls="insertHistory">
                        <FaHistory size={iconSize}/>
                        Insert History</a></li>;
                InsertPanel = <div className="collapse" id="insertHistory">
                    <div className="card text-center">
                        <div className="card-body insertFont">
                            <InsertIndividualHistory
                                user={this.props.auth.user.id}
                                profile={this.props.match.params.id}
                                status={false}
                            />
                        </div>

                    </div>
                </div>;
            }
            if (this.state.permissions_list.DeleteData.includes("Profile")) {
                if(!this.state.individual.deleted){
                    DeleteorNot = <li className="nav-item"><a
                        href="#"
                        data-toggle="modal"
                        data-target="#deleteprofile"
                        className="btn-outline-danger nav-link border-dark button-nav"
                    >
                        <FaUserAltSlash size={iconSize}/>
                        Delete</a></li>;
                    DeleteConfirm = <div className="modal fade" id="deleteprofile" tabIndex="-1" role="dialog"
                                         aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Confirm Delete Profile</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    Are you sure that you wish to delete this profile?
                                    Your action cannot be reverted!
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="site-btn text-black" data-dismiss="modal">Close
                                    </button>
                                    <button type="button" className="site-btn text-danger" data-dismiss="modal"
                                            onClick={this.onDeleteProfile}>Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>;
                }else{
                    DeleteorNot = <li className="nav-item"><a
                        href="#"
                        data-toggle="modal"
                        data-target="#deleteprofile"
                        className="btn-outline-success nav-link border-dark button-nav"
                    >
                        <FaTrashRestoreAlt size={iconSize}/>
                        Restore</a></li>;
                    DeleteConfirm = <div className="modal fade" id="deleteprofile" tabIndex="-1" role="dialog"
                                         aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Confirm Restore Profile</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    Are you sure that you wish to restore this profile?
                                    Your action cannot be reverted!
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="site-btn text-black" data-dismiss="modal">Close
                                    </button>
                                    <button type="button" className="site-btn text-success" data-dismiss="modal"
                                            onClick={this.onRestoreProfile}>Restore
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>;
                }

            }
            if (this.state.permissions_list.ViewData.includes("ExportPDF")) {
                downloadButton =
                    <li className="nav-item">
                        <ReactToPrint
                            trigger={() => <button
                                className="btn-outline-info  border-dark button-nav"
                            ><FaFileDownload size={iconSize}/>Export PDF
                            </button>}
                            content={() => this.componentRef}
                        />

                    </li>;
            }

        } else {
            downloadButton =
                <li className="nav-item">
                    <ReactToPrint
                        trigger={() => <button
                            className="btn-outline-info  border-dark button-nav"
                        ><FaFileDownload size={iconSize}/>Export PDF
                        </button>}
                        content={() => this.componentRef}
                    />
                </li>;
        }


        return (
            <div >
                <MainNavBar SignIn={false} SignUp={false} dashboard={false} socialMedia={false}/>
                {this.state.loading ? <LoadingScreen/> : this.state.fraudLoading ? <NotFound/> :
                    <React.Fragment>
                        <Viewer
                            visible={this.state.visible}
                            images={[
                                {src: this.state.photo, alt: 'Photo'},
                                {src: this.state.passport, alt: 'Passport'}]}
                            onClose={() => {
                                this.setState({visible: false});
                            }}
                        />
                        {DeleteConfirm}
                        <div className="home-three-style" >
                            <header className="header-section">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-4">

                                            <Breadcrumb>
                                                <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
                                                <BreadcrumbItem><a href="/dashboard">Dashboard</a></BreadcrumbItem>
                                                {recordsListOrNot}
                                                <BreadcrumbItem active>Individual Record</BreadcrumbItem>
                                            </Breadcrumb>
                                        </div>
                                        <div className="col-md-8  text-md-right header-buttons ">
                                            <ul className="nav justify-content-end hidden-print">
                                                {EditorNot}
                                                {DeleteorNot}
                                                {InsertHistory}
                                                {downloadButton}
                                                {/*{referenceCheck}*/}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className="container-fluid p-0">
                                <div className="row m-0" ref={el => (this.componentRef = el)}
                                     style={{'padding-left':30}}>

                                    <div className="col-xl-5 col-lg-5 p-0">
                                        <div className="main-left-area h-100">
                                            <div className="historyListTitle">
                                                <FaSearch size="3rem"/> <h2>Inklusivity : Reference Check</h2>
                                            </div>
                                            <section className="intro-section">

                                                <figure className="hero-image">
                                                    <img src={this.state.photo} width="160" alt=""
                                                         onClick={() => {
                                                             this.setState({visible: !this.state.visible});
                                                         }}/>
                                                    <img src={this.state.passport} width="160" alt=""
                                                         onClick={() => {
                                                             this.setState({visible: !this.state.visible});
                                                         }}/>
                                                </figure>
                                                <div className="row">
                                                    <div className="col mr-auto ml-auto">
                                                        <p className="font-weight-bold">You visited this profile on:</p>
                                                    </div>
                                                    <div className="col mr-auto ml-auto">
                                                        <p className="font-weight-bold text-danger"><em><Moment
                                                            format="LLLL">{this.props.match.params.date}</Moment></em>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="hero-text">
                                                    <h3 className={this.state.individual.deleted?"display-4 deleted":"display-4"}>{this.state.individual.name_in_khmer}</h3>
                                                    <h3 className={this.state.individual.deleted?"display-4 deleted":"display-4"}>{this.state.individual.name}</h3>

                                                </div>
                                                <Element name="scroll-to-element" className="element">
                                                    {EditPanel}
                                                </Element>

                                                <div className="hero-info pt-5">

                                                    <h2> <FaInfo size="2rem"/> General Info</h2>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="font-weight-bold">ID Number:</p>
                                                        </div>
                                                        <div className="col">
                                                            <p>{this.state.individual.identification_number}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="font-weight-bold">Nationality:</p>
                                                        </div>
                                                        <div className="col">
                                                            <p>{this.state.individual.nationality}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="font-weight-bold">Date of Birth:</p>
                                                        </div>
                                                        <div className="col">
                                                            <p>
                                                                <Moment format="DD/MM/YYYY">{this.state.individual.date_of_birth}</Moment>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="font-weight-bold">Current Address: </p>
                                                        </div>
                                                        <div className="col">
                                                            <p>{this.state.individual.current_address}</p>
                                                        </div>
                                                    </div>
                                                    <h2><FaPhone size="2rem"/> Contact Info</h2>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="font-weight-bold">Phone: </p>
                                                        </div>
                                                        <div className="col">
                                                            <p>{this.state.individual.contact}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="font-weight-bold">Email: </p>
                                                        </div>
                                                        <div className="col">
                                                            <p>{this.state.individual.email}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="font-weight-bold">Social Media Account: </p>
                                                        </div>
                                                        <div className="col">
                                                            <p>{this.state.individual.socialMedia}</p>
                                                        </div>
                                                    </div>
                                                    {additionalInfo}
                                                </div>
                                            </section>


                                        </div>
                                    </div>

                                    <div className="col-xl-6 col-lg-7">

                                        <div className="main-right-area">


                                            <section className="resume-section spad pt-0">
                                                <div className="historyListTitle">
                                                     <h1> Employment History</h1>
                                                </div>
                                                {InsertPanel}
                                                <ul className="resume-list" >
                                                    {HistoryItem}
                                                </ul>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>}}
            </div>

        )
    }
}

IndividualEmplyoeePage.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(IndividualEmplyoeePage));