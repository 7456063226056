import React, {Component} from "react";
import MainNavBar from "../../../../components/Navbars/MainNavBar";

import Alert from "react-s-alert";

import InsertIndividualHistoryAdmin from "./InsertIndividualHistoryAdmin";
import InsertInstitutionHistoryAdmin from "./InsertInstitutionHistoryAdmin";
import InsertIndividual from "../../Dashboard/InsertIndividual";
import InsertInstitution from "../../Dashboard/InsertInstitution";

class InsertHistories extends Component{


    render() {
        let status;
        if(this.props.permission === "System Admin"){
            status = true;
        }else{
            status = false;
        }

        return(
            <React.Fragment>
                <Alert stack={{limit: 3}}/>
                <div className="section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 ml-auto mr-auto  ">
                                <h3 className="text-center title">Insert History</h3>
                                <div className="card">
                                    <div className="card-header">
                                        <ul className="nav nav-tabs justify-content-center" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#individual"
                                                   role="tab">
                                                    <i className="now-ui-icons users_single-02"></i>
                                                    Individual Employee
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#institution"
                                                   role="tab">
                                                    <i className="now-ui-icons ui-1_settings-gear-63"></i>
                                                    Institution
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-body">

                                        <div className="tab-content text-center">
                                            <div className="tab-pane active" id="individual" role="tabpanel">
                                                <InsertIndividual
                                                    user ={this.props.user}
                                                />
                                            </div>
                                            <div className="tab-pane" id="institution" role="tabpanel">
                                                <InsertInstitution
                                                    user ={this.props.user}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}
export default InsertHistories;