/*eslint-disable*/
import React from "react";
import { Container } from "reactstrap";

function DarkFooter() {
  return (
    <footer
        className="footer"
        data-background-color="black"
    >
                <Container>
                    <nav>
                        <ul>
                            <li>
                                <a
                                    href="/"
                                    target="_blank"
                                >
                                    Personal Tracking System
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://inklusivity.com/"
                                    target="_blank"
                                >
                                    About Us
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div></div>
                    <div className="copyright" id="copyright">
                        © {new Date().getFullYear()}, Designed and Coded by
                        <br/> INKLUSIVITY TECH
                        <br/>#32 Street 432, SK Tuol Tumpoung 1, KH Chamkarmon, Phnom Penh.
                        <br/>+855 (089) 300710
                        <br/>info@inklusivity.com
                    </div>

                </Container>


    </footer>
  );
}

export default DarkFooter;
