import React, {Component} from "react";
import {withRouter} from "react-router-dom";
// reactstrap components


import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip
} from "reactstrap";
import {connect} from "react-redux";
import {logoutUser} from "../../actions/authActions";
import axios from "axios";

import { FaSearch } from "react-icons/fa";

class MainNavBar extends Component{

  constructor(props){
    super(props);
    this.state = {
      collapseOpen : false,
      signIn : props.signIn,
      signUp : props.signUp,
      socialMedia: props.socialMedia,
      name :''
    }
  }
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser(this.props.history);
    window.location.href = "/";
  };
  componentDidMount() {
    axios.get('/api-users/getUserName/' + this.props.auth.user.id).then( res =>{
      this.setState({
        name : res.data
      })
    }).catch(err =>{
      //console.log(err.response);
    })
  }


  render() {
    const {name} = this.state.name;
    let Button;
    let Welcome;
    let SignInButton;
    let SignUpButton;
    let SignOut ='';
    if(this.props.SignIn){
      SignInButton = <>
        <li className="nav-item">
          <a className="nav-link btn btn-info" href='/sign-in'>
            <p>Sign In</p>
          </a>
        </li>
      </>
    }else{
      SignInButton ="";
    }
    if(this.props.SignUp){
      SignUpButton = <>
        <li className="nav-item">
          <a className="nav-link btn btn-success" href='/sign-up'>
            <p>Sign Up</p>
          </a>
        </li>
      </>
    }
    if(this.props.auth.isAuthenticated){
      SignUpButton ="";
      SignInButton ="";
      Welcome =  <li className="nav-item">
        <a  className="nav-link " href='/user'>
          <i className="now-ui-icons users_circle-08"/>
          <p>Welcome {name}!</p>
        </a>
      </li>;

      if(this.props.dashboard){
        let Url = "/dashboard";

        Button = <>
          <li className="nav-item">
            <a className="nav-link" href={Url} >
              <i className="now-ui-icons business_chart-pie-36"/> Dashboard
            </a>
          </li>
        </>;
      }else{
        const Url = "/welcome" ;
        Button = <>
          <li className="nav-item">
            <a className="nav-link" href={Url} >
              <i className="now-ui-icons business_chart-pie-36"/> Reference Check
            </a>
          </li>
        </>;
      }
      SignOut = <li className="nav-item">
        <a className="nav-link" href="#"
           onClick={this.onLogoutClick}>
          <i className="now-ui-icons sport_user-run"/> Sign Out
        </a>
      </li>;
    }
    let socialMediaLink;
    if(this.props.socialMedia){
      socialMediaLink = <>
        <NavItem>
          <NavLink
              href="https://twitter.com/"
              target="_blank"
              id="twitter-tooltip"
          >
            <i className="fab fa-twitter"/>
            <p className="d-lg-none d-xl-none">Twitter</p>
          </NavLink>
          <UncontrolledTooltip target="#twitter-tooltip">
            Follow us on Twitter
          </UncontrolledTooltip>
        </NavItem>
        <NavItem>
          <NavLink
              href="https://www.facebook.com"
              target="_blank"
              id="facebook-tooltip"
          >
            <i className="fab fa-facebook-square"/>
            <p className="d-lg-none d-xl-none">Facebook</p>
          </NavLink>
          <UncontrolledTooltip target="#facebook-tooltip">
            Like us on Facebook
          </UncontrolledTooltip>
        </NavItem>
        <NavItem>
          <NavLink
              href="https://www.instagram.com/"
              target="_blank"
              id="instagram-tooltip"
          >
            <i className="fab fa-instagram"/>
            <p className="d-lg-none d-xl-none">Instagram</p>
          </NavLink>
          <UncontrolledTooltip target="#instagram-tooltip">
            Follow us on Instagram
          </UncontrolledTooltip>
        </NavItem>
      </>
    }

    return (
        <React.Fragment>
          {this.state.collapseOpen ? (
              <div
                  id="bodyClick"
                  onClick={() => {
                    document.documentElement.classList.toggle("nav-open");
                    this.setState({collapseOpen:true});
                  }}
              />
          ) : null}
          <Navbar className={"fixed-top"  } color="info" expand="lg">
            <Container>

              <div className="navbar-translate">
                <NavbarBrand
                    href="/"
                    id="navbar-brand"
                >
                 <FaSearch size="1.5rem"/> Inklusivity : Reference Check
                </NavbarBrand>
                <UncontrolledTooltip target="#navbar-brand">
                  Designed and Coded by KimAng
                </UncontrolledTooltip>
                <button
                    className="navbar-toggler navbar-toggler"
                    onClick={() => {
                      document.documentElement.classList.toggle("nav-open");
                      this.setState({collapseOpen:!this.state.collapseOpen});
                    }}
                    aria-expanded={this.state.collapseOpen}
                    type="button"
                >
                  <span className="navbar-toggler-bar top-bar"/>
                  <span className="navbar-toggler-bar middle-bar"/>
                  <span className="navbar-toggler-bar bottom-bar"/>
                </button>
              </div>
              <Collapse
                  className="justify-content-end"
                  isOpen={this.state.collapseOpen}
                  navbar
              >
                <Nav navbar>
                  {SignUpButton}
                  {SignInButton}
                  {Welcome}
                  {Button}
                  {SignOut}
                  {socialMediaLink}
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
        </React.Fragment>
    );
  }


}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
    mapStateToProps,
    {logoutUser}
)(withRouter(MainNavBar));
