import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import NotFound from "../../../../components/404/404";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import AdminsList from "./AdminsList";
import axios from "axios";
import {trackPromise} from "react-promise-tracker";
import MainNavBar from "../../../../components/Navbars/MainNavBar";

class AdminsListValidator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            authorized: false
        }
    }

    componentDidMount() {
        trackPromise(
            axios.get('/api-users/getUserPermissions/' + this.props.auth.user.id).then( res =>{
                this.setState({
                    authorized : res.data.permission_classification === "System Admin",
                    loading: false
                })
            }).catch( () =>{
                this.setState({
                    authorized : false,
                    loading: false
                })
            })
        );
        // if (this.props.auth.user.permission === "System Admin") {
        //     this.setState({
        //         authorized: true,
        //         loading: false
        //     })
        // }else{
        //     this.setState({
        //         authorized: false,
        //         loading: false
        //     })
        // }

    }


    render() {

        return (
            <div>
                <MainNavBar SignIn={false} SignUp={false} dashboard={true} socialMedia={false}/>
                {this.state.loading ? <LoadingScreen/> : this.state.authorized ? <AdminsList/> : <NotFound/>}
            </div>

        )
    }
}

AdminsListValidator.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(AdminsListValidator));