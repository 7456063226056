import React, {Component} from "react";
import Moment from "react-moment";
import Viewer from "react-viewer";
import {trackPromise} from "react-promise-tracker";
import axios from "axios";
import { MdHistory,MdDeleteForever } from "react-icons/md";
import EditInstitutionHistory from "../Admin/RecordsList/EditInstitutionHistory";
import {FaTrashRestoreAlt} from "react-icons/fa";
class InstitutionHistory extends Component{
    constructor(props) {
        super(props);
        this.state = {
            id: props.history._id,
            fromYear: props.history.fromYear,
            toYear: props.history.toYear,
            summary: props.history.summary,
            reference: props.history.reference,
            evidence: props.history.evidence_document,
            doc: null,
            visible: false,
            info_type: props.history.info_type
        }
    }
    onDeleteHistory = e =>{
        e.preventDefault();
        axios.post('/api-counts/delete_history/' + this.props.history._id +'/' +this.props.user).then( res =>{
            window.location.reload();
        }).catch( err =>{
            console.log(err.response);
        })
    };
    onRestoreHistory = e =>{
        e.preventDefault();
        axios.post('/api-counts/restore_history/' + this.props.history._id +'/' +this.props.user).then( res =>{
            window.location.reload();
        }).catch( err =>{
            console.log(err.response);
        })
    };
    componentDidMount() {
        trackPromise(
            axios.get("/api-institutionProfile/getEvidence/" + this.state.id).then(
                history => {
                    this.setState({doc: history.data})
                }
            ).catch(error => console.log(error))
        );
    }
    render() {
        let status;
        let edit;
        let editPanel;
        let additionalInfo;
        let deleteOrNot;
        let DeleteConfirm;
        if (this.state.info_type === true) {
            status = <span className="badge badge-success">Positive</span>;
        } else {
            status = <span className="badge badge-danger ">Negative</span>;
        }
        if(this.props.permissions_list.DeleteData.includes("Histories")){
            let href = "deletehistory" + this.props.history._id;
            if(this.props.history.status === "Deleted"){
                deleteOrNot = <a href="#"
                                 data-toggle="modal"
                                 data-target={`#${href}`}
                                 className="btn-outline-success border-dark">
                    <FaTrashRestoreAlt size="1rem"/>
                    Restore</a>;
                DeleteConfirm = <div
                    className="modal fade"
                    id={href}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true">

                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Confirm Delete History</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure that you wish to restore this history?
                                <h2
                                >{this.state.fromYear}-{this.state.toYear}</h2>
                                <h4>{this.state.summary}</h4>
                                Your action cannot be reverted!
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="site-btn text-black" data-dismiss="modal">Close</button>
                                <button type="button" className="site-btn text-success" data-dismiss="modal" onClick={this.onRestoreHistory}>Restore</button>
                            </div>
                        </div>
                    </div>
                </div>;
            }else{
                deleteOrNot = <a href="#"
                                 data-toggle="modal"
                                 data-target={`#${href}`}
                                 className="btn-outline-danger border-dark">
                    <MdDeleteForever size="1rem"/>
                    Delete</a>;
                DeleteConfirm = <div
                    className="modal fade"
                    id={href}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true">

                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Confirm Delete History</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure that you wish to delete this history?
                                <h2
                                >{this.state.fromYear}-{this.state.toYear}</h2>
                                <h4>{this.state.summary}</h4>
                                Your action cannot be reverted!
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="site-btn text-black" data-dismiss="modal">Close</button>
                                <button type="button" className="site-btn text-danger" data-dismiss="modal" onClick={this.onDeleteHistory}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>;
            }

        }
        if (this.props.permissions_list.EditData.includes("Histories")) {
            let dyhref = "editHistroy" + this.props.history._id;
            edit = <a href={`#${dyhref}`}
                      className="btn-outline-warning border-dark"
                      data-toggle="collapse"
                      aria-expanded="false"
                      aria-controls={dyhref}
                      style={{margin:'10px'}}
            >
                <MdHistory size="1rem"/>
                Edit History
            </a>;
            editPanel = <div className="collapse" id={dyhref}>
                <div className="card">
                    <div className="card-body text-center">
                        <EditInstitutionHistory
                            history={this.props.history}
                            user={this.props.user}
                            status={this.props.status}
                        />
                    </div>
                </div>

            </div>;

        }
        if(this.props.userType === "AdminUser"){
            let color;
            let statusColor;
            if (this.props.history.status === "In Review") {
                statusColor = "badge badge-warning";
            }
            if (this.props.history.status === "Published") {
                statusColor = "badge badge-info";
            }
            if (this.props.history.status === "Rejected") {
                statusColor = "badge badge-danger";
            }
            if (this.props.history.access_classification === "Not-Accessible") {
                color = "badge badge-danger";
            }
            if (this.props.history.access_classification === "Confidential" || this.props.history.access_classification === "Strictly-Confidential") {
                color = "badge badge-warning";
            }
            if (this.props.history.access_classification === "Public") {
                color = "badge badge-primary";
            }
            additionalInfo =
                <div>
                    <span className={color}> {this.props.history.access_classification}</span>
                    <span className={statusColor}> {this.props.history.status}</span>
                    <p>Created At : <Moment
                        format="DD/MM/YYYY">{this.props.history.publishDate}</Moment></p>
                </div>
        }
        let dataContributorOrNot;
        if(this.props.permission === "System Admin"){
            dataContributorOrNot =  <div>
                <br/>
                <h3>Data Contributors</h3>
                {
                    this.props.history.data_contributor.map((data, k) =>
                        <>

                            <div className="row">
                                <div className="col">
                                    <p className="font-weight-bold">Name: </p>
                                    <a
                                        href={
                                            data.type ==="AdminUser"?"/adminUser/"+ data._id
                                                :data.type ==="IndividualMember"?"/individualUser/"+ data._id:
                                                data.type ==="OrganizationMember"?"/organizationUser/"+ data._id:""}><p>{data.name}</p></a>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col">
                                    <p className="font-weight-bold">Email: </p>
                                    <p>{data.email}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {data.type === "AdminUser"? <div>
                                        <p className="font-weight-bold">Permission: </p>
                                        <p>{data.permission_classification}</p>
                                    </div> : <div>
                                        <p className="font-weight-bold">User Type: </p>
                                        <p>{data.type}</p>
                                    </div>}

                                </div>
                            </div>
                            <br/>

                        </>
                    )}
            </div>;
        }
        return(
            <React.Fragment>
                {DeleteConfirm}
                <li>
                    <div className="hidden-print">
                        {edit}
                        {deleteOrNot}
                    </div>
                    <h2 className={this.props.history.status ==="Deleted"?"deleted":""}>{this.state.fromYear}-{this.state.toYear}</h2>
                    <h3 className={this.props.history.status ==="Deleted"?"deleted":""}>{this.props.history.fromMonth} - {this.props.history.toMonth}</h3>
                    <h3 className={this.props.history.status ==="Deleted"?"deleted":""}>{this.props.history.fromDay} - {this.props.history.toDay}</h3>
                    {status}
                    {additionalInfo}
                    {editPanel}

                    <p className={this.props.history.status ==="Deleted"?"deleted":""}>{this.state.summary}</p>
                    <h4>References</h4>
                    <div className="review-slider owl-carousel">
                        <div className="single-review">
                            <div className="qut">“</div>
                            <p>{this.state.reference} </p>
                        </div>
                    </div>
                    <h4>Evidence Document</h4>
                    <img
                        alt="Evident Document"
                        src={this.state.doc}
                        onClick={() => {
                            this.setState({visible: !this.state.visible});
                        }}
                        width="150"
                    />
                    {dataContributorOrNot}
                    <div className="separator-line"/>
                    <Viewer
                        visible={this.state.visible}
                        images={[
                            {src: this.state.doc, alt: 'Evidence Document'}]}
                        onClose={() => {
                            this.setState({visible: false});
                        }}

                    />
                </li>
            </React.Fragment>
        )
    }

}

export default InstitutionHistory;