import React, {Component} from "react";

import ReactTable from 'react-table';
import { Badge } from 'reactstrap';


import {
    IndividualMatchedColumns,
    OrganizationMatchedColumns,
    IndividualUndecidedColumnsPopUp,
    OrganizationUndecidedColumnsPopUp,
    IndividualNotFoundColumnsPopUp,
    OrganizationNotFoundColumnsPopUp
}from '../MergeData/MergeUtilities';

import axios from "axios";
import Moment from "react-moment";
import { MdCallMerge, MdPageview} from "react-icons/all";
import ConflictedIndividualHistory from "../MergeData/MiniIndividualHistory";
import ConflictedInstitutionHistory from "../MergeData/MiniInstitutionHistory";

class HistoriesPopUp extends Component{

    constructor(props) {
        super(props);
        this.state = {
            currentHistory : ''
        }
    }

    render() {
        const IndividualColumns = [
            {
                Header: 'Action',
                columns: [
                    {
                        Header: "View Profile",
                        accessor: '',
                        Cell: props => (
                            <div>
                                <a href={"/individualEmployee/" + props.value._id + "/" + new Date()}
                                   target="_blank"><MdPageview/> View Profile </a>
                            </div>
                        )
                    },
                    {
                        Header: "Merge",
                        accessor: '',
                        Cell: props => (
                            <div>
                                <button
                                    className="btn-info"
                                    onClick={() => {
                                        //console.log(currentIndividual);
                                        axios.post('/api-individualProfile/mergeProfileWithHistory/' + props.value._id + '/' + this.state.currentHistory._id)
                                            .then(res => {
                                                window.location.href = '/individualEmployee/' + props.value._id + '/' + new Date();
                                            }).catch(err => {
                                            console.log(err);
                                        })
                                    }}
                                >
                                    <MdCallMerge/> Merge
                                </button>

                            </div>
                        )
                    }
                ],

            },
            {
                Header: 'ID',
                accessor: 'identification_number'
            }
            ,
            {
                Header: 'Name',
                accessor: 'name',
                Cell: row => (
                    <div style={{color: '#1abc9c'}}> {row.value}</div>
                )
            },
            {
                Header: 'Date of Birth',
                accessor: 'date_of_birth',
                Cell: row => (
                    <Moment format="DD/MM/YYYY">{row.value}</Moment>
                )
            },
            {
                Header: 'Nationality',
                accessor: 'nationality'
            }
        ];
        const InstitutionColumns = [
            {
                Header: 'Action',
                columns: [
                    {
                        Header: "View Profile",
                        accessor: '',
                        Cell: props => (

                            <a href={"/institution/" + props.value._id + "/" + new Date()}
                               target="_blank"><MdPageview/> View Profile </a>

                        )
                    },
                    {
                        Header: "Merge",
                        accessor: '',
                        Cell: props => (
                            <div>
                                <button
                                    className="btn-info"
                                    onClick={() => {
                                        //console.log(currentIndividual);
                                        axios.post('/api-institutionProfile/mergeProfileWithHistory/' + props.value._id + '/' + this.state.currentHistory._id)
                                            .then(res => {
                                                window.location.href = '/institution/' + props.value._id + '/' + new Date();
                                            }).catch(err => {
                                            console.log(err);
                                        })
                                    }}
                                >
                                    <MdCallMerge/> Merge
                                </button>

                            </div>
                        )
                    }
                ]

            },
            {
                Header: 'Registered Number',
                accessor: 'register_number'
            }
            ,
            {
                Header: 'Name',
                accessor: 'name',
                Cell: row => (
                    <div style={{color: '#1abc9c'}}> {row.value}</div>
                )
            },
            {
                Header: 'Tax Identification Number',
                accessor: 'tax_identification_number'
            }
        ];
        return (
            <div className="card">
                <div className="card-header">

                    <ul className="nav nav-tabs justify-content-center" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" data-toggle="tab" href="#newhistory"
                               role="tab">
                                <i className="now-ui-icons users_single-02"/>
                                Matched with records <Badge color="success">{this.props.successfullyMergedIndividualProfiles.length + this.props.successfullyMergedInstitutionProfiles.length}</Badge>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#undecided"
                               role="tab">
                                <i className="now-ui-icons business_bank"/>
                                Undecided Histories <Badge color="warning">{this.props.conflictedIndividualHistories.length + this.props.conflictedInstitutionHistories.length}</Badge>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#notfound"
                               role="tab">
                                <i className="now-ui-icons business_bank"/>
                                Profile Not Found <Badge color="danger">{this.props.notFoundIndividualHistories.length + this.props.notFoundInstitutionHistories.length}</Badge>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <div className="tab-content text-center">
                        <div className="tab-pane active " id="newhistory" role="tabpanel">
                            <h3 className="font-weight-bold">Individual Histories</h3>
                            <ReactTable
                                data={this.props.successfullyMergedIndividualProfiles}
                                columns={IndividualMatchedColumns}
                                filterable
                                defaultPageSize={5}
                                showPagination={true}
                                getTrProps={(state, rowInfo, column, instance) => {
                                    return {
                                        onClick: (e) => {
                                            this.setState({
                                                currentHistory : rowInfo.original
                                            });
                                        }
                                    }
                                }}
                            />
                            <br/>
                            <h3 className="font-weight-bold">Institution Histories</h3>
                            <ReactTable
                                data={this.props.successfullyMergedInstitutionProfiles}
                                columns={OrganizationMatchedColumns}
                                filterable
                                defaultPageSize={5}
                                showPagination={true}
                                getTrProps={(state, rowInfo, column, instance) => {
                                    return {
                                        onClick: (e) => {
                                            this.setState({
                                                currentHistory : rowInfo.original
                                            });
                                        }
                                    }
                                }}
                            />
                        </div>
                        <div className="tab-pane  " id="undecided" role="tabpanel">
                            <h3 className="font-weight-bold">Individual Histories</h3>
                            <ReactTable
                                data={this.props.conflictedIndividualHistories}
                                columns={IndividualUndecidedColumnsPopUp}
                                filterable
                                defaultPageSize={5}
                                showPagination={true}
                                getTrProps={(state, rowInfo, column, instance) => {
                                    return {
                                        onClick: (e) => {
                                            this.setState({
                                                currentHistory : rowInfo.original
                                            });
                                        }
                                    }
                                }}
                                SubComponent={row => {
                                    //console.log(row);
                                    return (
                                        <div>
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h4 className="font-weight-bold text-dark">Possible
                                                        Candidates</h4>
                                                    <ReactTable
                                                        className="-highlight -striped"
                                                        columns={IndividualColumns}
                                                        data={row.original.possibleAssociation}
                                                        defaultPageSize={3}
                                                        showPagination={true}
                                                    />
                                                </div>

                                            </div>
                                            <ConflictedIndividualHistory
                                                history={row.original}
                                                user={this.props.user}/>
                                        </div>
                                    );
                                }}
                            />
                            <br/>
                            <h3 className="font-weight-bold">Institution Histories</h3>
                            <ReactTable
                                data={this.props.conflictedInstitutionHistories}
                                columns={OrganizationUndecidedColumnsPopUp}
                                filterable
                                defaultPageSize={5}
                                showPagination={true}
                                getTrProps={(state, rowInfo, column, instance) => {
                                    return {
                                        onClick: (e) => {
                                            this.setState({
                                                currentHistory : rowInfo.original
                                            });
                                        }
                                    }
                                }}
                                SubComponent={row => {
                                    //console.log(row);
                                    return (
                                        <div>
                                            <div className="row">
                                                <div className="col text-center">
                                                    <h4 className="font-weight-bold text-dark">Possible
                                                        Candidates</h4>
                                                    <ReactTable
                                                        className="-highlight -striped"
                                                        columns={InstitutionColumns}
                                                        data={row.original.possibleAssociation}
                                                        defaultPageSize={3}
                                                        showPagination={true}
                                                    />
                                                </div>

                                            </div>
                                            {/*<div className="col text-center">*/}
                                            {/*    <h3 className="font-weight-bold">Summary</h3>*/}
                                            {/*    <h4 className="font-weight-bold">{row.original.fromYear}-{row.original.toYear}</h4>*/}
                                            {/*</div>*/}
                                            <ConflictedInstitutionHistory
                                                history={row.original}
                                                user={this.props.user}/>

                                            {/*<div>*/}
                                            {/*    {row.original.work_summary}*/}
                                            {/*</div>*/}

                                        </div>
                                    );
                                }}
                            />
                        </div>
                        <div className="tab-pane " id="notfound" role="tabpanel">
                            <h3 className="font-weight-bold">Individual Histories</h3>
                            <ReactTable
                                data={this.props.notFoundIndividualHistories}
                                columns={IndividualNotFoundColumnsPopUp}
                                filterable
                                defaultPageSize={5}
                                showPagination={true}
                                getTrProps={(state, rowInfo, column, instance) => {
                                    return {
                                        onClick: (e) => {
                                            this.setState({
                                                currentHistory : rowInfo.original
                                            });
                                        }
                                    }
                                }}
                            />
                            <br/>
                            <h3 className="font-weight-bold">Institution Histories</h3>
                            <ReactTable
                                data={this.props.notFoundInstitutionHistories}
                                columns={OrganizationNotFoundColumnsPopUp}
                                filterable
                                defaultPageSize={5}
                                showPagination={true}
                                getTrProps={(state, rowInfo, column, instance) => {
                                    return {
                                        onClick: (e) => {
                                            this.setState({
                                                currentHistory : rowInfo.original
                                            });
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HistoriesPopUp;