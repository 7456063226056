import React, {Component} from "react";
import axios from 'axios';
import Alert from "react-s-alert";
//import DateTime from "react-datetime";
import { FiArrowUp } from "react-icons/fi";
import ImageUploader from "react-images-upload";
import ShowAlert from "../../../../utils/ShowAlert";
import {  animateScroll as scroll } from 'react-scroll'
import Moment from "react-moment";
import {FaEdit} from "react-icons/all";
class EditIndividual extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            name_in_khmer: '',
            date_of_birth: '',
            identification_number: '',
            current_address: '',
            nationality : '',
            contact :'',
            email:'',
            socialMedia:'',
            photo : [],
            passport: [],
            name_p: '',
            name_in_khmer_p: '',
            date_of_birth_p: '',
            identification_number_p: '',
            current_address_p: '',
            nationality_p : '',
            contact_p :'',
            email_p:'',
            socialMedia_p:'',
            photo_p : '',
            passport_p: ''
        };
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    onSubmit = e => {
        e.preventDefault();
        const viewP = {
            name: this.state.name_p?this.state.name_p:this.props.dataRevealPercentage.name,
            name_in_khmer: this.state.name_in_khmer_p?this.state.name_in_khmer_p:this.props.dataRevealPercentage.name_in_khmer,
            date_of_birth: this.state.date_of_birth_p?this.state.date_of_birth_p:this.props.dataRevealPercentage.date_of_birth,
            identification_number: this.state.identification_number_p?this.state.identification_number_p:this.props.dataRevealPercentage.identification_number,
            nationality: this.state.nationality_p?this.state.nationality_p:this.props.dataRevealPercentage.nationality,
            socialMedia : this.state.socialMedia_p?this.state.socialMedia_p:this.props.dataRevealPercentage.socialMedia,
            current_address:this.state.current_address_p?this.state.current_address_p:this.props.dataRevealPercentage.current_address,
            contact:this.state.contact_p?this.state.contact_p:this.props.dataRevealPercentage.contact,
            email:this.state.email_p?this.state.email_p:this.props.dataRevealPercentage.email,
        };
        const update = new FormData();
        let updatePassportOrNot;
        let updatePhotoOrNot;
        if(this.state.photo[0]){
            updatePhotoOrNot = "Update";
        }
        if(this.state.passport[0]){
            updatePassportOrNot = "Update";
        }

        update.append("myFiles",this.state.photo[0]);
        update.append("myFiles",this.state.passport[0]);
        // update.append("name",this.state.name ? this.state.name : this.props.user.name);
        // update.append("name_in_khmer",this.state.name_in_khmer ? this.state.name_in_khmer : this.props.user.name_in_khmer);
        // update.append("date_of_birth",this.state.date_of_birth ? this.state.date_of_birth :this.props.user.date_of_birth);
        // update.append("identification_number",this.state.identification_number ? this.state.identification_number : this.props.user.identification_number);
        update.append("current_address",this.state.current_address?this.state.current_address : this.props.user.current_address);
        //update.append("nationality",this.state.nationality?this.state.nationality : this.props.user.nationality);
        update.append("contact",this.state.contact?this.state.contact : this.props.user.contact);
        update.append("email",this.state.email?this.state.email : this.props.user.email);
        update.append("socialMedia",this.state.socialMedia?this.state.socialMedia : this.props.user.socialMedia);
        update.append("photo",updatePhotoOrNot);
        update.append("passport",updatePassportOrNot);
        update.append("dataRevealPercentage",JSON.stringify(viewP));
        // const update = {
        //     name: this.state.name ? this.state.name : this.props.user.name,
        //     name_in_khmer: this.state.name_in_khmer ? this.state.name_in_khmer : this.props.user.name_in_khmer,
        //     date_of_birth: this.state.date_of_birth ? this.state.date_of_birth :this.props.user.date_of_birth ,
        //     identification_number: this.state.identification_number ? this.state.identification_number : this.props.user.identification_number,
        //     current_address: this.state.current_address?this.state.current_address : this.props.user.current_address,
        //     nationality : this.state.nationality?this.state.nationality : this.props.user.nationality
        // };
        axios.patch('/api-individualProfile/update/' + this.props.user._id +'/' + this.props.member ,update).then(res => {
            window.location.reload();
        }).catch(err => {
            ShowAlert(err.response);
            window.location.reload();
        })
    };

    onChange = e => {
        e.preventDefault();
        this.setState({[e.target.id]: e.target.value});
    };
    onDropPassport = file => {
        this.setState({
            passport: this.state.passport.concat(file)
        });
    };

    onDropPhoto = file => {
        this.setState({
            photo: this.state.photo.concat(file)
        });
    };
    scrollToTop() {
        scroll.scrollToTop({duration:200});
    }

    render() {
        //console.log(this.props);
        //const dataPercentage = this.props.dataPercentage;
        return (
            <React.Fragment>
                <Alert stack={{limit: 3}}/>
                <br/>
                <FaEdit size="2rem"/> <h3> Edit Individual Record</h3>
                <form className="form " onSubmit={this.onSubmit}
                      encType="multipart/form-data" method="POST"
                      style={{fontSize:12}}
                >
                    <div className="text-left">
                        <div className="row">
                            <div className="col">
                                <p className="font-weight-bold">Name</p>
                            </div>
                            <div className="col">
                                {/*<input*/}
                                {/*    onChange={this.onChange}*/}
                                {/*    className="form-control"*/}
                                {/*    id="name"*/}
                                {/*    required*/}
                                {/*    defaultValue={this.props.user.name}*/}

                                {/*/>*/}
                                <p className="font-weight-light">{this.props.user.name}</p>
                            </div>
                            {this.props.formatReportOrNot?<div className="col-sm-3">
                                <select
                                    onChange={this.onChange}
                                    required={true}
                                    //value={this.state.name_p}
                                    id="name_p"
                                    className="form-control"
                                    //defaultValue={this.props.dataRevealPercentage.name}
                                >
                                    <option selected={this.props.dataRevealPercentage.name === 0}value={0}>0%</option>
                                    <option selected={this.props.dataRevealPercentage.name === 30}value={30}>30%</option>
                                    <option selected={this.props.dataRevealPercentage.name === 50}value={50}>50%</option>
                                    <option selected={this.props.dataRevealPercentage.name === 75} value={75}>75%</option>
                                    <option selected={this.props.dataRevealPercentage.name === 100}value={100}>100%</option>
                                </select>
                            </div>:<span/>}
                        </div>
                        <div className="row">
                            <div className="col ">
                                <p className="font-weight-bold">Name In Khmer </p>
                            </div>
                            <div className="col ">
                                {/*<input*/}
                                {/*    onChange={this.onChange}*/}
                                {/*    className="form-control"*/}
                                {/*    defaultValue={this.props.user.name_in_khmer}*/}
                                {/*    id="name_in_khmer"*/}
                                {/*    required*/}
                                {/*/>*/}
                                <p className="font-weight-light">{this.props.user.name_in_khmer}</p>
                            </div>
                            {this.props.formatReportOrNot?<div className="col-sm-3">
                                <select
                                    onChange={this.onChange}
                                    required={true}
                                    //value={this.state.name_p}
                                    id="name_in_khmer_p"
                                    className="form-control"
                                    //defaultValue={this.props.dataRevealPercentage.name}
                                >
                                    <option selected={this.props.dataRevealPercentage.name_in_khmer === 0}value={0}>0%</option>
                                    <option selected={this.props.dataRevealPercentage.name_in_khmer === 30}value={30}>30%</option>
                                    <option selected={this.props.dataRevealPercentage.name_in_khmer === 50}value={50}>50%</option>
                                    <option selected={this.props.dataRevealPercentage.name_in_khmer === 75} value={75}>75%</option>
                                    <option selected={this.props.dataRevealPercentage.name_in_khmer === 100}value={100}>100%</option>
                                </select>
                            </div>:<span/>}
                        </div>
                        <div className="row">
                            <div className="col ">
                                <p className="font-weight-bold">Identification Number: </p>
                            </div>
                            <div className="col ">
                                {/*<input*/}
                                {/*    onChange={this.onChange}*/}
                                {/*    className="form-control"*/}
                                {/*    defaultValue={this.props.user.identification_number}*/}
                                {/*    id="identification_number"*/}
                                {/*    required*/}
                                {/*/>*/}
                                <p className="font-weight-light">{this.props.user.identification_number}</p>
                            </div>
                            {this.props.formatReportOrNot?<div className="col-sm-3">
                                <select
                                    onChange={this.onChange}
                                    required={true}
                                    //value={this.state.name_p}
                                    id="identification_number_p"
                                    className="form-control"
                                    //defaultValue={this.props.dataRevealPercentage.name}
                                >
                                    <option selected={this.props.dataRevealPercentage.identification_number === 0}value={0}>0%</option>
                                    <option selected={this.props.dataRevealPercentage.identification_number === 30}value={30}>30%</option>
                                    <option selected={this.props.dataRevealPercentage.identification_number === 50}value={50}>50%</option>
                                    <option selected={this.props.dataRevealPercentage.identification_number === 75} value={75}>75%</option>
                                    <option selected={this.props.dataRevealPercentage.identification_number === 100}value={100}>100%</option>
                                </select>
                            </div>:<span/>}

                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="font-weight-bold">Date of Birth: </p>
                            </div>
                            <div className="col">
                                {/*<DateTime*/}
                                {/*    onChange={this.handleChange}*/}
                                {/*    selected={this.state.date_of_birth}*/}
                                {/*    timeFormat={false}*/}
                                {/*    inputProps={{placeholder: this.props.user.date_of_birth}}*/}
                                {/*/>*/}
                                <p className="font-weight-light"><Moment
                                    format="DD/MM/YYYY">{this.props.user.date_of_birth}</Moment></p>
                            </div>
                            {this.props.formatReportOrNot?<div className="col-sm-3">
                                <select
                                    onChange={this.onChange}
                                    required={true}
                                    //value={this.state.name_p}
                                    id="date_of_birth_p"
                                    className="form-control"
                                    //defaultValue={this.props.dataRevealPercentage.name}
                                >
                                    <option selected={this.props.dataRevealPercentage.date_of_birth === 0}value={0}>0%</option>
                                    <option selected={this.props.dataRevealPercentage.date_of_birth === 30}value={30}>30%</option>
                                    <option selected={this.props.dataRevealPercentage.date_of_birth === 50}value={50}>50%</option>
                                    <option selected={this.props.dataRevealPercentage.date_of_birth === 75} value={75}>75%</option>
                                    <option selected={this.props.dataRevealPercentage.date_of_birth === 100}value={100}>100%</option>
                                </select>
                            </div>:<span/>}
                        </div>
                        <div className="row">
                            <div className="col ">
                                <p className="font-weight-bold">Phone: </p>
                            </div>
                            <div className="col ">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.user.contact}
                                    id="contact"
                                    required
                                />
                            </div>
                            {this.props.formatReportOrNot?<div className="col-sm-3">
                                <select
                                    onChange={this.onChange}
                                    required={true}
                                    //value={this.state.name_p}
                                    id="contact_p"
                                    className="form-control"
                                    //defaultValue={this.props.dataRevealPercentage.name}
                                >
                                    <option selected={this.props.dataRevealPercentage.contact === 0}value={0}>0%</option>
                                    <option selected={this.props.dataRevealPercentage.contact === 30}value={30}>30%</option>
                                    <option selected={this.props.dataRevealPercentage.contact === 50}value={50}>50%</option>
                                    <option selected={this.props.dataRevealPercentage.contact === 75} value={75}>75%</option>
                                    <option selected={this.props.dataRevealPercentage.contact === 100}value={100}>100%</option>
                                </select>
                            </div>:<span/>}
                        </div>
                        <div className="row">
                            <div className="col ">
                                <p className="font-weight-bold">Email: </p>
                            </div>
                            <div className="col ">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.user.email}
                                    id="email"
                                    required
                                />
                            </div>
                            {this.props.formatReportOrNot?<div className="col-sm-3">
                                <select
                                    onChange={this.onChange}
                                    required={true}
                                    //value={this.state.name_p}
                                    id="email_p"
                                    className="form-control"
                                    //defaultValue={this.props.dataRevealPercentage.name}
                                >
                                    <option selected={this.props.dataRevealPercentage.email === 0}value={0}>0%</option>
                                    <option selected={this.props.dataRevealPercentage.email === 30}value={30}>30%</option>
                                    <option selected={this.props.dataRevealPercentage.email === 50}value={50}>50%</option>
                                    <option selected={this.props.dataRevealPercentage.email === 75} value={75}>75%</option>
                                    <option selected={this.props.dataRevealPercentage.email === 100}value={100}>100%</option>
                                </select>
                            </div>:<span/>}
                        </div>
                        <div className="row">
                            <div className="col ">
                                <p className="font-weight-bold">Social Media: </p>
                            </div>
                            <div className="col ">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.user.socialMedia}
                                    id="socialMedia"
                                    required
                                />
                            </div>
                            {this.props.formatReportOrNot?<div className="col-sm-3">
                                <select
                                    onChange={this.onChange}
                                    required={true}
                                    //value={this.state.name_p}
                                    id="socialMedia_p"
                                    className="form-control"
                                    //defaultValue={this.props.dataRevealPercentage.name}
                                >
                                    <option selected={this.props.dataRevealPercentage.socialMedia === 0}value={0}>0%</option>
                                    <option selected={this.props.dataRevealPercentage.socialMedia === 30}value={30}>30%</option>
                                    <option selected={this.props.dataRevealPercentage.socialMedia === 50}value={50}>50%</option>
                                    <option selected={this.props.dataRevealPercentage.socialMedia === 75} value={75}>75%</option>
                                    <option selected={this.props.dataRevealPercentage.socialMedia === 100}value={100}>100%</option>
                                </select>
                            </div>:<span/>}
                        </div>
                        <div className="row">
                            <div className="col ">
                                <p className="font-weight-bold">Nationality: </p>
                            </div>
                            <div className="col ">
                                {/*<input*/}
                                {/*    onChange={this.onChange}*/}
                                {/*    className="form-control"*/}
                                {/*    defaultValue={this.props.user.nationality}*/}
                                {/*    id="nationality"*/}
                                {/*    required*/}
                                {/*/>*/}
                                <p className="font-weight-light">{this.props.user.nationality}</p>
                            </div>
                            {this.props.formatReportOrNot?<div className="col-sm-3">
                                <select
                                    onChange={this.onChange}
                                    required={true}
                                    //value={this.state.name_p}
                                    id="nationality_p"
                                    className="form-control"
                                    //defaultValue={this.props.dataRevealPercentage.name}
                                >
                                    <option selected={this.props.dataRevealPercentage.nationality === 0}value={0}>0%</option>
                                    <option selected={this.props.dataRevealPercentage.nationality === 30}value={30}>30%</option>
                                    <option selected={this.props.dataRevealPercentage.nationality === 50}value={50}>50%</option>
                                    <option selected={this.props.dataRevealPercentage.nationality === 75} value={75}>75%</option>
                                    <option selected={this.props.dataRevealPercentage.nationality === 100}value={100}>100%</option>
                                </select>
                            </div>:<span/>}
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="font-weight-bold">Current Address: </p>
                            </div>
                            <div className="col ">
                                <input
                                    onChange={this.onChange}
                                    className="form-control"
                                    defaultValue={this.props.user.current_address}
                                    id="current_address"
                                    required
                                />
                            </div>
                            {this.props.formatReportOrNot?<div className="col-sm-3">
                                <select
                                    onChange={this.onChange}
                                    required={true}
                                    //value={this.state.name_p}
                                    id="current_address_p"
                                    className="form-control"
                                    //defaultValue={this.props.dataRevealPercentage.name}
                                >
                                    <option selected={this.props.dataRevealPercentage.current_address === 0}value={0}>0%</option>
                                    <option selected={this.props.dataRevealPercentage.current_address === 30}value={30}>30%</option>
                                    <option selected={this.props.dataRevealPercentage.current_address === 50}value={50}>50%</option>
                                    <option selected={this.props.dataRevealPercentage.current_address === 75} value={75}>75%</option>
                                    <option selected={this.props.dataRevealPercentage.current_address === 100}value={100}>100%</option>
                                </select>
                            </div>:<span/>}
                        </div>
                        <ImageUploader
                            singleImage={true}
                            withPreview={true}
                            withIcon={true}
                            buttonText='Update Photo'
                            onChange={this.onDropPhoto}
                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                            maxFileSize={5242880}
                        />
                        <ImageUploader
                            singleImage={true}
                            withPreview={true}
                            withIcon={true}
                            buttonText='Update Passport'
                            onChange={this.onDropPassport}
                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                            maxFileSize={5242880}
                        />

                    </div>


                    <button type="submit" className="site-btn text-info">Update Info
                    </button>

                </form>
                <a   href="#editProfile"
                     data-toggle="collapse"
                     aria-expanded="false"
                     aria-controls="editProfile"
                     onClick={this.scrollToTop}
                >
                    <FiArrowUp size ="4em" color="black"/>
                </a>
            </React.Fragment>
        )
    }
}

export default EditIndividual;